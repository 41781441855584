import gql from "graphql-tag";
export const contractDocumentFragment = gql`
  fragment ContractDocument on FiscalContractDocument {
    id,
    date,
    contractId,
    description,
    documents64 {
      id,
      data,
      filename,
      contentType,
      key
    }
  }
`;