import { LocationDistributionAction, BatchDistributionAction, StockFlowAction } from 'stock-front';
import PrintBarcodeAction from '../components/products/PrintBarcodeAction';
import ProductUnitStatsAction from '../components/analysis/fiscalProducts/stats/ProductUnitStatsAction';
export const productUnitActionsBuilder = productUnit => {
  return [{
    action: StockFlowAction,
    actionProps: {
      productUnitId: productUnit.id
    }
  }, {
    action: ProductUnitStatsAction,
    actionProps: {
      productUnitId: productUnit.id
    }
  }, {
    type: 'divider'
  }, {
    action: PrintBarcodeAction,
    actionProps: {
      record: productUnit,
      name: productUnit.product?.name
    }
  }, {
    action: LocationDistributionAction,
    actionProps: {
      productUnit: productUnit
    }
  }, {
    action: BatchDistributionAction,
    actionProps: {
      productUnit: productUnit
    }
  }];
};