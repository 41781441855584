import { accountAttributesBuilder, accountDefaultBuilder } from './AccountBuilder';
export const advanceParamBuilder = (record, values) => {
  const attributes = accountAttributesBuilder(record, values);
  return {
    attributes: {
      ...attributes,
      id: undefined
    },
    currentAssetAccountId: record.account.id
  };
};
export const advanceDefaultBuilder = record => {
  const object = accountDefaultBuilder(record);
  return {
    ...object,
    id: undefined,
    shortComplement: object.shortComplement || "Adiantamento"
  };
};