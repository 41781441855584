import gql from "graphql-tag";
import { taxProfileRetentionFragment } from "./TaxProfileRetention";
import { taxProfileCsllFragment } from "./TaxProfileCsll";
import { taxProfileIrpjFragment } from "./TaxProfileIrpj";
import { taxProfileInssFragment } from "./TaxProfileInss";
import { taxProfilePisFragment } from "./TaxProfilePis";
import { taxProfileCofinsFragment } from "./TaxProfileCofins";
import { taxProfileIpiFragment } from "./TaxProfileIpi";
import { taxProfileIssFragment } from "./TaxProfileIss";
import { taxProfileIcmsFragment } from "./TaxProfileIcms";
import { taxProfileCfopFragment } from "./TaxProfileCfop";
import { taxProfileNcmFragment } from "./TaxProfileNcm";
import { taxProfileAdditionalInformationFragment } from "./TaxProfileAdditionalInformation";
import { taxProfileCestFragment } from "./TaxProfileCest";
export const TaxProfileSimpleFragment = gql`
  fragment TaxProfileSimple on TaxProfile  { 
    id,
    name,
    movementKind,
    applicationKind,
    finalCostumerMethod,
    discardedAt,    
  }
`;
export const TaxProfileFragment = gql`
  fragment TaxProfile on TaxProfile  { 
    ...TaxProfileSimple,
    retentions {
      ...TaxProfileRetention
    }
    csll {
      ...TaxProfileCsll
    }
    irpj {
      ...TaxProfileIrpj
    }
    inss {
      ...TaxProfileInss
    }
    pis {
      ...TaxProfilePis
    }
    cofins {
      ...TaxProfileCofins
    }    
    ipi {
      ...TaxProfileIpi
    }
    iss {
      ...TaxProfileIss
    }  
    icms {
      ...TaxProfileIcms
    }
    cfops {
      ...TaxProfileCfop
    }  
    ncms {
      ...TaxProfileNcm
    }
    additionalInformations {
      ...TaxProfileAdditionalInformation
    }                
    cests {
      ...TaxProfileCest
    }
  }
  ${TaxProfileSimpleFragment}
  ${taxProfileRetentionFragment}
  ${taxProfileCsllFragment}  
  ${taxProfileIrpjFragment}
  ${taxProfileInssFragment}  
  ${taxProfilePisFragment}
  ${taxProfileCofinsFragment}  
  ${taxProfileIpiFragment}    
  ${taxProfileIssFragment}
  ${taxProfileIcmsFragment}  
  ${taxProfileCfopFragment}  
  ${taxProfileNcmFragment}
  ${taxProfileAdditionalInformationFragment} 
  ${taxProfileCestFragment} 
`;