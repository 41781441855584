import { PROTEST_MESSAGES } from "../constants/bankSlips/BankSlipFieldsOptions";
import { DAILY, DO_NOT_PROTEST, TO_RECEIVE } from "../constants/operations/OperationsFieldsOptions";
import { percentageFormat, getLabelByValue, simpleDateFormat } from 'grifo-components';
export const buildInstructions = (operation, competencyDate, form) => {
  if (operation.kind !== TO_RECEIVE) return;
  let latePaymentFine = operation.latePaymentFine;
  if (operation.latePaymentInterestKind === DAILY) latePaymentFine = operation.latePaymentFine / 30;
  const latePaymentInterest = operation.latePaymentInterest;
  let instructions = [];
  if (latePaymentFine === 0 && latePaymentInterest === 0) instructions.push('Não cobrar encargos por atraso');else {
    const lateDate = simpleDateFormat(operation.daysAfterOverdueToExpiredTitle + competencyDate);
    if (latePaymentInterest > 0) instructions.push(`Após ${lateDate} juros de ${percentageFormat(latePaymentInterest)}/dia`);
    if (latePaymentFine > 0) instructions.push(`Após ${lateDate} multa de ${percentageFormat(latePaymentFine)}`);
    instructions.push('Não conceder desconto');
  }
  if (operation.protestCode !== DO_NOT_PROTEST) {
    if (operation.daysToProtest === 0) instructions.push(`Protesto no vencimento`);else {
      const protestMessage = getLabelByValue(PROTEST_MESSAGES, operation.protestCode);
      instructions.push(`Protesto no ${operation.daysToProtest}${protestMessage}`);
    }
  }
  form.setFieldValue('instruction1', '');
  form.setFieldValue('instruction2', '');
  form.setFieldValue('instruction3', '');
  form.setFieldValue('instruction4', '');
  for (let index = 0; index < instructions.length; index++) form.setFieldValue(`instruction${index + 1}`, instructions[index]);
};