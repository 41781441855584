import React from 'react';
import './App.css';
import { AppConfigContext, Image } from 'grifo-components';
import { App as Starter } from 'capella';
import logo from './assets/images/logo.png';
import { ALL_FEATURES } from './constants/Features';
import Footer from './components/Footer';

const App = () => {
  return (
    <AppConfigContext.Provider value={
      {
        backendUrl: process.env.REACT_APP_COOPERASIS_BACKEND_URL,
        features: ALL_FEATURES,
        logo: <Image preview={false} src={logo} className="headerLogo" />,
        styles: [],
        footer: <Footer />,
        canCreateWorkspace: true
      }
    }> 
      <Starter /> 
    </AppConfigContext.Provider>       
  )    
}

export default App;