import { today } from 'grifo-components';
import { accountAttributesBuilder, accountDefaultBuilder } from './AccountBuilder';
export const reschedulingParamBuilder = (record, values) => {
  const attributes = accountAttributesBuilder(record, values);
  return {
    attributes: {
      ...attributes,
      id: undefined
    },
    accountId: record.id
  };
};
export const reschedulingDefaultBuilder = record => {
  const object = accountDefaultBuilder(record);
  return {
    ...object,
    id: undefined,
    competencyDate: today(),
    shortComplement: "Reparcelamento de conta"
  };
};