export const NOT_APPLICABLE = 'not_applicable';
export const OPENED = 'opened';
export const CLOSED_OR_VAULT = 'closed_or_vault';
export const BULK_CARRIER = 'bulk_carrier';
export const CONTAINER_HOLDER = 'container_holder';
export const SIDER = 'sider';
export const BODY_TYPE_OPTIONS = [{
  value: NOT_APPLICABLE,
  label: 'Não aplicável'
}, {
  value: OPENED,
  label: 'Aberta'
}, {
  value: CLOSED_OR_VAULT,
  label: 'Fechada/Baú'
}, {
  value: BULK_CARRIER,
  label: 'Granelera'
}, {
  value: CONTAINER_HOLDER,
  label: 'Porta Container'
}, {
  value: SIDER,
  label: 'Sider'
}];