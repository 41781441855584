export const NATURAL_MASK = '999.999.999-99';
export const JURIDICAL_MASK = '99.999.999/9999-99';
export const RNTRC_MASK = 'aaa-99999999';
export const NATURAL = 'natural';
export const JURIDICAL = 'juridical';
export const NON_SPECIFIED = 'non_specified';
export const FEMALE = 'female';
export const MALE = 'male';
export const OTHER = 'other';
export const NOT_GOVERNMENT_INSTITUTION = 'not_government_institution';
export const INSS = 'inss';
export const KID = 'kid';
export const YOUNG = 'young';
export const ADULT = 'adult';
export const ELDERLY = 'elderly';
export const PERSON_GOVERNMENT_TYPE = [{
  value: NOT_GOVERNMENT_INSTITUTION,
  label: 'Não governamental'
}, {
  value: INSS,
  label: 'INSS'
}];
export const PERSON_TYPE_OPTIONS = [{
  value: NATURAL,
  label: 'Física'
}, {
  value: JURIDICAL,
  label: 'Jurídica'
}];
export const PERSON_GENDER_OPTIONS_BY_KEY = [{
  value: '0',
  label: 'Feminino'
}, {
  value: '1',
  label: 'Masculino'
}, {
  value: '2',
  label: 'Outro'
}, {
  value: '90',
  label: 'Não especificado'
}];
export const PERSON_GENDER_OPTIONS = [{
  value: FEMALE,
  label: 'Feminino'
}, {
  value: MALE,
  label: 'Masculino'
}, {
  value: OTHER,
  label: 'Outro'
}, {
  value: NON_SPECIFIED,
  label: 'Não especificado'
}];
export const ETC = 'etc';
export const TAX = 'tax';
export const CTC = 'ctc';
export const RNTRC_CODE = [{
  value: ETC,
  label: 'ETC'
}, {
  value: TAX,
  label: 'TAX'
}, {
  value: CTC,
  label: 'CTC'
}];
export const PERSON_AGE_GROUP_OPTIONS = [{
  value: KID,
  label: 'Crianças'
}, {
  value: YOUNG,
  label: 'Jovens'
}, {
  value: ADULT,
  label: 'Adultos'
}, {
  value: ELDERLY,
  label: 'Idosos'
}];