import { PRESIDENT, SECRETARY, TREASURER, VICE_PRESIDENT } from "../administrativeCouncil/AdministrativeCouncilOptions";
import { COUNSELOR } from "../fiscalCouncil/FiscalCouncilOptions";
export const DIRECTOR = 'director';
export const FISCAL_COUNCIL = 'fiscal_council';
export const ASSOCIATE = 'associate';
export const INVITED = 'invited';
export const CLIENT = 'client';
export const SUPPLIER = 'supplier';
export const NORMAL = 'normal';
export const PARTICIPANT_ROLE = [{
  value: PRESIDENT,
  label: 'Presidente'
}, {
  value: VICE_PRESIDENT,
  label: 'Vice Presidente'
}, {
  value: TREASURER,
  label: 'Tesoureiro'
}, {
  value: SECRETARY,
  label: 'Secretário'
}, {
  value: COUNSELOR,
  label: 'Conselheiro fiscal'
}, {
  value: DIRECTOR,
  label: 'Diretor'
}, {
  value: FISCAL_COUNCIL,
  label: 'Conselho Fiscal'
}, {
  value: ASSOCIATE,
  label: 'Associado'
}, {
  value: INVITED,
  label: 'Convidado'
}, {
  value: CLIENT,
  label: 'Cliente'
}, {
  value: SUPPLIER,
  label: 'Fornecedor'
}, {
  value: NORMAL,
  label: 'Normal '
}];