import gql from "graphql-tag";
import { addressFragment } from 'admin-front';
import { InvoiceAdditionalInfoFragment } from "./InvoiceAdditionalInfo";
import { InvoiceItemFragment, taxInvoiceItemFragment } from "./InvoiceItem";
import { paymentFragment } from "./payment";
import { InvoiceVolumeFragment } from "./InvoiceVolume";
import { fiscalOperationFragment } from "./Operation";
import { fiscalPersonFragment } from "./Person";
import { InvoiceTaxFragment } from "./InvoiceTax";
import { InvoiceTaxWithheldFragment } from "./InvoiceTaxWithheld";
import { electronicDocumentFragment } from "./ElectronicDocument";
import { driverFragment } from "fleet-front";
import { vehicleSimpleFragment } from "fleet-front";
import { invoiceResultCenterFragment } from "./InvoiceResultCenter";
import { invoiceListingFragment } from "./InvoiceListing";
import { ContractFragment } from "./Contract";
import { InvoiceSimpleFragment } from "./InvoiceSimple";
export const InvoiceFragment = gql`
  fragment Invoice on Invoice  {
    ...InvoiceSimple     
    operation {
      ...FiscalOperation
    }
    person {
      ...FiscalPerson
    }
    address {
      ...Address
    }     
    receiver {
      ...FiscalPerson
    }
    pickupLocation {
      ...Address
    }    
    dispatcher {
      ...FiscalPerson
    }
    deliveryPlace {
      ...Address
    }
    shipper {
      ...Driver
    }
    vehicle {
      ...VehicleSimple
    }
    contract {
      ...FiscalContract
    }
    volumes {
      ...InvoiceVolume
    } 
    items {
      ...InvoiceItem
    }
    infos {
      ...InvoiceAdditionalInfo
    }
    payments {
      ...Payment
    }
    resultCenters {
      ...InvoiceResultCenter
    }
    children {
    ...InvoiceListing
    }
  }
  ${InvoiceSimpleFragment}
  ${fiscalPersonFragment}
  ${addressFragment}
  ${fiscalOperationFragment}  
  ${InvoiceVolumeFragment}
  ${InvoiceItemFragment}
  ${paymentFragment}
  ${invoiceListingFragment}
  ${driverFragment}
  ${vehicleSimpleFragment}
  ${invoiceResultCenterFragment}
  ${ContractFragment}
  ${InvoiceAdditionalInfoFragment}
`;
export const TaxInvoiceFragment = gql`
  fragment TaxInvoice on Invoice  { 
    ...Invoice
    invoiceTaxWithheld {
      ...InvoiceTaxWithheld
    }
    items {
      ...TaxInvoiceItem
    }
    invoiceTax {
      ...InvoiceTax
    }   
    electronicDocument {
      ...ElectronicDocument
    }
  }
  ${InvoiceFragment}
  ${electronicDocumentFragment}
  ${InvoiceTaxWithheldFragment}
  ${taxInvoiceItemFragment}
  ${InvoiceTaxFragment}
`;