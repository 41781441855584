import { ICMS_NOT_TAXABLE, OUTSIDE_STATE, ICMS_PREVIOUS_TAXABLE_BY_SUBSTITUTION } from "../constants/taxProfile/TaxFieldsOptions";
export const calculatePIS = (pis, form, indexKey) => {
  if (!pis) return;
  if (pis.errors) {
    form.setFieldValue(['items', indexKey, 'pis'], undefined);
    return;
  }
  const tax = pis.pis;
  const values = {
    'taxSituation': tax?.taxSituation,
    'basis': tax?.basis,
    'rate': tax?.rate,
    'quantity': tax?.quantity,
    'rateByValue': tax?.rateByValue,
    'value': tax?.value,
    'taxProfileId': tax?.taxProfile?.id,
    'taxProfileName': tax?.taxProfile?.name,
    'basisReductionPercentage': tax?.basisReductionPercentage
  };
  form.setFieldValue(['items', indexKey, 'pis'], values);
};
export const calculateIPI = (ipi, form, indexKey) => {
  if (!ipi) return;
  if (ipi.errors) {
    form.setFieldValue(['items', indexKey, 'ipi'], undefined);
    return;
  }
  const tax = ipi.ipi;
  const values = {
    'ipiLegalFrameworkId': tax?.ipiLegalFrameworkId,
    'taxSituation': tax?.taxSituation,
    'basis': tax?.basis || 0,
    'rate': tax?.rate || 0,
    'quantity': tax?.quantity || 0,
    'rateByValue': tax?.rateByValue || 0,
    'value': tax?.value || 0,
    'taxProfileId': tax?.taxProfile?.id,
    'taxProfileName': tax?.taxProfile?.name
  };
  form.setFieldValue(['items', indexKey, 'ipi'], values);
};
export const calculateCOFINS = (cofins, form, indexKey) => {
  if (!cofins) return;
  if (cofins.errors) {
    form.setFieldValue(['items', indexKey, 'cofins'], undefined);
    return;
  }
  const tax = cofins.cofins;
  const values = {
    'taxSituation': tax?.taxSituation,
    'basis': tax?.basis || 0,
    'rate': tax?.rate || 0,
    'quantity': tax?.quantity || 0,
    'rateByValue': tax?.rateByValue || 0,
    'value': tax?.value || 0,
    'taxProfileId': tax?.taxProfile?.id,
    'taxProfileName': tax?.taxProfile?.name,
    'basisReductionPercentage': tax?.basisReductionPercentage || 0
  };
  form.setFieldValue(['items', indexKey, 'cofins'], values);
};
export const calculateICMS = (icms, form, indexKey, operationDestination, taxes = undefined) => {
  if (!icms) return;
  if (icms.errors) {
    form.setFieldValue(['items', indexKey, 'icms'], undefined);
    return;
  }
  const tax = icms.icms || icms;
  const values = {
    'goodsOrigin': tax?.goodsOrigin,
    'taxSituation': tax?.taxSituation,
    'methodBasis': tax?.methodBasis,
    'basisReductionPercentage': tax?.basisReductionPercentage || 0,
    'basis': tax?.basis || 0,
    'rate': tax?.rate || 0,
    'value': tax?.value || 0,
    'fcpBasis': tax?.fcpBasis || 0,
    'fcpRate': tax?.fcpRate || 0,
    'fcpValue': tax?.fcpValue || 0,
    'methodBasisSt': tax?.methodBasisSt,
    'mvaRate': tax?.mvaRate || 0,
    'basisReductionPercentageSt': tax?.basisReductionPercentageSt,
    'basisSt': tax?.basisSt || 0,
    'rateSt': tax?.rateSt || 0,
    'valueSt': tax?.valueSt || 0,
    'fcpBasisSt': tax?.fcpBasisSt || 0,
    'fcpRateSt': tax?.fcpRateSt || 0,
    'fcpValueSt': tax?.fcpValueSt || 0,
    'unencumberedRate': tax?.unencumberedRate || 0,
    'unencumberedBasisReduction': tax?.unencumberedBasisReduction || 0,
    'unencumberedBasis': tax?.unencumberedBasis || 0,
    'unencumberedValue': tax?.unencumberedValue || 0,
    'unencumberedReason': tax?.unencumberedReason,
    'deferralRate': tax?.deferralRate || 0,
    'deferralValue': tax?.deferralValue || 0,
    'valueWithDeferral': tax?.valueWithDeferral || 0,
    'taxProfileId': tax?.taxProfile?.id,
    'taxProfileName': tax?.taxProfile?.name,
    'cestId': tax?.cestId,
    'taxBenefitCodeId': tax?.taxBenefitCodeId,
    'ncmIds': tax?.ncmIds || []
  };
  form.setFieldValue(['items', indexKey, 'icms'], values);
  if (tax.taxProfile) {
    const cfop = (tax.taxProfile.cfops.filter(cfop => cfop.destination === operationDestination) || [])[0];
    form.setFieldValue(['items', indexKey, 'cfopId'], cfop?.cfopId);
  }
  if ([ICMS_NOT_TAXABLE, ICMS_PREVIOUS_TAXABLE_BY_SUBSTITUTION].includes(tax.taxSituation) && form.getFieldValue('operationDestination') === OUTSIDE_STATE) calcICMSTransfer(tax, form, indexKey);
  if (form.getFieldValue(['items', indexKey]).cfopId && taxes) {
    calculateICMSDestination(taxes?.icmsDestination, form, indexKey);
    calculateICMSShared(taxes?.icmsShared?.invoiceItemIcmsSharedCalc, form, indexKey);
  }
};
const calcICMSTransfer = (icms, form, indexKey) => {
  if (!icms) return;
  const values = [{
    'goodsOrigin': icms.goodsOrigin
  }, {
    'taxSituation': icms.taxSituation
  }];
  form.setFieldValue(['items', indexKey, 'icmsTransfer'], values);
};
export const calculateICMSDestination = (icms, form, indexKey) => {
  if (!icms) return;
  const values = {
    'basis': icms.basis || 0,
    'rate': icms.rate || 0,
    'interstateRate': icms.interstateRate || 0,
    'provisionalRate': icms.provisionalRate || 0,
    'value': icms.value || 0,
    'originValue': icms.originValue || 0,
    'fcpBasis': icms.fcpBasis || 0,
    'fcpRate': icms.fcpRate || 0,
    'fcpValue': icms.fcpValue || 0
  };
  form.setFieldValue(['items', indexKey, 'icmsDestination'], values);
};
export const calculateICMSShared = (icms, form, indexKey) => {
  if (!icms) return;
  const values = {
    'stateId': icms.stateId,
    'goodsOrigin': icms.goodsOrigin,
    'taxSituation': icms.taxSituation,
    'methodBasis': icms.methodBasis,
    'basis': icms.basis || 0,
    'value': icms.value || 0,
    'basisReductionPercentage': icms.basisReductionPercentage || 0,
    'rate': icms.rate || 0,
    'methodBasisSt': icms.methodBasisSt || 0,
    'mvaRate': icms.mvaRate || 0,
    'basisReductionPercentageSt': icms.basisReductionPercentageSt || 0,
    'basisSt': icms.basisSt || 0,
    'rateSt': icms.rateSt || 0,
    'valueSt': icms.valueSt || 0
  };
  form.setFieldValue(['items', indexKey, 'icmsShared'], values);
};