import { SELL, REPORTS, CONFIG } from 'grifo-components';
import { NFCE, NFE } from './invoices/InvoiceFieldsOptions';
import { EXIT } from './operations/OperationsFieldsOptions';
export const buildSellFeatures = () => {
  const sellPath = "/" + SELL;
  const sellConfigPath = sellPath + '/' + CONFIG;
  const sellReportPath = sellPath + '/' + REPORTS;
  const sellConfigRoutes = [{
    ordination: '05.10.01',
    name: 'Operações',
    key: sellConfigPath + "/operations",
    routePath: sellConfigPath + "/operations",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "FiscalOperationsPage",
      import_path: "containers/operations/OperationsPage"
    },
    contentProp: {
      operationKind: EXIT
    }
  }];
  const sellReportRoutes = [{
    ordination: '05.11.01',
    name: 'Produtos por Pessoa',
    key: sellReportPath + "/products_by_person",
    routePath: sellReportPath + "/products_by_person",
    kind: 'report',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "ProductsByPersonReportPage",
      import_path: "containers/productByPerson/ProductsByPersonReportPage"
    },
    contentProp: {
      operationType: 1
    }
  }];
  const routes = [{
    ordination: '05.01',
    name: 'Dashboard',
    key: sellPath + "/dashboard",
    routePath: sellPath + "/dashboard",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "DashboardSellPage",
      import_path: "containers/dashboards/DashboardSellPage"
    }
  }, {
    ordination: '05.02',
    name: 'Condicionais',
    key: sellPath + "/conditional",
    routePath: sellPath + "/conditional",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "InvoicesPage",
      import_path: "containers/invoices/InvoicesPage"
    },
    contentProp: {
      operationKind: EXIT,
      onApproval: true
    },
    dividerAbove: true
  }, {
    ordination: '05.03',
    name: 'Contratos de Vendas',
    key: sellPath + "/contracts",
    routePath: sellPath + "/contracts",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "ContractsPage",
      import_path: "containers/contracts/ContractsPage"
    },
    contentProp: {
      operationKind: EXIT
    }
  }, {
    ordination: '05.04',
    name: 'Pedidos de Vendas',
    key: sellPath + "/invoices",
    routePath: sellPath + "/invoices",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "InvoicesPage",
      import_path: "containers/invoices/InvoicesPage"
    },
    contentProp: {
      operationKind: EXIT
    }
  }, {
    ordination: '05.05',
    name: 'NFe - Notas fiscais',
    key: sellPath + "/tax_invoices",
    routePath: sellPath + "/tax_invoices",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "TaxInvoicesPage",
      import_path: "containers/taxInvoice/TaxInvoicesPage"
    },
    contentProp: {
      operationKind: EXIT,
      dfeModel: NFE
    },
    dividerAbove: true
  }, {
    ordination: '05.06',
    name: 'NFCe - Cupons fiscais',
    key: sellPath + "/coupons",
    routePath: sellPath + "/coupons",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "TaxInvoicesPage",
      import_path: "containers/taxInvoice/TaxInvoicesPage"
    },
    contentProp: {
      operationKind: EXIT,
      dfeModel: NFCE
    }
  }, {
    ordination: '05.07',
    name: 'PDV',
    key: sellPath + "/point_of_sales",
    routePath: sellPath + "/point_of_sales",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      component: "PointOfSalesPage",
      import_path: "containers/pointOfSales/PointOfSalesPage"
    },
    routePathParams: {
      target: '_blank'
    }
  }, {
    ordination: '05.08',
    name: 'CTe - Conhecimento de transporte',
    key: sellPath + "/waybills",
    routePath: sellPath + "/waybills",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "WaybillsPage",
      import_path: "containers/waybill/WaybillsPage"
    }
  }, {
    ordination: '05.09',
    name: 'MDFe - Manifesto Eletrônico',
    key: sellPath + "/manifests",
    routePath: sellPath + "/manifests",
    kind: 'page',
    status: 'disabled',
    authorization: 'regular',
    content: {
      page: "ManifestsPage",
      import_path: "containers/manifest/ManifestsPage"
    }
  }, {
    ordination: '05.10',
    name: 'Configurações',
    key: sellConfigPath,
    routePath: sellConfigPath,
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    dividerAbove: true,
    children: sellConfigRoutes
  }, {
    ordination: '05.11',
    name: 'Relatórios',
    key: sellReportPath,
    routePath: sellReportPath,
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    children: sellReportRoutes
  }];
  return {
    ordination: '05',
    name: 'Vendas',
    key: sellPath,
    kind: 'module',
    status: 'active',
    routePath: sellPath,
    routePathParams: undefined,
    dividerAbove: false,
    icon: 'sell',
    content: undefined,
    contentProp: undefined,
    authorization: 'regular',
    userAuthorization: undefined,
    children: routes
  };
};