import gql from "graphql-tag";
import { commercialUnitFragment } from 'stock-front';
export const fiscalProductUnitInvoiceListingFragment = gql`
  fragment FiscalProductUnitInvoiceListing on FiscalProductUnit  {
    id,
    productId,
    packingKindId,
    commercialUnitId,  
    taxableUnitId,
    parentId,
    ncmId,
    barcode,
    multiplier,
    value,
    grossWeight,
    netWeight,
    height,
    width,
    length,
    minimum,
    maximum,
    discardedAt,
    productionTracking,
    batchTracking,
    commercialUnit {
      ...CommercialUnit
    }
  }
  ${commercialUnitFragment}
`;