import { ACCOUNTING, REPORTS, CONFIG, ADVANCED_CONFIG } from 'grifo-components';
export const buildFeatures = () => {
  const accountingPath = "/" + ACCOUNTING;
  const accountingConfigPath = accountingPath + '/' + CONFIG;
  const accountingAdvancedConfigPath = accountingConfigPath + '/' + ADVANCED_CONFIG;
  const accountingReportPath = accountingPath + '/' + REPORTS;
  const accountingAccessoryObligationsPath = "/" + ACCOUNTING + '/accessory_obligations';
  const accountingAdvancedConfigRoutes = [{
    ordination: '11.05.01',
    name: 'Contas Referenciais',
    key: accountingAdvancedConfigPath + "/referential_accounts",
    routePath: accountingAdvancedConfigPath + "/referential_accounts",
    kind: 'page',
    status: 'active',
    authorization: 'super_admin',
    content: {
      page: "AccountsPage",
      import_path: "containers/accounts/AccountsPage"
    },
    contentProp: {
      referential: true
    }
  }, {
    ordination: '11.05.02',
    name: 'Planos Referenciais',
    key: accountingAdvancedConfigPath + "/referential_chart_accounts",
    routePath: accountingAdvancedConfigPath + "/referential_chart_accounts",
    kind: 'page',
    status: 'active',
    authorization: 'super_admin',
    content: {
      page: "ChartsPage",
      import_path: "containers/charts/ChartsPage"
    },
    contentProp: {
      referential: true
    }
  }];
  const accountingConfigRoutes = [{
    ordination: '11.05.01',
    name: 'Operações contábeis',
    key: accountingConfigPath + "/operations",
    routePath: accountingConfigPath + "/operations",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "AccountingOperationsPage",
      import_path: "containers/operations/AccountingOperationsPage"
    }
  }, {
    ordination: '11.05.03',
    name: 'Contas Contábeis',
    key: accountingConfigPath + "/accounts",
    routePath: accountingConfigPath + "/accounts",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "AccountsPage",
      import_path: "containers/accounts/AccountsPage"
    },
    contentProp: {
      referential: false
    },
    dividerAbove: true
  }, {
    ordination: '11.05.04',
    name: 'Planos de Contas',
    key: accountingConfigPath + "/chart_accounts",
    routePath: accountingConfigPath + "/chart_accounts",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "ChartsPage",
      import_path: "containers/charts/ChartsPage"
    },
    contentProp: {
      referential: false
    }
  }, {
    ordination: '11.05.06',
    name: 'Operações de estoque',
    key: accountingConfigPath + "/stock_operations",
    routePath: accountingConfigPath + "/stock_operations",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "StockOperationsPage",
      import_path: "containers/configurations/StockOperationsPage"
    },
    dividerAbove: true
  }, {
    ordination: '11.05.07',
    name: 'Operações financeiras',
    key: accountingConfigPath + "/financial_operations",
    routePath: accountingConfigPath + "/financial_operations",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "FinancialOperationsPage",
      import_path: "containers/configurations/FinancialOperationsPage"
    }
  }, {
    ordination: '11.05.08',
    name: 'Disponibilidades',
    key: accountingConfigPath + "/current_assets",
    routePath: accountingConfigPath + "/current_assets",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "FinancialCurrentAssetsPage",
      import_path: "containers/configurations/FinancialCurrentAssetsPage"
    }
  }, {
    ordination: '11.05.09',
    name: 'Avançado',
    key: accountingAdvancedConfigPath,
    routePath: accountingAdvancedConfigPath,
    kind: 'page',
    status: 'active',
    authorization: 'super_admin',
    dividerAbove: true,
    children: accountingAdvancedConfigRoutes
  }];
  const accountingReportRoutes = [{
    ordination: '11.06.01',
    name: 'Razão contábil',
    key: accountingReportPath + "/accounting_journal",
    routePath: accountingReportPath + "/accounting_journal",
    kind: 'report',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "JournalReportPage",
      import_path: "containers/journals/JournalReportPage"
    }
  }, {
    ordination: '11.06.02',
    name: 'DRE - Demonstração do Resultado do Exercício',
    key: accountingReportPath + "/dre",
    routePath: accountingReportPath + "/dre",
    kind: 'report',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "DreReportPage",
      import_path: "containers/dre/DreReportPage"
    }
  }, {
    ordination: '11.06.03',
    name: 'Balanço Patrimonial',
    key: accountingReportPath + "/patrimonial_balance",
    routePath: accountingReportPath + "/patrimonial_balance",
    kind: 'report',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "PatrimonialBalanceReportPage",
      import_path: "containers/patrimonial-balance/PatrimonialBalanceReportPage"
    }
  }, {
    ordination: '11.06.04',
    name: 'Balancete contábil',
    key: accountingReportPath + "/accounting_balance",
    routePath: accountingReportPath + "/accounting_balance",
    kind: 'report',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "AccountingBalancePage",
      import_path: "containers/balance/AccountingBalancePage"
    }
  }, {
    ordination: '11.06.05',
    name: 'Planos de conta',
    key: accountingReportPath + "/chart_account",
    routePath: accountingReportPath + "/chart_account",
    kind: 'report',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "ChartAccountsReportPage",
      import_path: "containers/balance/ChartAccountsReportPage"
    }
  }];
  const accountingAccessoryObligationsRoutes = [{
    ordination: '11.04.01',
    name: 'SPED - ECD',
    key: accountingAccessoryObligationsPath + "/bookkeepings",
    routePath: accountingAccessoryObligationsPath + "/bookkeepings",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "BookkeepingsPage",
      import_path: "containers/accessoryobligations/BookkeepingsPage"
    }
  }, {
    ordination: '11.04.02',
    name: 'SPED - EFD ICMS/IPI',
    key: accountingAccessoryObligationsPath + "/bookkeeping_taxes",
    routePath: accountingAccessoryObligationsPath + "/bookkeeping_taxes",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "BookkeepingTaxesPage",
      import_path: "containers/accessoryobligations/BookkeepingTaxesPage"
    },
    contentProp: {
      kind: 'state'
    }
  }, {
    ordination: '11.04.03',
    name: 'SPED - ECF',
    key: accountingAccessoryObligationsPath + "/bookkeeping_fiscals",
    routePath: accountingAccessoryObligationsPath + "/bookkeeping_fiscals",
    kind: 'page',
    status: 'active',
    authorization: 'super_admin',
    content: {
      page: "BookkeepingFiscalsPage",
      import_path: "containers/accessoryobligations/BookkeepingFiscalsPage"
    }
  }, {
    ordination: '11.04.04',
    name: 'SPED - EFD PIS/COFINS',
    key: accountingAccessoryObligationsPath + "/bookkeeping_taxes_pis_cofins",
    routePath: accountingAccessoryObligationsPath + "/bookkeeping_taxes_pis_cofins",
    kind: 'page',
    status: 'active',
    authorization: 'super_admin',
    content: {
      page: "BookkeepingTaxesPage",
      import_path: "containers/accessoryobligations/BookkeepingTaxesPage"
    },
    contentProp: {
      kind: 'federal'
    }
  }];
  const routes = [{
    ordination: '11.01',
    name: 'Dashboard',
    key: accountingPath + "/dashboard",
    routePath: accountingPath + "/dashboard",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "DashboardAccountingPage",
      import_path: "containers/DashboardAccountingPage"
    }
  }, {
    ordination: '11.02',
    name: 'Exercício Contábil',
    key: accountingPath + "/year",
    routePath: accountingPath + "/year",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "AccountingYearsPage",
      import_path: "containers/years/AccountingYearsPage"
    },
    dividerAbove: true
  }, {
    ordination: '11.03',
    name: 'Movimentações',
    key: accountingPath + "/journals",
    routePath: accountingPath + "/journals",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "JournalsPage",
      import_path: "containers/journals/JournalsPage"
    },
    contentProp: {
      referential: true
    }
  }, {
    ordination: '11.04',
    name: 'Obrigações Acessórias',
    key: accountingAccessoryObligationsPath,
    routePath: accountingAccessoryObligationsPath,
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    dividerAbove: true,
    children: accountingAccessoryObligationsRoutes
  }, {
    ordination: '11.05',
    name: 'Configurações',
    key: accountingConfigPath,
    routePath: accountingConfigPath,
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    children: accountingConfigRoutes
  }, {
    ordination: '11.06',
    name: 'Relatórios',
    key: accountingReportPath,
    routePath: accountingReportPath,
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    children: accountingReportRoutes
  }];
  return {
    ordination: '11',
    name: 'Contabilidade',
    key: accountingPath,
    kind: 'module',
    status: 'active',
    routePath: accountingPath,
    routePathParams: undefined,
    dividerAbove: false,
    icon: 'accounting',
    content: undefined,
    contentProp: undefined,
    authorization: 'regular',
    userAuthorization: undefined,
    children: routes
  };
};