import gql from "graphql-tag";
import { commercialUnitFragment } from "./CommercialUnit";
import { batchFragment } from "./Batch";
import { packingKindFragment } from "./PackingKind";
export const productUnitSimpleForSearchFragment = gql`
  fragment ProductUnitSimpleForSearch on ProductUnit  {
    id,
    name,
    productId,
    commercialUnitId,
    parentId,
    barcode,
    multiplier,
    grossWeight,
    netWeight,
    height,
    width,
    length,
    discardedAt,
    packingKindId,
    productionTracking,
    batchTracking,
    minimum,
    maximum,
    discardedAt,    
    code,
    commercialUnit {
      ...CommercialUnit
    }
  }
  ${commercialUnitFragment} 
`;
export const productUnitSimpleFragment = gql`
  fragment ProductUnitSimple on ProductUnit  {
    ...ProductUnitSimpleForSearch
    batches {
      ...Batch
    }
    packingKind {
      ...PackingKind
    }    
  }
  ${productUnitSimpleForSearchFragment}
  ${batchFragment}
  ${packingKindFragment}
`;
export const productUnitFragment = gql`
  fragment ProductUnit on ProductUnit  {
    ...ProductUnitSimple
    parent {
      ...ProductUnitSimple
    },
  }
  ${productUnitSimpleFragment}
`;