import { WITHHOLDING, MUNICIPAL_MICRO_COMPANY, ISS_CHARGEABLE } from "../constants/taxProfile/TaxFieldsOptions";
import { NO } from 'grifo-components';
export const issParamBuilder = (record, values) => {
  return {
    attributes: values.iss.map(object => ({
      ...object,
      taxProfileId: record.id
    }))
  };
};
export const issDefaultBuilder = record => {
  return {
    rate: (record || {}).rate || 0,
    basisReductionPercentage: (record || {}).basisReductionPercentage || 0,
    kind: (record || {}).kind || WITHHOLDING,
    taxIncentive: (record || {}).taxIncentive || NO,
    chargeabilityIndicator: (record || {}).chargeabilityIndicator || ISS_CHARGEABLE,
    specialTaxRegime: (record || {}).specialTaxRegime || MUNICIPAL_MICRO_COMPANY,
    suspendedChargeCourtCaseNumber: (record || {}).suspendedChargeCourtCaseNumber,
    continuousSupplyCode: (record || {}).continuousSupplyCode
  };
};