import { ICMS_NOT_TAXABLE, ICMS_PREVIOUS_TAXABLE_BY_SUBSTITUTION } from "../constants/taxProfile/TaxFieldsOptions";
export const setTranferField = (form, field, indexName, value) => {
  const item = form.getFieldValue(['items', indexName]);
  if (!form || !field || !(indexName >= 0) || !value || !item) return;
  if (field === 'withheldBasis') field = 'withheldBasisDestination';
  if (field === 'withheldValue') field = 'withheldValueDestination';
  if (field === 'taxSituation' && ![ICMS_NOT_TAXABLE, ICMS_PREVIOUS_TAXABLE_BY_SUBSTITUTION].includes(value)) {
    delete item.icmsTransfer;
    form.setFieldValue(['items', indexName], item);
    return;
  }
  if (field !== 'goodsOrigin' && item.icmsTransfer?.taxSituation === ICMS_NOT_TAXABLE) return;
  item.icmsTransfer[field] = value;
  form.setFieldValue(['items', indexName], item);
};