import gql from "graphql-tag";
import { cityFragment } from "./City";
import { stateFragment } from "./State";
export const addressFragment = gql`
  fragment Address on Address {
    id,
    alias,
    naming,
    district,
    street,
    number,
    complement,
    room,
    zipcode,
    cityId,
    city {
      ...City
    },
    stateId,
    state {
      ...State
    }
  }
  ${cityFragment}
  ${stateFragment}  
`;