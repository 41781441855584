import gql from "graphql-tag";
import { accountSimpleFragment } from "./Account";
import { movementSimpleFragment } from "./Movement";
export const reconciliationMovementFragment = gql`
  fragment ReconciliationMovement on ReconciliationMovement {
    id,
    transactionId,
    value,
    movement {
      ...MovementSimple
    },
  }
  ${movementSimpleFragment}
`;