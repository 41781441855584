import { DFE_AUTHORIZED, DFE_CANCELLED, DFE_DENIED, DFE_PENDING_SUBMISSION, DFE_PROCESSING, DFE_PROCESSING_CANCELLATION, DFE_REJECTED, DFE_SENT } from "../dfes/DfeFieldsOptions";
export const XML = 'xml';
export const SYSTEM = 'system';
export const TRUNCATER = 'truncater';
export const ROUNDER = 'rounder';
export const COMPANY_LOCATION = 'company_location';
export const PERSON_LOCATION = 'person_location';
export const DELIVERY_OTHER_LOCATION = 'delivery_other_location';
export const PICKUP_OTHER_LOCATION = 'pickup_other_location';
export const HIRING_FREIGHT_BY_SENDER = 'hiring_freight_by_sender';
export const HIRING_FREIGHT_BY_RECIPIENT = 'hiring_freight_by_recipient';
export const HIRING_FREIGHT_BY_THIRD_PARTY = 'hiring_freight_by_third_party';
export const OWN_FREIGHT_BY_SENDER = 'own_freight_by_sender';
export const OWN_FREIGHT_BY_RECIPIENT = 'own_freight_by_recipient';
export const WITHOUT_FREIGHT = 'without_freight';
export const VIEWING = 'viewing';
export const OPENED = 'opened';
export const ONLY_PREVIEW = 'only_preview';
export const RESERVATION = 'reservation';
export const FINISHED = 'finished';
export const BILLED = 'billed';
export const CANCELLED = 'cancelled';
export const GROUPED = 'grouped';
export const ON_APPROVAL = 'on_approval';
export const NORMAL_DFE = 'normal_df_e';
export const COMPLEMENTARY_DFE = 'complementary_df_e';
export const ADJUSTMENT_DFE = 'adjustment_df_e';
export const GOODS_RETURN_DFE = 'goods_return_df_e';
export const NFE = 'nf_e';
export const NFCE = 'nfc_e';
export const CTE = 'ct_e';
export const CTE_OS = 'ct_e_os';
export const MDFE = 'mdf_e';
export const FROM_INVOICE = 'from_invoice';
export const FROM_REQUEST = 'from_request';
export const FROM_OFFER = 'from_offer';
export const FROM_SALES = 'from_sales';
export const FROM_CONTRACT = 'from_contract';
export const INVOICE = 'invoice';
export const UNCOOPERATIVE_ACT = 'uncooperative_act';
export const COOPERATIVE_ACT = 'cooperative_act';
export const ANYONE = 'anyone';
export const ISSUER = 'issuer';
export const INVOICES_COOPERATIVE_ACT = [{
  value: UNCOOPERATIVE_ACT,
  label: 'Não Cooperado'
}, {
  value: COOPERATIVE_ACT,
  label: 'Cooperado'
}];
export const INVOICES_COOPERATIVE_ACT_BY_KEY = [{
  value: '0',
  label: 'Não Cooperado'
}, {
  value: '1',
  label: 'Cooperado'
}];
export const PRECISION_OPTIONS = [{
  value: ROUNDER,
  label: 'Arredondamento'
}, {
  value: TRUNCATER,
  label: 'Corte'
}];
export const PICKUP_METHOD_OPTIONS = [{
  text: 'Endereço da empresa',
  value: COMPANY_LOCATION,
  description: 'Os produtos serão retirados na própria empresa.',
  label: 'Na própria empresa'
}, {
  text: 'Outro local',
  value: PICKUP_OTHER_LOCATION,
  description: 'Os produtos serão retirados em outro local.',
  label: 'Em outro local'
}];
export const DELIVERY_METHOD_OPTIONS = [{
  text: 'Endereço da pessoa',
  value: PERSON_LOCATION,
  description: 'Os produtos serão entregues no endereço da pessoa.',
  label: 'No endereço da pessoa'
}, {
  text: 'Outro local',
  value: DELIVERY_OTHER_LOCATION,
  description: 'Os produtos serão entregues em outro local.',
  label: 'Em outro local'
}];
export const FREIGHT_METHOD_OPTIONS = [{
  label: 'Contratação do Frete por conta do Remetente (CIF)',
  value: HIRING_FREIGHT_BY_SENDER
}, {
  label: 'Contratação do Frete por conta do Destinatário (FOB)',
  value: HIRING_FREIGHT_BY_RECIPIENT
}, {
  label: 'Contratação do Frete por conta de Terceiros',
  value: HIRING_FREIGHT_BY_THIRD_PARTY
}, {
  label: 'Transporte Próprio por conta do Remetente',
  value: OWN_FREIGHT_BY_SENDER
}, {
  label: 'Transporte Próprio por conta do Destinatário',
  value: OWN_FREIGHT_BY_RECIPIENT
}, {
  label: 'Sem Ocorrência de Transporte',
  value: WITHOUT_FREIGHT
}];
export const SITUATION_OPTIONS_BY_KEY = [{
  label: 'Aberto',
  value: '0'
}, {
  label: 'Reservado',
  value: '1'
}, {
  label: 'Finalizado',
  value: '2'
}, {
  label: 'Faturado',
  value: '3'
}, {
  label: 'Cancelado',
  value: '99'
}, {
  label: 'Agrupado',
  value: '4'
}];
export const SITUATION_OPTIONS = [{
  label: 'Aberto',
  value: OPENED
}, {
  label: 'Reservado',
  value: RESERVATION
}, {
  label: 'Finalizado',
  value: FINISHED
}, {
  label: 'Faturado',
  value: BILLED
}, {
  label: 'Cancelado',
  value: CANCELLED
}, {
  label: 'Agrupado',
  value: GROUPED
}, {
  label: 'Não enviado automaticamente',
  value: ONLY_PREVIEW
}, {
  label: 'Condicional',
  value: ON_APPROVAL
}];
export const PRODUCTS_BY_PERSON_REPORT_OPTIONS = [{
  label: 'Aberto',
  value: OPENED
}, {
  label: 'Finalizado',
  value: FINISHED
}, {
  label: 'Faturado',
  value: BILLED
}];
export const ELECTRONIC_SITUATION_OPTIONS = [{
  label: 'Em prévia',
  value: ONLY_PREVIEW
}, {
  label: 'Envio Pendente',
  value: DFE_PENDING_SUBMISSION
}, {
  label: 'Enviando para SEFAZ',
  value: DFE_PROCESSING
}, {
  label: 'Recebido pelo SEFAZ',
  value: DFE_SENT
}, {
  label: 'Autorizado',
  value: DFE_AUTHORIZED
}, {
  label: 'Denegado',
  value: DFE_DENIED
}, {
  label: 'Rejeitado',
  value: DFE_REJECTED
}, {
  label: 'Processando cancelamento',
  value: DFE_PROCESSING_CANCELLATION
}, {
  label: 'Cancelado',
  value: DFE_CANCELLED
}];
export const ISSUANCE_PURPOSE_OPTIONS_BY_KEY = [{
  label: 'NF-e normal',
  value: '1'
}, {
  label: 'NF-e complementar',
  value: '2'
}, {
  label: 'NF-e de ajuste',
  value: '3'
}, {
  label: 'NF-e de devolução de mercadoria',
  value: '4'
}];
export const ISSUANCE_PURPOSE_OPTIONS = [{
  label: 'NF-e normal',
  value: NORMAL_DFE
}, {
  label: 'NF-e complementar',
  value: COMPLEMENTARY_DFE
}, {
  label: 'NF-e de ajuste',
  value: ADJUSTMENT_DFE
}, {
  label: 'NF-e de devolução de mercadoria',
  value: GOODS_RETURN_DFE
}];
export const OTHER_ISSUANCE_PURPOSE_OPTIONS = [{
  label: 'NF-e normal',
  value: NORMAL_DFE
}, {
  label: 'NF-e complementar',
  value: COMPLEMENTARY_DFE
}, {
  label: 'NF-e de ajuste',
  value: ADJUSTMENT_DFE
}];
export const RECEIVER_OPTIONS = [{
  label: 'Qualquer pessoa',
  value: ANYONE
}, {
  label: 'Emissor',
  value: ISSUER
}];
export const OPERATION_MODEL_OPTIONS_EXIT_BY_KEY = [{
  label: 'NF-e - Nota Fiscal Eletrônica',
  value: '55'
}, {
  label: 'NFC-e - Cupom Fiscal Eletrônico',
  value: '65'
}, {
  label: 'CT-e - Conhecimento de Transporte Eletrônico',
  value: '57'
}, {
  label: 'CT-e - Conhecimento de Transporte Eletrônico - Outros Serviços',
  value: '67',
  disabled: true
}, {
  label: 'MDF-e - Manifesto Eletrônico de Documentos Fiscais',
  value: '58'
}];
export const OPERATION_MODEL_OPTIONS_EXIT = [{
  label: 'NF-e - Nota Fiscal Eletrônica',
  value: NFE
}, {
  label: 'NFC-e - Cupom Fiscal Eletrônico',
  value: NFCE
}, {
  label: 'CT-e - Conhecimento de Transporte Eletrônico',
  value: CTE
}, {
  label: 'CT-e - Conhecimento de Transporte Eletrônico - Outros Serviços',
  value: CTE_OS,
  disabled: true
}, {
  label: 'MDF-e - Manifesto Eletrônico de Documentos Fiscais',
  value: MDFE
}];
export const OPERATION_MODEL_OPTIONS_ENTRY = [{
  label: 'NF-e - Nota Fiscal Eletrônica',
  value: NFE
}, {
  label: 'NFC-e - Cupom Fiscal Eletrônico',
  value: NFCE
}, {
  label: 'CT-e - Conhecimento de Transporte Eletrônico',
  value: CTE,
  disabled: true
}, {
  label: 'CT-e - Conhecimento de Transporte Eletrônico - Outros Serviços',
  value: CTE_OS,
  disabled: true
}, {
  label: 'MDF-e - Manifesto Eletrônico de Documentos Fiscais',
  value: MDFE,
  disabled: true
}];
export const UNUSED_MODEL_OPTIONS = [{
  label: 'NF-e - Nota Fiscal Eletrônica',
  value: NFE
}, {
  label: 'NFC-e - Cupom Fiscal Eletrônico',
  value: NFCE
}, {
  label: 'CT-e - Conhecimento de Transporte Eletrônico',
  value: CTE
}, {
  label: 'CT-e - Conhecimento de Transporte Eletrônico - Outros Serviços',
  value: CTE_OS,
  disabled: true
}];
export const MODEL_OPTIONS = [{
  label: 'NF-e - Nota Fiscal Eletrônica',
  value: NFE
}, {
  label: 'NFC-e - Nota Fiscal de Consumidor Eletrônica',
  value: NFCE
}, {
  label: 'CT-e - Conhecimento de Transporte Eletrônico',
  value: CTE
}, {
  label: 'CT-e - Conhecimento de Transporte Eletrônico - Outros Serviços',
  value: CTE_OS
}, {
  label: 'MDF-e - Manifesto Eletrônico de Documentos Fiscais',
  value: MDFE
}];
export const MODEL_OPTIONS_FROM_NUMBER = [{
  label: 'NF-e - Nota Fiscal Eletrônica',
  value: 55
}, {
  label: 'NFC-e - Nota Fiscal de Consumidor Eletrônica',
  value: 65
}, {
  label: 'CT-e - Conhecimento de Transporte Eletrônico',
  value: 57
}, {
  label: 'CT-e - Conhecimento de Transporte Eletrônico - Outros Serviços',
  value: 67
}, {
  label: 'MDF-e - Manifesto Eletrônico de Documentos Fiscais',
  value: 58
}];
export const GENERATED_BY_OPTIONS = [{
  label: 'Emitida',
  value: SYSTEM
}, {
  label: 'Importada',
  value: XML
}];