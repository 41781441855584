import { sanitizeBackend, getSessionInfo } from 'grifo-components';
export const productUnitParamAttributesBuilder = (record, values, parentRecord = undefined) => {
  return {
    id: record?.id,
    productId: parentRecord.id,
    commercialUnitId: values.commercialUnitId,
    barcode: values.barcode?.length > 0 ? values.barcode : null,
    grossWeight: values.grossWeight,
    netWeight: values.netWeight,
    height: values.height,
    width: values.width,
    length: values.length,
    parentId: values.parentId,
    multiplier: values.multiplier,
    minimum: values.minimum,
    maximum: values.maximum,
    batches: (values.batches || []).map(object => ({
      ...object,
      productUnitId: values.id,
      companyId: getSessionInfo().company.id
    })),
    packingKindId: values.packingKindId,
    productionTracking: values.productionTracking,
    batchTracking: values.batchTracking
  };
};
export const productUnitParamBuilder = (record, values, parentRecord = undefined) => {
  return {
    attributes: productUnitParamAttributesBuilder(record, values, parentRecord),
    productId: parentRecord.id
  };
};
export const productUnitDefaultBuilder = record => {
  const object = record || {};
  return {
    commercialUnitId: object.commercialUnitId,
    barcode: object.barcode,
    grossWeight: object.grossWeight || 0,
    netWeight: object.netWeight || 0,
    height: object.height || 0,
    length: object.length || 0,
    width: object.width || 0,
    parentId: object.parentId,
    multiplier: object.multiplier || 0,
    minimum: object.minimum || 0,
    maximum: object.maximum || 0,
    batches: (object.batches || []).map(object => ({
      ...object,
      manufacturingDate: sanitizeBackend(object.manufacturingDate),
      expirationDate: sanitizeBackend(object.expirationDate)
    })),
    packingKindId: object.packingKindId,
    productionTracking: object.productionTracking,
    batchTracking: object.batchTracking
  };
};