import moment from 'moment';
import { CREDIT } from '../constants/currentAssets/CurrentAssetsFieldsOptions';
import { Ofx } from 'ofx-data-extractor';
export const parseOfx = fileUrl => {
  let content = null;
  try {
    const file = fs.readFile(fileUrl);
    const ofx = Ofx.fromBuffer(file);
    content = ofx.toJson();
  } catch (e) {
    content = {
      errors: e
    };
  }
  return content;
};
export const getMomentFromStringDate = stringDate => {
  if (!stringDate) return;
  let date = stringDate.slice(0, 8);
  let year = date.slice(0, 4);
  let month = date.slice(4, 6);
  let day = date.slice(6, 8);
  return moment(`${year}/${month}/${day}`, 'YYYY/M/D');
};
export const getAccountInfos = ofx => {
  try {
    const headers = ofx.getHeaders();
    return {
      account: headers,
      transactions: (getTransactions(ofx) || {}).ofx,
      errors: null
    };

    // return { 
    //   account: ofx.BANKMSGSRSV1.STMTTRNRS.STMTRS.BANKACCTFROM,
    //   dateStart: getMomentFromStringDate(ofx.BANKMSGSRSV1.STMTTRNRS.STMTRS.BANKTRANLIST.DTSTART),
    //   dateEnd: getMomentFromStringDate(ofx.BANKMSGSRSV1.STMTTRNRS.STMTRS.BANKTRANLIST.DTEND),
    //   balance: ofx.BANKMSGSRSV1.STMTTRNRS.STMTRS.LEDGERBAL.BALAMT,
    //   transactions: (getTransactions(ofx) || {}).ofx,
    //   errors: null
    // }
  } catch (e) {
    return {
      errors: e
    };
  }
};
export const getTransactions = ofx => {
  if (!ofx) return;
  try {
    return {
      ofx: ofx.getTransactionsSummary()
    };

    // let transactions = ofx.BANKMSGSRSV1.STMTTRNRS.STMTRS.BANKTRANLIST.STMTTRN
    // transactions = Array.isArray(transactions) ? transactions : [transactions]
    // return { ofx: transactions.sort((a) => { return a.TRNTYPE === CREDIT ? -1 : 1 }) }
  } catch (e) {
    return e;
  }
};