import gql from "graphql-tag";
import { personSearchFragment } from "./Person";
import { tenantUserFragment } from "./TenantUser";
import { tenantFragment } from "./Tenant";
import { authorizationFragment } from "./Authorization";
import { companySimpleFragment } from "./Company";
export const userSimpleFragment = gql`
  fragment UserSimple on User {
    id,
    email,
    name,
    document,
    profile,
    tenantId,
    createdAt,
    updatedAt,
    confirmedAt,
    discardedAt,
    lockedAt,
    resetPasswordSentAt,
    confirmationToken,
    signInCount,
    currentSignInAt,
    lastSignInAt,
    lastSignInIp,
    failedAttempts,    
  }
`;
export const userFragment = gql`
  fragment User on User {
    ...UserSimple
    person {
      ...PersonSearch
    },
    tenant {
      ...Tenant
    },
    tenants {
      ...Tenant
    },
    tenantUser {
      ...TenantUser
    }, 
    tenantUsers {
      ...TenantUser
    },   
    companies {
      id
    },
    company {
      ...CompanySimple
    }
    authorizations {
      ...Authorization
    }
  }
  ${userSimpleFragment}
  ${tenantFragment}  
  ${personSearchFragment}
  ${tenantUserFragment}    
  ${companySimpleFragment}
  ${authorizationFragment}  
`;
export const userStoreSimpleFragment = gql`
  fragment UserStoreSimple on UserStore {
    id,
    email,
    name,
    document,
    profile,
    tenantId,
    createdAt,
    updatedAt,
    confirmedAt,
    discardedAt,
    lockedAt,
    resetPasswordSentAt,
    confirmationToken,
    signInCount,
    currentSignInAt,
    lastSignInAt,
    lastSignInIp,
    failedAttempts,    
  }
`;
export const userStoreFragment = gql`
  fragment UserStore on UserStore {
    ...UserStoreSimple,
    person {
      ...PersonSearch
    },
    tenant {
      ...Tenant
    },
    tenants {
      ...Tenant
    },
    tenantUser {
      ...TenantUser
    }, 
    currentTenantUser {
      person {
        ...PersonSearch
      }
    }, 
    tenantUsers {
      ...TenantUser
    },
    companies {
      id
    },
    company {
      ...CompanySimple
    }
  }
  ${userStoreSimpleFragment}
  ${personSearchFragment}
  ${tenantFragment}    
  ${tenantUserFragment}
  ${companySimpleFragment}
`;