import { sanitizeBackend } from 'grifo-components';
import { EVERYONE } from '../constants/offers/offerFieldsOptions';
export const offerParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      quantity: values.quantity,
      availableQuantity: values.quantity,
      maxPerBid: values.maxPerBid,
      showAvailable: values.showAvailable,
      start: values.start,
      finish: values.finish,
      availability: values.availability,
      price: values.price,
      productUnitId: values.productUnit[1],
      locationId: values.productUnit[2],
      batchId: values.productUnit[3],
      operationId: values.operationId,
      payments: (values.payments || []).map((object, index) => {
        delete object.paymentMethod;
        delete object.paymentCondition;
        return {
          ...object
        };
      })
    }
  };
};
export const offerDefaultBuilder = record => {
  const object = record || {};
  return {
    company: object.company,
    availableQuantity: object.availableQuantity || 0.00,
    quantity: object.quantity || 0.00,
    maxPerBid: object.maxPerBid || 0.00,
    showAvailable: object.showAvailable,
    start: sanitizeBackend(object.start),
    finish: sanitizeBackend(object.finish),
    availability: object.availability || EVERYONE,
    price: object.price || 0.00,
    productUnit: [(object.productUnit || {}).productId, object.productUnitId, object.locationId, object.batchId],
    toLabel: object.toLabel,
    active: object.active,
    bid: object.bid,
    operationId: (object.operation || {}).id,
    operationType: (object.operation || {}).kind,
    payments: object.payments || [{}]
  };
};
export const loadOfferPayments = (record, form, index) => {
  if (!record && !form) return;
  let fieldsValue = record ? record : form ? form.getFieldsValue() : {};
  if (!fieldsValue || !fieldsValue.payments || !fieldsValue.payments[index]) return;
  let payments = fieldsValue.payments[index] || {};
  return {
    paymentMethodId: (payments.paymentMethod || {}).id ? (payments.paymentMethod || {}).id : payments.paymentMethodId ? payments.paymentMethodId : undefined,
    paymentConditionId: (payments.paymentCondition || {}).id ? (payments.paymentCondition || {}).id : payments.paymentConditionId ? payments.paymentConditionId : undefined
  };
};