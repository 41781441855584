import gql from "graphql-tag";
import { personFragment } from 'admin-front';
export const administrativeCouncilFragment = gql`
  fragment AdministrativeCouncil on AdministrativeCouncil  { 
    id,
    role,
    start,
    finish,
    personId,
    person {
      ...Person
    }
  }
  ${personFragment}

`;