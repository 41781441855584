import gql from "graphql-tag";
export const resultCenterFragment = gql`
  fragment ResultCenter on ResultCenter {
    id,
    description,
    name,
    discardedAt,
    parent {
      id
    }
  }
`;
export const parentResultCenterFragment = gql`
  fragment ParentResultCenter on ResultCenter {
    ...ResultCenter,
    parent {
      ...ResultCenter,
    },
    children {
      ...ResultCenter,
      children {
        ...ResultCenter,
        children {
          ...ResultCenter,
          children {
            ...ResultCenter,
            children {
              ...ResultCenter,
            }
          }
        }
      }
    }    
  }
  ${resultCenterFragment}
`;