import gql from "graphql-tag";
import { accountSimpleFragment } from "./Account";
import { operationFragment } from "./Operation";
import { currentAssetOpeningFragment } from "./CurrentAssetOpening";
export const simpleCurrentAssetFragment = gql`
  fragment CurrentAssetSimple on CurrentAsset  { 
    id,
    name,
    kind,
    controlsOpening,
    opened,
    userId,
    discardedAt,
    implantationDate,
    operationId,
  }
`;
export const currentAssetFragment = gql`
  fragment CurrentAsset on CurrentAsset  { 
    ...CurrentAssetSimple,
    account {
      ...AccountSimple
    }
    operation {
      ...Operation      
    }
    currentAssetOpenings {
      ...CurrentAssetOpening
    }
    currentAssetActualOpening {
      ...CurrentAssetOpening
    }
  }
  ${simpleCurrentAssetFragment}
  ${accountSimpleFragment}
  ${operationFragment}
  ${currentAssetOpeningFragment}
`;
export const currentAssetSelectionFragment = gql`
  fragment CurrentAssetSelection on CurrentAsset  { 
    id,
    name,
    account {
      ...AccountSimple
    }
  }
  ${accountSimpleFragment}
`;