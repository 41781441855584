import { COOPERATIVE, getSessionInfo, CONFIG } from 'grifo-components';
import { CAPITAL_PAYMENT } from 'financial-front';
import { ASSEMBLY_CLOSED, ASSEMBLY_OPENED } from './Assembly/AssemblyEventOptions';
import { POLL_CLOSED } from './Assembly/PollEventOptions';
import MembershipByPersonQuery from '../queries/MembershipByPersonQuery';
const session = () => {
  return getSessionInfo() || {};
};
const company = () => {
  return session().company || {};
};
const organization = () => {
  return session().organization || {};
};
const canAccessCooperativeResources = () => {
  return company().isCooperative;
};
const getPerson = () => {
  return session().person;
};
const getStatutoryCapitalBase = () => {
  if (!company().isCooperative) return 0;
  return organization().statutoryCapitalBase;
};
export const buildFeatures = () => {
  const cooperativePath = "/" + COOPERATIVE;
  const cooperativeAssemblyPath = cooperativePath + "/assembly";
  const cooperativeCouncilPath = cooperativePath + "/councils";
  const cooperativeStatementPath = cooperativePath + '/statements';
  const cooperativeConfigPath = cooperativePath + '/' + CONFIG;
  const cooperativeStatementsRoutes = [{
    ordination: '08.08.01',
    name: 'Modelo de proposta de associação',
    key: cooperativeStatementPath + "/empty_membership_proposal",
    routePath: cooperativeStatementPath + "/empty_membership_proposal",
    kind: 'page',
    status: canAccessCooperativeResources() ? 'active' : 'disabled',
    authorization: 'regular',
    content: {
      page: "EmptyMembershipProposal",
      import_path: "containers/statements/EmptyMembershipProposalPage"
    }
  }];
  const cooperativeAssemblyRoutes = [{
    ordination: '08.05.01',
    name: 'Eventos',
    key: cooperativeAssemblyPath + "/assembly",
    routePath: cooperativeAssemblyPath + "/assembly",
    kind: 'page',
    status: canAccessCooperativeResources() ? 'active' : 'disabled',
    authorization: 'regular',
    content: {
      page: "AssembliesPage",
      import_path: "containers/assembly/AssembliesPage"
    }
  }, {
    ordination: '08.05.02',
    name: 'Eventos em Andamento',
    key: cooperativeAssemblyPath + "/assembly_opened",
    routePath: cooperativeAssemblyPath + "/assembly_opened",
    kind: 'page',
    status: canAccessCooperativeResources() ? 'active' : 'disabled',
    authorization: 'regular',
    content: {
      page: "AssembliesPage",
      import_path: "containers/assembly/AssembliesPage"
    },
    contentProp: {
      event: ASSEMBLY_OPENED
    }
  }, {
    ordination: '08.05.03',
    name: 'Eventos Encerrados',
    key: cooperativeAssemblyPath + "/assembly_closed",
    routePath: cooperativeAssemblyPath + "/assembly_closed",
    kind: 'page',
    status: canAccessCooperativeResources() ? 'active' : 'disabled',
    authorization: 'regular',
    content: {
      page: "AssembliesPage",
      import_path: "containers/assembly/AssembliesPage"
    },
    contentProp: {
      event: ASSEMBLY_CLOSED
    }
  }, {
    ordination: '08.05.04',
    name: 'Enquetes',
    key: cooperativeAssemblyPath + "/polls",
    routePath: cooperativeAssemblyPath + "/polls",
    kind: 'page',
    status: canAccessCooperativeResources() ? 'active' : 'disabled',
    authorization: 'regular',
    content: {
      page: "PollsPage",
      import_path: "containers/poll/PollsPage"
    },
    contentProp: {
      event: POLL_CLOSED
    },
    dividerAbove: true
  }];
  const cooperativeCouncilRoutes = [{
    ordination: '08.06.01',
    name: 'Conselho Administrativo',
    key: cooperativeCouncilPath + "/administrative_council",
    routePath: cooperativeCouncilPath + "/administrative_council",
    kind: 'page',
    status: canAccessCooperativeResources() ? 'active' : 'disabled',
    authorization: 'regular',
    content: {
      page: "AdministrativeCouncilsPage",
      import_path: "containers/administrativeCouncil/AdministrativeCouncilsPage"
    }
  }, {
    ordination: '08.06.02',
    name: 'Conselho Fiscal',
    key: cooperativeCouncilPath + "/fiscal_council",
    routePath: cooperativeCouncilPath + "/fiscal_council",
    kind: 'page',
    status: canAccessCooperativeResources() ? 'active' : 'disabled',
    authorization: 'regular',
    content: {
      page: "FiscalCouncilsPage",
      import_path: "containers/fiscalCouncil/FiscalCouncilsPage"
    }
  }, {
    ordination: '08.06.03',
    name: 'Eleições de conselho',
    key: cooperativeCouncilPath + "/elections",
    routePath: cooperativeCouncilPath + "/elections",
    kind: 'page',
    status: canAccessCooperativeResources() ? 'active' : 'disabled',
    authorization: 'regular',
    content: {
      page: "ElectionsPage",
      import_path: "containers/elections/ElectionsPage"
    },
    dividerAbove: true
  }, {
    ordination: '08.06.04',
    name: 'Chapas de conselho',
    key: cooperativeCouncilPath + "/slates",
    routePath: cooperativeCouncilPath + "/slates",
    kind: 'page',
    status: canAccessCooperativeResources() ? 'active' : 'disabled',
    authorization: 'regular',
    content: {
      page: "SlatesPage",
      import_path: "containers/slates/SlatesPage"
    }
  }];
  const cooperativeConfigRoutes = [{
    ordination: '08.07.01',
    name: 'Cooperativa',
    key: cooperativeConfigPath + "/organization",
    routePath: cooperativeConfigPath + "/organization",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "OrganizationsPage",
      import_path: "containers/organizations/OrganizationsPage"
    }
  }, {
    ordination: '08.07.02',
    name: 'Pontos de interesse',
    key: cooperativeConfigPath + "/points_of_interest",
    routePath: cooperativeConfigPath + "/points_of_interest",
    kind: 'page',
    status: canAccessCooperativeResources() ? 'active' : 'disabled',
    authorization: 'regular',
    content: {
      page: "PointsOfInterestPage",
      import_path: "containers/pointsOfInterest/PointsOfInterestPage"
    }
  }];
  const routes = [{
    ordination: '08.01',
    name: 'Dashboard',
    key: cooperativePath + "/dashboard",
    routePath: cooperativePath + "/dashboard",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "DashboardCooperativePage",
      import_path: "containers/DashboardPage"
    }
  }, {
    ordination: '08.02',
    name: 'Matrículas',
    key: cooperativePath + "/member_registrations",
    routePath: cooperativePath + "/member_registrations",
    kind: 'page',
    status: canAccessCooperativeResources() ? 'active' : 'disabled',
    authorization: 'regular',
    content: {
      page: "MemberRegistrationsPage",
      import_path: "containers/memberRegistrations/MemberRegistrationsPage"
    },
    dividerAbove: true
  }, {
    ordination: '08.03',
    name: 'Integralização de Capital',
    key: cooperativePath + "/capital_payments",
    routePath: cooperativePath + "/capital_payments",
    kind: 'page',
    status: canAccessCooperativeResources() ? 'active' : 'disabled',
    authorization: 'regular',
    contentProp: {
      operationKind: CAPITAL_PAYMENT,
      minimumValueForCapitalPayment: getStatutoryCapitalBase(),
      title: 'Integralização de Capital',
      personAuxiliaryQuery: MembershipByPersonQuery
    },
    dividerAbove: true
  }, {
    ordination: '08.04',
    name: 'Distribuição de resultado',
    key: cooperativePath + "/leftover_distribution",
    routePath: cooperativePath + "/leftover_distribution",
    kind: 'page',
    status: canAccessCooperativeResources() ? 'active' : 'disabled',
    authorization: 'regular',
    content: {
      page: "DistributionsPage",
      import_path: "containers/distributions/DistributionsPage"
    }
  }, {
    ordination: '08.05',
    name: 'Eventos',
    key: cooperativeAssemblyPath,
    routePath: cooperativeAssemblyPath,
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    dividerAbove: true,
    children: cooperativeAssemblyRoutes
  }, {
    ordination: '08.06',
    name: 'Conselhos',
    key: cooperativeCouncilPath,
    routePath: cooperativeCouncilPath,
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    children: cooperativeCouncilRoutes
  }, {
    ordination: '08.07',
    name: 'Configurações',
    key: cooperativeConfigPath,
    routePath: cooperativeConfigPath,
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    children: cooperativeConfigRoutes
  }, {
    ordination: '08.08',
    name: 'Declarações',
    key: cooperativeStatementPath,
    routePath: cooperativeStatementPath,
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    children: cooperativeStatementsRoutes
  }];
  return {
    ordination: '08',
    name: 'Cooperativa',
    key: cooperativePath,
    kind: 'module',
    status: 'active',
    routePath: cooperativePath,
    routePathParams: undefined,
    dividerAbove: false,
    icon: 'cooperative',
    content: undefined,
    contentProp: undefined,
    authorization: 'regular',
    userAuthorization: undefined,
    children: routes
  };
};
export const buildAssociatedFeatures = () => {
  const cooperativePath = "/" + COOPERATIVE;
  const associatedCooperativeRoutes = [{
    ordination: '08.55.01',
    name: 'Eventos em Andamento',
    key: cooperativePath + "/assembly_opened",
    routePath: cooperativePath + "/assembly_opened",
    kind: 'page',
    status: canAccessCooperativeResources() ? 'active' : 'disabled',
    authorization: 'regular',
    content: {
      page: "AssembliesPage",
      import_path: "containers/assembly/AssembliesPage"
    },
    contentProp: {
      event: ASSEMBLY_OPENED
    }
  }, {
    ordination: '08.55.02',
    name: 'Eventos Encerrados',
    key: cooperativePath + "/assembly_closed",
    routePath: cooperativePath + "/assembly_closed",
    kind: 'page',
    status: canAccessCooperativeResources() ? 'active' : 'disabled',
    authorization: 'regular',
    content: {
      page: "AssembliesPage",
      import_path: "containers/assembly/AssembliesPage"
    },
    contentProp: {
      event: ASSEMBLY_CLOSED
    }
  }, {
    ordination: '08.55.03',
    name: 'Minhas matrículas',
    key: cooperativePath + "/member_registrations",
    routePath: cooperativePath + "/member_registrations",
    kind: 'page',
    status: canAccessCooperativeResources() ? 'active' : 'disabled',
    authorization: 'regular',
    content: {
      page: "MemberRegistrationsPage",
      import_path: "containers/memberRegistrations/MemberRegistrationsPage"
    },
    contentProp: {
      person: getPerson()
    },
    dividerAbove: true
  }];
  return {
    ordination: '08',
    name: 'Cooperativa',
    key: cooperativePath,
    kind: 'module',
    status: 'active',
    routePath: cooperativePath,
    routePathParams: undefined,
    dividerAbove: false,
    icon: 'cooperative',
    content: undefined,
    contentProp: undefined,
    authorization: 'regular',
    userAuthorization: undefined,
    children: associatedCooperativeRoutes
  };
};