import gql from "graphql-tag";
import { movementFragment } from "../fragments/Movement";
export const GQL_LOCATION_DISTRIBUTION_STORE = gql`
  mutation StoreLocationDistribution($originAttributes: StockDistributionOriginAttributes!, $destinantionAttributes: [StockDistributionDestinationAttributes!]) {
    storeLocationDistribution(input: {originAttributes: $originAttributes, destinantionAttributes: $destinantionAttributes}) { 
      movements {
        ...StockMovement
      }
      errors 
    } 
  }
  ${movementFragment}   
`;