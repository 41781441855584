export const PRESIDENT = 'president';
export const VICE_PRESIDENT = 'vice_president';
export const TREASURER = 'treasurer';
export const SECRETARY = 'secretary';
export const ADMINISTRATIVE_MEMBER_ROLE = [{
  value: PRESIDENT,
  label: 'Presidente'
}, {
  value: VICE_PRESIDENT,
  label: 'Vice Presidente'
}, {
  value: TREASURER,
  label: 'Tesoureiro'
}, {
  value: SECRETARY,
  label: 'Secretário'
}];