import { personDefaultBuilder, personAtributesBuilder } from 'admin-front';
export const driverParamBuilder = (record, values) => {
  const personAttributes = personAtributesBuilder({
    id: values.personId
  }, values);
  return {
    attributes: {
      id: record?.id,
      personId: record?.personId,
      person: personAttributes,
      driverVehicles: values?.driverVehicles || []
    }
  };
};
export const driverDefaultBuilder = record => {
  const personAttributes = personDefaultBuilder(record?.person);
  return {
    id: record?.id,
    personId: record?.personId,
    ...personAttributes,
    driverVehicles: record?.driverVehicles || []
  };
};