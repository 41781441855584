import gql from 'graphql-tag';
import { personSimpleFragment } from 'admin-front';
export const companyAccountantFragment = gql`
  fragment CompanyAccountant on CompanyAccountant {
    id,
    profile,
    active,
    start,
    finish,
    personId,
    crcCode,
    crcSequential,
    expirationDate,
    officeId,
    stateId,
    person {
      ...PersonSimple
    },
  }
  ${personSimpleFragment}
`;