export const format = (value = 0, precision = 2, locale = 'pt-BR', currency = 'BRL') => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency
  }).format(value.toFixed(precision));
};
export const percentageFormat = value => {
  let data = value || 0;
  return new Intl.NumberFormat('pt-BR', {
    currency: 'BRL',
    minimumFractionDigits: 2
  }).format(data) + '%';
};
export const thousandValuesMask = value => {
  return value.toLocaleString('pt-br', {
    currency: 'BRL',
    minimumFractionDigits: 2
  });
};