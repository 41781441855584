import gql from "graphql-tag";
import { cooperativeCompanyFragment } from "./CooperativeCompany";
export const organizationSimpleFragment = gql`
  fragment OrganizationSimple on Organization  { 
    id,
    level,
    statutoryCapitalBase,
    statutoryPersonAcceptance,
    name,
    document,
    discardedAt,
    parentId,
    companyId,
    company { 
      ...CooperativeCompany
    }
  }
  ${cooperativeCompanyFragment}
`;