import gql from "graphql-tag";
import { InvoiceFragment, TaxInvoiceFragment } from "../fragments/Invoice";
import { parentInvoiceFragment, parentTaxInvoiceFragment } from "../fragments/InvoiceParent";
import { reportFragment } from 'admin-front';
import { invoiceListingFragment } from "../fragments/InvoiceListing";
export const GQL_INVOICE_LIST = gql`
  {
    invoices { 
      ...ParentInvoice,
    }
  }
  ${parentInvoiceFragment}
`;
export const GQL_INVOICE_STORE = gql`
  mutation StoreInvoice($attributes: InvoiceAttributes!) {
    storeInvoice(input: {attributes: $attributes}) { 
      invoice {
        ...Invoice,
      }, 
      errors 
    } 
  }
  ${InvoiceFragment}
`;
export const GQL_TAX_INVOICE_STORE = gql`
  mutation StoreInvoice($attributes: InvoiceAttributes!) {
    storeInvoice(input: {attributes: $attributes}) { 
      invoice {
        ...TaxInvoice,
      }, 
      errors 
    } 
  }
  ${TaxInvoiceFragment}
`;
export const GQL_QUICK_BILL_INVOICE = gql`
  mutation QuickBillInvoice($id: ID!) {
    quickBillInvoice(input: {id: $id}) { 
      invoice {
        ...Invoice,
      }, 
      errors 
    } 
  }
  ${InvoiceFragment}
`;
export const GQL_INVOICE_BY_ACCESS_KEY = gql`
  query GetByAccessKey($accessKey: String) {
    invoices(accessKey: $accessKey) {
      ...TaxInvoice
    }
  }
  ${TaxInvoiceFragment}
`;
export const GQL_CREATE_DANFE = gql`
  mutation CreateDanfe($invoiceId: ID!) {
    createDanfe(input: {invoiceId: $invoiceId}) { 
      invoice {
        ...TaxInvoice,
      }, 
      errors 
    } 
  }
  ${TaxInvoiceFragment}
`;
export const GQL_CONSULTATION_DANFE = gql`
  mutation StoreInvoiceConsultation($id: ID!, $kind: String) {
    storeInvoiceConsultation(input:{ id: $id, kind: $kind}) {
      invoice {
        ...TaxInvoice,
      }, 
      errors
    }
  }
  ${TaxInvoiceFragment}
`;
export const GQL_MULTIPLE_INVOICES_STORE = gql`
  mutation StoreInvoice($attributes: AcquisitionMultipleInvoicesAttributes!) {
    storeMultipleInvoices(input: {attributes: $attributes}) { 
      invoices {
        ...Invoice,
      }, 
      errors 
    } 
  }
  ${InvoiceFragment}
`;
export const GQL_INVOICE_SEARCH = gql`
  query Search($pageSize: Int, $cursor: String, $filter: Filter) {    
    invoicesPaginated(first: $pageSize, after: $cursor, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...InvoiceListing
        }
      }
    }    
  }
  ${invoiceListingFragment}
`;
export const GQL_INVOICE_BY_ID = gql`
  query GetById($id: ID) {    
    invoices(id: $id) {
      ...ParentInvoice
    }    
  }
  ${parentInvoiceFragment}
`;
export const GQL_INVOICE_BY_IDS = gql`
  query GetByIds($ids: [ID!]) {    
    invoices(ids: $ids) {
      ...ParentInvoice
    }    
  }
  ${parentInvoiceFragment}
`;
export const GQL_INVOICE_BILLED_BY_ID = gql`
  query GetBilledId($id: ID) {    
    invoices(billed: $id) {
      ...ParentTaxInvoice
    }    
  }
  ${parentTaxInvoiceFragment}
`;
export const GQL_GENERATE_DANFE = gql`
  query GenerateDanfe($id: ID!) {    
    generateDanfe(id: $id) {
      ...Report
    }
  }
  ${reportFragment}
`;
export const GQL_GENERATE_PREVIEW_DANFE = gql`
  query GeneratePreviewDanfe($id: ID!) {    
    generatePreviewDanfe(id: $id) {
      ...Report
    }
  }
  ${reportFragment}
`;
export const GQL_INVOICE_UNGROUP = gql`
  mutation UngroupInvoice($id: ID!) {
    ungroupInvoice(input: {id: $id}) { 
      invoices {
        ...Invoice,
      }, 
      errors 
    } 
  }
  ${InvoiceFragment}
`;
export const GQL_INVOICE_CHILD_UNGROUP = gql`
  mutation UngroupInvoiceChild($id: ID!) {
    ungroupInvoiceChild(input: {id: $id}) { 
      invoice {
        ...Invoice,
      }, 
      errors 
    } 
  }
  ${InvoiceFragment}
`;
export const GQL_OPENED_INVOICE_CHILD_UNGROUP = gql`
  mutation UngroupOpenedInvoiceChild($id: ID!) {
    ungroupOpenedInvoiceChild(input: {id: $id}) { 
      invoices {
        ...Invoice,
      }, 
      errors 
    } 
  }
  ${InvoiceFragment}
`;
export const GQL_GENERATE_PACKING_LIST = gql`
  query GeneratePackingList($id: ID!) {    
    generatePackingList(id: $id) {
      ...Report
    }
  }
  ${reportFragment}
`;
export const GQL_GENERATE_COUPON_PACKING_LIST = gql`
  query GenerateCouponPackingList($id: ID!) {    
    generateCouponPackingList(id: $id) {
      ...Report
    }
  }
  ${reportFragment}
`;
export const GQL_GENERATE_INVOICE_RECEIPT = gql`
  query GenerateInvoiceReceipt($id: ID!) {    
    generateInvoiceReceipt(id: $id) {
      ...Report
    }
  }
  ${reportFragment}
`;