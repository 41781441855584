export const batchDistributionParamBuilder = (record, values) => {
  return {
    originAttributes: {
      productId: values.selectedProduct[0],
      productUnitId: values.selectedProduct[1],
      locationId: values.selectedProduct[2],
      batchId: values.selectedProduct[3]
    },
    destinantionAttributes: (values.locations || []).filter(location => location.quantity > 0).map(location => ({
      locationId: location.locationId,
      quantity: location.quantity
    }))
  };
};