import gql from "graphql-tag";
import { groupSimpleFragment, productBrandFragment, productSupplierFragment } from 'stock-front';
import { productInfoFragment } from "./ProductInfo";
import { fiscalProductUnitFragment } from "./ProductUnit";
export const fiscalProductSimpleFragment = gql`
  fragment FiscalProductSimple on FiscalProduct  {
    id,
    name,
    description,
    searchCode,
    discardedAt,
    kind,
    nveCode,
    anpCode,
    codifCode,
    recopiCode,
    goodsOrigin,
    glpPercentage,
    glgnPercentage,
    importedGlgnPercentage,
    destinationType,
    productionScale,
    ipiExceptionCode,
    anpProductDescription,
    productInfos {
      ...ProductInfo
    }
  }
  ${productInfoFragment}    
`;
export const fiscalProductFragment = gql`
  fragment FiscalProduct on FiscalProduct  {
    ...FiscalProductSimple,
    groups {
      ...GroupSimple
    }
    productBrands {
      ...ProductBrand
    }
    productSuppliers {
      ...ProductSupplier
    }
    productUnits {
      ...FiscalProductUnit
    }  
  }
  ${fiscalProductSimpleFragment}
  ${groupSimpleFragment}
  ${productBrandFragment}
  ${productSupplierFragment}
  ${fiscalProductUnitFragment}    
  ${productInfoFragment}    
`;