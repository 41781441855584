export const reconciliationParamBuilder = (record, accountInfos) => {
  const attributes = {
    bankAccountId: record?.id,
    ...accountInfos
  };
  return {
    attributes: attributes
  };
};
export const reconciliationDefaultBuilder = record => {
  return {
    ...record
  };
};