export const ENTRY = 'entry';
export const EXIT = 'exit';
export const SALES = 'sales';
export const PURCHASE = 'purchase';
export const TRANSFER = 'transfer';
export const DEVOLUTION = 'devolution';
export const INSTITUCIONAL_MARKET = 'institutional_market';
export const FINAL_CUSTOMER = 'final_customer';
export const FINAL_COSTUMER = 'final_costumer';
export const BASIC_BASKET = 'basic_basket';
export const ELECTRICITY = 'electricity';
export const SERVICE = 'service';
export const TAX_SUBSTITUTION = 'tax_substitution';
export const INTEGRATION_SYSTEMS = 'integration_systems';
export const EXPORT = 'export';
export const FIXED_ASSETS_OR_CONSUPTION = 'fixed_assets_or_consumption';
export const CREDIT_OF_ICMS = 'credit_of_icms';
export const FUELS = 'fuels';
export const OTHERS = 'others';
export const COMMUNICATION = 'communication';
export const TRANSPORT = 'transport';
export const OTHERS_SERVICES = 'others_services';
export const DOES_NOT_GENERATE_TAX_DOCUMENT = 'does_not_generate_tax_document';
export const OWN_TAX_DOCUMENT = 'own_tax_document';
export const OWN_TAX_DOCUMENT_ON_BEHALF_OF_THRID_PARTIES = 'own_tax_document_on_behalf_of_third_parties';
export const THIRD_PARTY_TAX_DOCUMENT = 'third_party_tax_document';
export const POINT_OF_SALES = 'point_of_sales';
export const OTHER = 'other';
export const OPERATIONS_KIND = [{
  value: ENTRY,
  label: 'Entrada'
}, {
  value: EXIT,
  label: 'Saída'
}];
export const OPERATIONS_SUBKIND_BY_KEY = [{
  value: '0',
  label: "Vendas"
}, {
  value: '4',
  label: "Compras"
}, {
  value: '5',
  label: "Transferência"
}, {
  value: '6',
  label: "Devolução"
}, {
  value: '1',
  label: "Mercado Institucional"
}, {
  value: '2',
  label: "Consumidor final"
}, {
  value: '3',
  label: "Cesta básica"
}, {
  value: '7',
  label: "Energia elétrica"
}, {
  value: '8',
  label: "Serviço"
}, {
  value: '9',
  label: "Substituição tributária"
}, {
  value: '10',
  label: "Sistemas de integração"
}, {
  value: '11',
  label: "Exportação"
}, {
  value: '12',
  label: "Imobilizado/consumo"
}, {
  value: '13',
  label: "Crédito de icms"
}, {
  value: '14',
  label: "Combustíveis"
}, {
  value: '15',
  label: "Outros"
}];
export const OPERATIONS_SUBKIND = [{
  value: SALES,
  label: "Vendas"
}, {
  value: PURCHASE,
  label: "Compras"
}, {
  value: TRANSFER,
  label: "Transferência"
}, {
  value: DEVOLUTION,
  label: "Devolução"
}, {
  value: INSTITUCIONAL_MARKET,
  label: "Mercado Institucional"
}, {
  value: FINAL_COSTUMER,
  label: "Consumidor final"
}, {
  value: BASIC_BASKET,
  label: "Cesta básica"
}, {
  value: ELECTRICITY,
  label: "Energia elétrica"
}, {
  value: SERVICE,
  label: "Serviço"
}, {
  value: TAX_SUBSTITUTION,
  label: "Substituição tributária"
}, {
  value: INTEGRATION_SYSTEMS,
  label: "Sistemas de integração"
}, {
  value: EXPORT,
  label: "Exportação"
}, {
  value: FIXED_ASSETS_OR_CONSUPTION,
  label: "Imobilizado/consumo"
}, {
  value: CREDIT_OF_ICMS,
  label: "Crédito de icms"
}, {
  value: FUELS,
  label: "Combustíveis"
}, {
  value: OTHERS,
  label: "Outros"
}];
export const OPERATIONS_SALES_SUBKIND = [{
  value: SALES,
  label: "Vendas"
}];
export const OPERATIONS_PURCHASE_SUBKIND = [{
  value: PURCHASE,
  label: "Compras"
}];
export const OPERATIONS_GENERIC_SUBKIND = [{
  value: TRANSFER,
  label: "Transferência"
}, {
  value: DEVOLUTION,
  label: "Devolução"
}, {
  value: INSTITUCIONAL_MARKET,
  label: "Mercado Institucional"
}, {
  value: FINAL_COSTUMER,
  label: "Consumidor final"
}, {
  value: BASIC_BASKET,
  label: "Cesta básica"
}, {
  value: ELECTRICITY,
  label: "Energia elétrica"
}, {
  value: SERVICE,
  label: "Serviço"
}, {
  value: TAX_SUBSTITUTION,
  label: "Substituição tributária"
}, {
  value: INTEGRATION_SYSTEMS,
  label: "Sistemas de integração"
}, {
  value: EXPORT,
  label: "Exportação"
}, {
  value: FIXED_ASSETS_OR_CONSUPTION,
  label: "Imobilizado/consumo"
}, {
  value: CREDIT_OF_ICMS,
  label: "Crédito de icms"
}, {
  value: FUELS,
  label: "Combustíveis"
}, {
  value: OTHERS,
  label: "Outros"
}];
export const OPERATIONS_KIND_OF_SERVICE = [{
  value: COMMUNICATION,
  label: 'Comunicação'
}, {
  value: TRANSPORT,
  label: 'Transporte'
}, {
  value: OTHERS_SERVICES,
  label: 'Outros'
}];
export const TAX_DOCUMENT_ISSUER_OPTIONS = [{
  value: DOES_NOT_GENERATE_TAX_DOCUMENT,
  label: 'Não gera documento fiscal'
}, {
  value: OWN_TAX_DOCUMENT,
  label: 'Documento fiscal próprio'
}, {
  value: OWN_TAX_DOCUMENT_ON_BEHALF_OF_THRID_PARTIES,
  label: 'Documento fiscal próprio em nome de terceiros'
}, {
  value: THIRD_PARTY_TAX_DOCUMENT,
  label: 'Documento fiscal de terceiros'
}];
export const TAX_DOCUMENT_ISSUER_SIMPLE_OPTIONS = [{
  value: OWN_TAX_DOCUMENT,
  label: 'Documento fiscal próprio'
}, {
  value: OWN_TAX_DOCUMENT_ON_BEHALF_OF_THRID_PARTIES,
  label: 'Documento fiscal próprio em nome de terceiros'
}, {
  value: THIRD_PARTY_TAX_DOCUMENT,
  label: 'Documento fiscal de terceiros'
}];