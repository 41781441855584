import moment from 'moment';
export const simpleDateFormat = (date, format = "DD/MM/YYYY") => {
  return moment(date).format(format);
};
export const dateFormat = date => {
  if (!date) return;
  return new Intl.DateTimeFormat("pt-BR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit"
  }).format(new Date(date));
};
export const rangeDays = (start, end) => {
  const startDate = moment(start, "YYYY-MM-DD");
  const endDate = moment(end, "YYYY-MM-DD");
  return moment.duration(endDate.diff(startDate)).asDays();
};
export const elapsedByPercent = (dates, date) => {
  const totalRangeDays = rangeDays(dates[0], dates[dates.length - 1]);
  return 100 / totalRangeDays * rangeDays(dates[0], date);
};
export const concatDateTime = (date, time) => {
  return moment(date.format('DD/MM/YYYY') + ' ' + time.format('HH:mm'), 'DD/MM/YYYY HH:mm:ss-0300');
};
export const buildDate = (day, month, year) => {
  return moment(`${day}/${month}/${year}`, 'DD/MM/YYYY');
};
export const sanitizeBackend = date => {
  return date ? moment(date) : undefined;
};
export const today = () => {
  return moment();
};
export const now = () => {
  return moment(moment(), 'HH:mm');
};
export const dateOrToday = date => {
  return sanitizeBackend(date) || today();
};
export const timeOrNow = time => {
  return time ? moment(time || moment(Date()), 'HH:mm') : now();
};
export const nextDayFromDay = day => {
  let date = today();
  if (!day) day = date.day();
  let year = date.year();
  let month = (date.month() + 1).toLocaleString('pt-BR', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });
  if (day < date.date()) {
    day = date.date();
  }
  day = day.toLocaleString('pt-BR', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });
  let newDate = buildDate(day, month, year);
  return newDate;
};
export const nextWeekFromDay = day => {
  let date = today();
  if (!day) day = date.day();
  let year = date.year();
  let month = (date.month() + 1).toLocaleString('pt-BR', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });
  let newDate = buildDate(day, month, year);
  day = day.toLocaleString('pt-BR', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });
  const nextWeek = moment(newDate).add(1, 'weeks');
  if (nextWeek.date() < day || nextWeek.date() < date.date()) {
    return nextWeekFromDay(nextWeek.date());
  }
  return nextWeek;
};
export const currentMonth = () => {
  return moment(new Date()).month() + 1 + "";
};
export const nextMonthFromDay = day => {
  let date = today();
  if (!day) date = date.day();
  let year = date.year();
  let month = (date.month() + 1).toLocaleString('pt-BR', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });
  let newDate = buildDate(day, month, year);
  day = day.toLocaleString('pt-BR', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });
  const nextMonth = moment(newDate).add(1, 'month');
  return nextMonth;
};
export const currentYear = () => {
  return moment(new Date()).year() + "";
};
export const lastDayOfLastYear = () => {
  return moment(new Date(new Date().getFullYear() - 1, 11, 31));
};
export const nextYearFromDay = day => {
  let date = today();
  if (!day) date = date.day();
  let year = date.year();
  let month = (date.month() + 1).toLocaleString('pt-BR', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });
  day = day.toLocaleString('pt-BR', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });
  let newDate = buildDate(day, month, year);
  const nextYear = moment(newDate).add(1, 'year');
  return nextYear;
};
export const startOfMonth = date => {
  return date ? moment(date).startOf('month') : moment().startOf('month');
};
export const endOfMonth = date => {
  return date ? moment(date).endOf('month').startOf('day') : moment().endOf('month').startOf('day');
};
export const endOfDay = (date = undefined) => {
  return moment(date).endOf('day');
};
export const firstDateComparison = (dateA, dateB) => {
  if (!dateA) return dateB;
  if (!dateB) return dateA;
  return dateA < dateB ? dateA : dateB;
};
export const lastDateComparison = (dateA, dateB) => {
  if (!dateA) return dateB;
  if (!dateB) return dateA;
  return dateA > dateB ? dateA : dateB;
};
export const countdownDeadline = date => {
  return moment(new Date()) + (moment(date) - moment(new Date()));
};