import gql from "graphql-tag";
import { productModelFragment } from "./ProductModel";
export const productBrandSimpleFragment = gql`
  fragment ProductBrandSimple on ProductBrand  {
    id,
    productId,
    brandId,
    code,
  }
`;
export const productBrandFragment = gql`
  fragment ProductBrand on ProductBrand  {
    ...ProductBrandSimple
    productModels {
      ...ProductModel
    },
  }
  ${productBrandSimpleFragment}
  ${productModelFragment}
`;