import gql from "graphql-tag";
import { accountFlowMovementResultCenterFragment } from "./AccountFlowMovementResultCenter";
export const accountFlowMovementFragment = gql`
  fragment AccountFlowMovement on AccountFlowMovement  {
    id,
    time,
    transactionId,
    person,
    operation,
    entries,
    exits,
    kind,
    status,
    reversalOf,
    reversedIn,
    resultCenters {
      ...AccountFlowMovementResultCenter
    }
  }
  ${accountFlowMovementResultCenterFragment}
`;