export const IPI_IMMUNITY = 'immunity';
export const IPI_SUSPENSION = 'suspension';
export const IPI_EXEMPTION = 'exemption';
export const IPI_REDUCTION = 'reduction';
export const IPI_OTHERS = 'others';
export const IPI_GROUP_OPTIONS = [{
  value: IPI_IMMUNITY,
  label: 'Imunidade'
}, {
  value: IPI_SUSPENSION,
  label: 'Suspensão'
}, {
  value: IPI_EXEMPTION,
  label: 'Isenção'
}, {
  value: IPI_REDUCTION,
  label: 'Redução'
}, {
  value: IPI_OTHERS,
  label: 'Outros'
}];