import gql from "graphql-tag";
import { fleetPersonSimpleFragment } from "./FleetPerson";
export const ownerTrailerFragment = gql`
  fragment OwnerTrailer on OwnerTrailer  { 
    id,
    start,
    finish,
    personId,
    person {
      ...FleetPersonSimple
    }
  }
  ${fleetPersonSimpleFragment}
`;