import gql from "graphql-tag";
import { groupSimpleFragment, productBrandFragment, productSupplierFragment } from 'stock-front';
import { productInfoFragment, fiscalProductUnitToListFragment } from "fiscal-front";
export const productGenreFragment = gql`
  fragment ProductGenre on ProductGenre {
    id,
    code,
    description,
  }
`;
export const accountingProductFragment = gql`
  fragment AccountingProduct on AccountingProduct  {
    id,
    name,
    description,
    searchCode,
    discardedAt,
    kind,
    nveCode,
    anpCode,
    codifCode,
    recopiCode,
    goodsOrigin,
    glpPercentage,
    glgnPercentage,
    importedGlgnPercentage,
    destinationType,
    productionScale,
    ipiExceptionCode,
    anpProductDescription,
    productGenreId,
    groups {
      ...GroupSimple
    }
    productBrands {
      ...ProductBrand
    }
    productSuppliers {
      ...ProductSupplier
    }
    productUnits {
      ...FiscalProductUnitToList
    }  
    productInfos {
      ...ProductInfo
    }
  }
  ${groupSimpleFragment}
  ${productBrandFragment}
  ${productSupplierFragment}
  ${fiscalProductUnitToListFragment}    
  ${productInfoFragment}    
`;