import gql from "graphql-tag";
import { fiscalOperationListFragment } from "./OperationList";
import { paymentFragment } from "./payment";
import { FiscalContractItemFragment } from "./ContractItem";
import { fiscalPersonToCardFragment } from "./Person";
import { contractDocumentFragment } from "./ContractDocument";
import { addressFragment } from 'admin-front';
import { contractResultCenterFragment } from "./ContractResultCenter";
import { contractCategoryFragment } from "./ContractCategory";
import { InvoiceSimpleFragment } from "./InvoiceSimple";
export const ContractSimpleFragment = gql`
  fragment FiscalContractSimple on FiscalContract  {
    id,
    number,
    description,
    observation,
    status,
    start,
    finish,
    value,
    amount,
    balance,
    quantityBalance,
    administrateTax,
    completionPercentage,
    quantityCompletionPercentage,
    discardedAt,
    personId,
    addressId,
    operationId,
    operation {
      ...FiscalOperationList
    },
   }
   ${fiscalOperationListFragment}
`;
export const ContractFragment = gql`
  fragment FiscalContract on FiscalContract  {
    ...FiscalContractSimple
    operation {
      ...FiscalOperationList
    },
    products {
      ...FiscalContractItem
    }
    categories{
      ...ContractCategory
    },
    address {
      ...Address
    },    
    person {
      ...FiscalPersonToCard
    },
    contractDocuments {
      ...ContractDocument
    },
    payments {
      ...Payment
    },
    resultCenters {
      ...ContractResultCenter
    },
    infos {
      id,
      target,
      origin,
      info,
      additionalInformationId, 
    },
  }
  ${ContractSimpleFragment}
  ${FiscalContractItemFragment}
  ${contractCategoryFragment}
  ${fiscalPersonToCardFragment}
  ${contractDocumentFragment}
  ${addressFragment}
  ${paymentFragment}
  ${contractResultCenterFragment}
  ${fiscalOperationListFragment}
`;
export const ContractListingFragment = gql`
  fragment ContractListing on FiscalContract  {
    ...FiscalContractSimple      
    address {
      ...Address
    },
    person{
      ...FiscalPersonToCard
    },
    invoices {
      ...InvoiceSimple
      operation {
        id,
        taxDocumentIssuer
      }
    },
   }
   ${ContractSimpleFragment}
   ${InvoiceSimpleFragment}
   ${fiscalPersonToCardFragment}
   ${addressFragment}    
`;