import { round } from 'grifo-components';
import { IN_CASH, MONTH } from "../constants/paymentConditions/PaymentConditionsFieldsOptions";
import { installmentsBuilder } from "../builders/InstallmentsBuilder";
export const onValueChange = (value, form, referenceDate, onCalculation) => {
  if ((form.getFieldValue('payments') || []).length === 1) calculatePaymentValue(value, 0, form, referenceDate, onCalculation);
};
export const setPaymentMethodValues = (paymentMethod, index, form, referenceDate, onCalculation) => {
  let payment = form.getFieldValue(['payments', index]);
  payment.methodFeesRate = paymentMethod.fees || 0;
  payment.methodDiscountRate = paymentMethod.discount || 0;
  payment.methodKind = paymentMethod.kind;
  form.setFieldValue(['payments', index], payment);
  calculatePaymentValue(payment.valueToPay, index, form, referenceDate, onCalculation);
};
export const setPaymentConditionValues = (paymentCondition, index, form, referenceDate, onCalculation) => {
  let payment = form.getFieldValue(['payments', index]);
  payment.conditionFeesRate = paymentCondition.fees;
  payment.conditionDiscountRate = paymentCondition.discount;
  payment.time = paymentCondition.time || 1;
  payment.timeKind = paymentCondition.timeKind || MONTH;
  payment.installment = paymentCondition.installments || 1;
  payment.gracePeriod = paymentCondition.gracePeriod || 0;
  payment.condition = paymentCondition.condition || IN_CASH;
  form.setFieldValue(['payments', index], payment);
  calculatePaymentValue(payment.valueToPay, index, form, referenceDate, onCalculation);
};
export const calculatePaymentValue = (value, index, form, referenceDate, onCalculation) => {
  let payment = form.getFieldValue(['payments', index]);
  if (!payment) return;
  payment.valueToPay = value || 0;
  payment.amount = payment.valueToPay;
  payment.methodFees = round(payment.valueToPay * (payment.methodFeesRate || 0) / 100);
  payment.conditionFees = round(payment.valueToPay * (payment.conditionFeesRate || 0) / 100);
  payment.fees = round(payment.methodFees + payment.conditionFees, 2) || 0;
  payment.methodDiscount = round(payment.valueToPay * (payment.methodDiscountRate || 0) / 100);
  payment.conditionDiscount = round(payment.valueToPay * (payment.conditionDiscountRate || 0) / 100);
  payment.discount = round(payment.methodDiscount + payment.conditionDiscount, 2) || 0;
  payment.total = round(payment.valueToPay + payment.fees - payment.discount);
  payment.installments = generateInstallments({
    installments: payment.installment,
    time: payment.time,
    timeKind: payment.timeKind,
    gracePeriod: payment.gracePeriod,
    condition: payment.condition
  }, index, form, referenceDate);
  form.setFieldValue(['payments', index], payment);
  if (onCalculation) onCalculation(form);
};
export const generateInstallments = (paymentCondition, index, form, referenceDate) => {
  const payments = form.getFieldValue('payments');
  const payment = payments[index];
  return installmentsBuilder(paymentCondition.installments, payment.total, referenceDate, paymentCondition.time, paymentCondition.timeKind, paymentCondition.gracePeriod, payment.valueToPay, paymentCondition.condition);
};
export const generateInstallmentsByCustomCondition = (form, payments, index, installment, referenceDate) => {
  form.setFieldValue('installment', installment);
  const payment = payments[index];
  const installments = installmentsBuilder(installment, payment.total, referenceDate, form.getFieldValue('time'), form.getFieldValue('timeKind'), form.getFieldValue('gracePeriod'), form.getFieldValue('total'), payment.condition);
  form.setFieldValue('installments', installments);
};