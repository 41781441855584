import gql from "graphql-tag";
import { fleetPersonSimpleFragment } from "./FleetPerson";
export const ownerVehicleFragment = gql`
  fragment OwnerVehicle on OwnerVehicle  { 
    id,
    start,
    finish,
    personId,
    person {
      ...FleetPersonSimple
    }
  }
  ${fleetPersonSimpleFragment}
`;