import gql from "graphql-tag";
export const stockOperationSimpleFragment = gql`
  fragment StockOperationSimple on StockOperation {
    id,
    name,
    kind,
    generatesMovement,
    subkind,
    internal,
    parentId,
    discardedAt
  }
`;
export const stockOperationFragment = gql`
  fragment StockOperation on StockOperation  { 
      ...StockOperationSimple
      parent {
        ...StockOperationSimple
      },
  }
  ${stockOperationSimpleFragment}
`;
export const parentStockOperationFragment = gql`
  fragment StockOperationParent on StockOperation {
    ...StockOperation,
    parent {
      ...StockOperation,
    },
    children {
      ...StockOperation,
      children {
        ...StockOperation,
        children {
          ...StockOperation,
          children {
            ...StockOperation,
            children {
              ...StockOperation,
            }
          }
        }
      }
    }
  }
  ${stockOperationFragment} 
`;