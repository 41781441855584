import gql from "graphql-tag";
import { fiscalProductUnitFragment } from "./ProductUnit";
export const FiscalContractItemFragment = gql`
  fragment FiscalContractItem on FiscalContractProduct  {
    id,
    productId,
    productName,
    productUnitId,
    locationId,
    contractIndex,
    quantity,
    balance,
    unitaryValue,
    grossTotal,
    completionPercentage,
    administrateTax,
    productUnit {
      ...FiscalProductUnit
    }
  }
  ${fiscalProductUnitFragment}
`;