import gql from "graphql-tag";
import { productFragment } from "../fragments/Product";
import { productSearchFragment } from "../fragments/ProductSearch";
export const GQL_PRODUCTS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $search: String) {    
    productsPaginated(first: $pageSize, after: $cursor, search: $search) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Product
        }
      }
    }    
  }
  ${productFragment}
`;
export const GQL_LOAD_PRODUCT = gql`
  query LoadProduct($id: ID!) {    
    loadProduct(productId: $id) { 
      ...Product
    }
  }
  ${productFragment} 
`;
export const GQL_LOAD_PRODUCT_UNIT_SET = gql`
  query GetUnitBySet($productUnitId: ID!) {    
    loadProductUnitSet(productUnitId: $productUnitId) { 
      ...ProductSearch
    }
  }
  ${productSearchFragment} 
`;
export const GQL_LOAD_PRODUCT_SET = gql`
  query GetBySet($id: FilterStockProductUnitByLocationSearch) {    
    loadProductSet(filterLocation: $id) { 
      ...ProductSearch
    }
  }
  ${productSearchFragment} 
`;
export const GQL_PRODUCTS_SEARCH = gql`
  query SearchProducts($filter: FilterStockProductSearch) {
    searchProducts(filter: $filter) {
      ...ProductSearch
    }
  }
  ${productSearchFragment}
`;
export const GQL_PRODUCT_STORE = gql`
  mutation StoreProduct($attributes: ProductAttributes!) {
    storeProduct(input: {attributes: $attributes}) { 
      product {
        ...Product
      }, 
      errors 
    } 
  }
  ${productFragment}   
`;
export const GQL_PRODUCT_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: {id: $id, className: "Stock::Product"}) {
      id,
      discardedAt
    }
  }
`;