import gql from "graphql-tag";
import { taxableUnitFragment } from "./TaxableUnit";
import { NcmFragment } from "./Ncm";
import { productUnitTaxBenefitCodeFragment } from "./ProductUnitTaxBenefitCode";
import { commercialUnitFragment, batchFragment, packingKindFragment } from 'stock-front';
export const fiscalProductUnitSimpleFragment = gql`
  fragment FiscalProductUnitSimple on FiscalProductUnit  {
    id,
    productId,
    packingKindId,
    commercialUnitId,  
    taxableUnitId,
    parentId,
    ncmId,
    barcode,
    multiplier,
    grossWeight,
    netWeight,
    height,
    width,
    length,
    minimum,
    maximum,
    code,
    discardedAt,
    productionTracking,
    batchTracking,
    value,
    commercialUnit {
      ...CommercialUnit
    }    
  }
  ${commercialUnitFragment}
`;
export const fiscalProductUnitFragment = gql`
  fragment FiscalProductUnit on FiscalProductUnit {
    ...FiscalProductUnitSimple,
    batches {
      ...Batch
    }
    packingKind {
      ...PackingKind
    } 
    ncm {
      ...Ncm
    }
    taxableUnit {
      ...TaxableUnit
    } 
    taxBenefits {
      ...ProductUnitTaxBenefitCode
    } 
  }
  ${fiscalProductUnitSimpleFragment}
  ${taxableUnitFragment}
  ${NcmFragment}  
  ${batchFragment}
  ${packingKindFragment}
  ${productUnitTaxBenefitCodeFragment}
`;
export const fiscalProductUnitToListFragment = gql`
  fragment FiscalProductUnitToList on FiscalProductUnit  {
    ...FiscalProductUnit,
    parent {
      ...FiscalProductUnitSimple
    }
    balance    
  }
  ${fiscalProductUnitFragment}
  ${fiscalProductUnitSimpleFragment}
`;