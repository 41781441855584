import { WITHHOLDING, ICMS_TAXABLE, NATIONAL_EXCEPT_3_4_5_8, MVA, FIXED_PRICE_ST, ICMS_TAXABLE_WITH_BASIS_REDUCTION, ICMS_TAXABLE_BY_SUBSTITUTION, ICMS_EXEMPT_WITH_SUBSTITUTION, ICMS_OTHERS, ICMS_TAXABLE_WITH_BASIS_REDUCTION_AND_SUBSTITUTION, ICMS_PREVIOUS_TAXABLE_BY_SUBSTITUTION, ICMS_DEFERRAL, ICMS_SUSPENSION, ICMS_NOT_TAXABLE, ICMS_EXEMPT, MVA_ST } from "../constants/taxProfile/TaxFieldsOptions";
export const icmsStateDefaultBuilder = record => {
  return {
    states: (record || {}).states
  };
};
export const icmsStateItemParamBuilder = (values, taxSituation) => {
  let preparedAttributes = {
    id: values.id,
    stateId: values.stateId,
    kind: values.kind,
    taxBenefitCodeId: values.taxBenefitCodeId
  };
  if (taxSituation === ICMS_TAXABLE) {
    // 00
    preparedAttributes = Object.assign({}, {
      rate: values.rate,
      fcpRate: values.fcpRate,
      methodBasis: values.methodBasis
    }, preparedAttributes);
  } else if (taxSituation === ICMS_TAXABLE_BY_SUBSTITUTION) {
    // 10
    preparedAttributes = Object.assign({}, {
      rate: values.rate,
      fcpRate: values.fcpRate,
      methodBasis: values.methodBasis,
      rateSt: values.rateSt,
      fcpRateSt: values.fcpRateSt,
      methodBasisSt: values.methodBasisSt,
      mvaRate: values.methodBasisSt === MVA_ST ? values.mvaRate : undefined,
      basisReductionPercentageSt: values.basisReductionPercentageSt
    }, preparedAttributes);
  } else if (taxSituation === ICMS_TAXABLE_WITH_BASIS_REDUCTION) {
    // 20
    preparedAttributes = Object.assign({}, {
      rate: values.rate,
      fcpRate: values.fcpRate,
      methodBasis: values.methodBasis,
      basisReductionPercentage: values.basisReductionPercentage,
      unencumberedReason: values.unencumberedReason,
      unencumberedRate: values.unencumberedRate,
      unencumberedBasisReduction: values.unencumberedBasisReduction
    }, preparedAttributes);
  } else if (taxSituation === ICMS_EXEMPT_WITH_SUBSTITUTION) {
    // 30
    preparedAttributes = Object.assign({}, {
      rateSt: values.rateSt,
      fcpRateSt: values.fcpRateSt,
      methodBasisSt: values.methodBasisSt,
      mvaRate: values.methodBasisSt === MVA_ST ? values.mvaRate : undefined,
      basisReductionPercentageSt: values.basisReductionPercentageSt,
      unencumberedReason: values.unencumberedReason,
      unencumberedRate: values.unencumberedRate,
      unencumberedBasisReduction: values.unencumberedBasisReduction
    }, preparedAttributes);
  } else if ([ICMS_EXEMPT, ICMS_NOT_TAXABLE, ICMS_SUSPENSION].includes(taxSituation)) {
    // 40, 41, 50
    preparedAttributes = Object.assign({}, {
      unencumberedReason: values.unencumberedReason,
      unencumberedRate: values.unencumberedRate,
      unencumberedBasisReduction: values.unencumberedBasisReduction
    }, preparedAttributes);
  } else if (taxSituation === ICMS_DEFERRAL) {
    // 51
    preparedAttributes = Object.assign({}, {
      rate: values.rate,
      fcpRate: values.fcpRate,
      methodBasis: values.methodBasis,
      basisReductionPercentage: values.basisReductionPercentage,
      deferralRate: values.deferralRate
    }, preparedAttributes);
  } else if (taxSituation === ICMS_PREVIOUS_TAXABLE_BY_SUBSTITUTION) {
    // 60
    preparedAttributes = Object.assign({}, {
      rateSt: values.rateSt,
      fcpRateSt: values.fcpRateSt
    }, preparedAttributes);
  } else if (taxSituation === ICMS_TAXABLE_WITH_BASIS_REDUCTION_AND_SUBSTITUTION) {
    // 70
    preparedAttributes = Object.assign({}, {
      rate: values.rate,
      fcpRate: values.fcpRate,
      methodBasis: values.methodBasis,
      basisReductionPercentage: values.basisReductionPercentage,
      rateSt: values.rateSt,
      fcpRateSt: values.fcpRateSt,
      methodBasisSt: values.methodBasisSt,
      mvaRate: values.methodBasisSt === MVA_ST ? values.mvaRate : undefined,
      basisReductionPercentageSt: values.basisReductionPercentageSt,
      unencumberedReason: values.unencumberedReason,
      unencumberedRate: values.unencumberedRate,
      unencumberedBasisReduction: values.unencumberedBasisReduction
    }, preparedAttributes);
  } else if (taxSituation === ICMS_OTHERS) {
    // 90    
    preparedAttributes = Object.assign({}, {
      rate: values.rate,
      fcpRate: values.fcpRate,
      methodBasis: values.methodBasis,
      rateSt: values.rateSt,
      fcpRateSt: values.fcpRateSt,
      methodBasisSt: values.methodBasisSt,
      mvaRate: values.methodBasisSt === MVA_ST ? values.mvaRate : undefined,
      basisReductionPercentageSt: values.basisReductionPercentageSt,
      unencumberedReason: values.unencumberedReason,
      unencumberedRate: values.unencumberedRate,
      unencumberedBasisReduction: values.unencumberedBasisReduction
    }, preparedAttributes);
  }
  return preparedAttributes;
};
export const icmsStateItemDefaultBuilder = record => {
  const object = record || {};
  return {
    rate: object.rate || 0,
    fcpRate: object.fcpRate || 0,
    basisReductionPercentage: object.basisReductionPercentage || 0,
    methodBasis: object.methodBasis || MVA,
    rateSt: object.rateSt || 0,
    fcpRateSt: object.fcpRateSt || 0,
    basisReductionPercentageSt: object.basisReductionPercentageSt || 0,
    mvaRate: object.mvaRate || 0,
    methodBasisSt: object.methodBasisSt || FIXED_PRICE_ST,
    kind: object.kind || WITHHOLDING,
    taxSituation: object.taxSituation || ICMS_TAXABLE,
    goodsOrigin: object.goodsOrigin || NATIONAL_EXCEPT_3_4_5_8,
    unencumberedReason: object.unencumberedReason,
    unencumberedRate: object.unencumberedRate || 0,
    unencumberedBasisReduction: object.unencumberedBasisReduction || 0,
    deferralRate: object.deferralRate || 0,
    taxBenefitCodeId: ((object || {}).taxBenefitCode || {}).id
  };
};