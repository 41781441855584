import { personAtributesBuilder, personDefaultBuilder } from './PersonBuilder';
export const companyParamBuilder = (record, values) => {
  return {
    attributes: companyAtributesBuilder(record, values)
  };
};
export const companyAtributesBuilder = (record, values, newTenant = false) => {
  const personAttributes = personAtributesBuilder(record, values, newTenant);
  return {
    id: record?.id,
    person: personAttributes,
    administrator: values.administratorId
  };
};
export const companyDefaultBuilder = record => {
  const personAttributes = personDefaultBuilder(record?.person);
  return {
    id: record?.id,
    personId: record?.personId,
    ...personAttributes,
    administratorId: record?.administratorId
  };
};