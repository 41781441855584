import { BILLED } from "../constants/invoices/InvoiceFieldsOptions";
import { MAKE_UP_TOTAL } from "../constants/taxProfile/TaxFieldsOptions";
import { sanitizeBackend } from 'grifo-components';
export const defaultItemToPointOfSales = () => {
  return {
    quantity: 1,
    productUnitId: undefined,
    invoiceIndex: 1,
    discountValue: 0,
    freightValue: 0,
    insuranceValue: 0,
    cfopId: undefined,
    otherExpenses: 0,
    totalInvoiceMethod: MAKE_UP_TOTAL,
    icms: {},
    icmsDestination: {},
    icmsShared: {},
    ipi: {},
    pis: {},
    cofins: {}
  };
};
export const invoiceItemParamBuilder = (object, index, situation) => {
  let preparedAttributes = {
    invoiceIndex: object.invoiceIndex || index + 1,
    originalQuantity: object.originalQuantity,
    taxableQuantity: object.quantity,
    taxableUnitaryValue: object.unitaryValue,
    administrateTax: object.administrateTax,
    taxableGrossTotal: object.grossTotal
  };
  if (object.selectedProduct) {
    preparedAttributes = Object.assign({}, {
      productId: object.productId || object.selectedProduct[0],
      productUnitId: object.productUnitId || object.selectedProduct[1],
      locationId: object.locationId || object.selectedProduct[2],
      batchId: object.batchId || object.selectedProduct[3]
    }, preparedAttributes);
  }
  (object.additionalInformations || []).map(info => {
    delete info.mandatory;
    return {
      ...info
    };
  });
  delete object.selectedProduct;
  delete object.productUnit;
  delete object.product;
  delete object.location;
  delete object.productLabel;
  delete object.balance;
  delete object.invoiceItemTaxesCalculation;
  delete object.batch;
  delete object.totalReturned;
  delete object.returnedPercentage;
  delete object.key;
  delete object.cooperativeAct;
  delete object.cfop;
  delete object.taxableUnitId;
  delete object.categoryName;
  delete object.categoryBalance;
  if (situation !== BILLED) {
    delete object.goodsOrigin;
    delete object.icms;
    delete object.icmsDestination;
  }
  delete object?.icms?.ncmIds;
  if (object?.icms?.taxProfileName) delete object?.icms?.taxProfileName;
  if (object?.icms?.taxProfile) delete object?.icms?.taxProfile;
  if (object?.pis?.taxProfileName) delete object?.pis?.taxProfileName;
  if (object?.pis?.taxProfile) delete object?.pis?.taxProfile;
  if (object?.cofins?.taxProfileName) delete object?.cofins?.taxProfileName;
  if (object?.cofins?.taxProfile) delete object?.cofins?.taxProfile;
  if (object?.ipi?.taxProfileName) delete object?.ipi?.taxProfileName;
  if (object?.ipi?.taxProfile) delete object?.ipi?.taxProfile;
  if (object?.icmsShared?.taxProfile) delete object?.icmsShared?.taxProfile;
  if (object?.icmsTransfer?.taxProfile) delete object?.icmsTransfer?.taxProfile;
  if (!object?.icmsTransfer?.taxProfileId) delete object?.icmsTransfer;
  if (!object.pis?.taxProfileId) delete object.pis;
  if (!object.cofins?.taxProfileId) delete object.cofins;
  if (!object.pis?.taxSituation) delete object.pis;
  if (!object.cofins?.taxSituation) delete object.cofins;
  if (!object.ipi?.taxSituation) delete object.ipi;
  if (!object.icmsShared?.taxSituation) delete object.icmsShared;
  if (!object.icmsTransfer?.taxSituation) delete object.icmsTransfer;
  object.manufacturingDate ? sanitizeBackend(object.manufacturingDate).format("YYYY-MM-DD") : delete object.manufacturingDate;
  return {
    ...object,
    ...preparedAttributes
  };
};
export const loadInvoiceItem = (form, idx) => {
  if (!form) return;
  let item = form.getFieldValue(['items', idx]) || form.getFieldValue(['categoryItems', idx]) || {};
  const selectedProduct = [item.productId, item.productUnitId, item.batchId, item.locationId];
  item = {
    selectedProduct: selectedProduct,
    productId: item.productId,
    productUnitId: item.productUnitId,
    locationId: item.locationId,
    batchId: item.batchId,
    cfopId: item.cfopId,
    quantity: item.quantity || 1,
    originalQuantity: item.originalQuantity,
    totalReturned: item.totalReturned,
    returnedPercentage: item.returnedPercentage,
    totalInvoiceMethod: item.totalInvoiceMethod || MAKE_UP_TOTAL,
    discountValue: item.discountValue || 0,
    freightValue: item.freightValue || 0,
    insuranceValue: item.insuranceValue || 0,
    otherExpenses: item.otherExpenses || 0,
    unitaryValue: item.unitaryValue || 0,
    grossTotal: item.grossTotal || 0,
    productionScale: item.productionScale,
    productLabel: item.productLabel,
    taxableUnitId: item.taxableUnitId,
    administrateTax: item.administrateTax || form.getFieldValue('administrateTax'),
    ncmId: item.ncmId,
    invoiceItemTaxesCalculation: item.invoiceItemTaxesCalculation,
    retentions: item.retentions || [],
    additionalInformations: item.additionalInformations
  };
  return item;
};
export const loadProductSet = (form, idx) => {
  const item = loadInvoiceItem(form, idx);
  if (!item?.selectedProduct) return;
  return {
    productUnitId: item.selectedProduct[1],
    locationId: item.selectedProduct[2],
    batchId: item.selectedProduct[3]
  };
};
export const invoiceItemDefaultBuilder = form => {
  return loadInvoiceItem(form);
};