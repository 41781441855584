import gql from "graphql-tag";
import { latePaymentCalculationFragment } from "../fragments/LatePaymentCalculation";
import { movementFragment } from "../fragments/Movement";
import { movementWithWriteOffFragment } from "../fragments/Movement";
export const GQL_SEARCH_RECONCILIATION_MOVEMENTS = gql`
  query search($reconciliationFilter: ReconciliationFilter) {
    movements(reconciliationFilter: $reconciliationFilter) { 
      ...MovementWithWriteOff
    }
  }
  ${movementWithWriteOffFragment}   
`;
export const GQL_LATE_PAYMENT_CALCULATION = gql`
  query Calculate($attributes: CalculationAttributes) {    
    latePaymentCalc(attributes: $attributes) {
      ...LatePaymentCalculation
    }
  }
  ${latePaymentCalculationFragment}
`;
export const GQL_SEARCH_MOVEMENTS = gql`
  query search($filter: MovementCustomFilter) {
    movements(filter: $filter) { 
      ...Movement
    }
  }
  ${movementFragment}   
`;
export const GQL_REVERSE_ENTRY = gql`
mutation ReverseFinancialMovement($movements: MovementAttributes!, $date: String, $softDelete: Boolean) {
  reverseFinancialMovement(input: {movements: $movements, date: $date, softDelete: $softDelete}) { 
    movement { 
      ...Movement
    }, 
    errors 
  } 
}
${movementFragment}  `;
export const GQL_MOVEMENT_STORE = gql`
  mutation StoreMovement($movements: [MovementAttributes]!, $accountId: ID, $compensationsAccounts: [CompensationAccountsAttributes]) {
    storeMovement(input: {movements: $movements, accountId: $accountId, compensationsAccounts: $compensationsAccounts}) { 
      movement { 
        ...Movement
      }, 
      errors 
    } 
  }
  ${movementFragment}  
`;