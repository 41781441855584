import { bind, precisionType, sum } from 'grifo-components';
export const calculateResultCenterValue = (apportionment, basis) => {
  return precisionType(basis * apportionment / 100, 2);
};
export const calculateApportionmentValue = (fieldList, indexName, form, amount) => {
  if (!form) return;
  const apportionment = form.getFieldValue([fieldList, indexName, 'apportionment']);
  bind(form, calculateResultCenterValue(apportionment, amount), [fieldList, indexName, 'value']);
};
export const calculateApportionment = (fieldList, indexName, form, amount) => {
  if (!form) return;
  const value = form.getFieldValue([fieldList, indexName, 'value']);
  const apportionment = precisionType(100 * value / amount, 2);
  bind(form, apportionment, [fieldList, indexName, 'apportionment']);
};
export const calculateApportionableAmount = (fieldList, indexName, form, amount) => {
  const all = form.getFieldValue(fieldList) || [];
  const allocated = sum(all.filter((object, index) => index !== indexName));
  return precisionType(amount - allocated, 2);
};
export const calculateApportionable = (fieldList, form) => {
  const apportionments = sum(form.getFieldValue(fieldList), 'apportionment');
  return precisionType(apportionments, 2);
};