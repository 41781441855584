import gql from "graphql-tag";
import { operationResultCenterFragment } from "./OperationResultCenter";
export const operationFragment = gql`
  fragment Operation on Operation {
    id,
    name,
    kind,
    classification,
    internal,
    discardedAt,
    parentId,
    generatesMovement,
    latePaymentFine,
    latePaymentInterest,
    latePaymentInterestKind,
    earlyPaymentDeduction,
    daysAfterOverdueToExpiredTitle,
    canPayDefaulters,
    latePaymentInterestOperationId,
    latePaymentFineOperationId,
    writeOffId,
    earlyPaymentOperationId,
  }
`;
const operationSelectAttributesFragment = gql`
  fragment OperationSelectAttributes on Operation {
    id,
    name,
    discardedAt,
    generatesMovement,
  }
`;
export const operationSelectFragment = gql`
  fragment OperationSelect on Operation {
    ...OperationSelectAttributes
     children {
       ...OperationSelectAttributes,
       children {
         ...OperationSelectAttributes,
         children {
           ...OperationSelectAttributes,
           children {
             ...OperationSelectAttributes,
             children {
               ...OperationSelectAttributes,
             }
           }
         }
       }
     }
  }
  ${operationSelectAttributesFragment} 
`;
export const operationListFragment = gql`
  fragment OperationList on Operation {
    ...Operation
     children {
       ...Operation
       children {
         ...Operation,
         children {
           ...Operation,
           children {
             ...Operation,
             children {
               ...Operation,
             }
           }
         }
       }
     }
  }
  ${operationFragment} 
`;
export const operationLoadFragment = gql`
  fragment OperationLoad on Operation {
    ...Operation,
    daysAfterOverdueToExpiredTitle,
    acceptPaymentAfterDuedate,
    protestCode,
    daysToProtest,
    canPayDefaulters,
    latePaymentFine,
    latePaymentInterest,
    latePaymentInterestKind,
    canSetWriteOff,
    canChangeKind,
    counterpartId,
    latePaymentInterestOperationId,
    latePaymentFineOperationId,
    writeOffId,
    earlyPaymentOperationId,
    earlyPaymentDeduction,
    resultCenters {
      ...OperationResultCenter
    }
  }
  ${operationFragment}
  ${operationResultCenterFragment}
`;