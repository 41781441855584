import gql from "graphql-tag";
import { cooperativePersonSimpleFragment } from './CooperativePerson';
import { personStatisticFragment } from 'financial-front';
export const memberRegistrationFragment = gql`
  fragment MemberRegistration on MemberRegistration  { 
    id,
    registrationCode,
    observation,
    start,
    finish,
    operative,
    capitalPayment {
      ...PersonStatistic
    }
    returnedQuotas,
    personId,
    person {
      ...CooperativePersonSimple
    }
  }
  ${cooperativePersonSimpleFragment}
  ${personStatisticFragment}
  
`;