import gql from "graphql-tag";
import { batchFragment, productUnitFragment, locationSimpleFragment } from 'stock-front';
import { offerEventFragment } from './OfferEvent';
import { bidFragment } from "./Bid";
import { OfferPaymentFragment } from "./OfferPayment";
import { fiscalOperationFragment } from 'fiscal-front';
import { companyFragment } from 'admin-front';
export const offerFragment = gql`
  fragment Offer on Offer  { 
    id,
    company {
      ...Company
    },
    availableQuantity,
    quantity,
    maxPerBid,
    showAvailable,
    start,
    finish,
    availability,
    price,
    toLabel,
    canCancel,
    canPublish,
    canFinish,
    canEdit,
    canGenerateInvoice,
    unavailablePaymentMethodIds,
    active,
    bid {
      ...Bid
    }
    productUnitId,
    locationId,
    batchId,
    operation {
      ...FiscalOperation
    }
    currentEvent {
      ...OfferEvent
    }
    productUnit {
      ...ProductUnit
    }
    location {
      ...LocationSimple
    }
    batch {
      ...Batch
    }
    bids {
      ...Bid
    }
    payments {
      ...OfferPayment
    }
  }
  ${companyFragment}
  ${fiscalOperationFragment}
  ${OfferPaymentFragment}
  ${bidFragment}
  ${productUnitFragment}
  ${offerEventFragment}
  ${locationSimpleFragment}
  ${batchFragment}
  ${bidFragment}

`;