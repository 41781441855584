import { personAtributesBuilder, personDefaultBuilder } from 'admin-front';
import { bankAccountDefaultBuilder } from './BankAccountBuilder';
export const financialPersonParamBuilder = (record, values) => {
  const personAttributes = personAtributesBuilder(record, values);
  return {
    attributes: {
      person: personAttributes,
      personBankings: values?.personBankings || []
    }
  };
};
export const financialPersonDefaultBuilder = record => {
  const personAttributes = personDefaultBuilder(record);
  return {
    ...personAttributes,
    personBankings: (record?.personBankings || []).map(object => {
      return bankAccountDefaultBuilder(object);
    })
  };
};