export const ANYONE = 'anyone';
export const MEMBER = 'member';
export const NON_MEMBER = 'non_member';
export const SALES = 'sales';
export const CUSTOMERS = 'customers';
export const SUPPLIERS = 'suppliers';
export const TOTAL = 'total';
export const QUANTITY = 'quantity';
export const UNITARY_VALUE = 'unitary_value';
export const PROFIT = 'profit';
export const ALL = 'all';
export const HAS_BOUGHT = 'has_bought';
export const BOUGHT_LAST_YEAR = 'bought_last_year';
export const BOUGHT_LAST_6_MONTHS = 'bought_last_6_months';
export const BOUGHT_LAST_3_MONTHS = 'bought_last_3_months';
export const BOUGHT_LAST_MONTH = 'bought_last_month';
export const BOUGHT_LAST_WEEK = 'bought_last_week';
export const BOUGHT_TODAY = 'bought_today';
export const FUNNEL_OPTIONS = [{
  value: ALL,
  label: 'Todos'
}, {
  value: HAS_BOUGHT,
  label: 'Comprou ao menos uma vez'
}, {
  value: BOUGHT_LAST_YEAR,
  label: 'Comprou no último ano'
}, {
  value: BOUGHT_LAST_6_MONTHS,
  label: 'Comprou nos últimos seis meses'
}, {
  value: BOUGHT_LAST_3_MONTHS,
  label: 'Comprou nos últimos três meses'
}, {
  value: BOUGHT_LAST_MONTH,
  label: 'Comprou no último mês'
}, {
  value: BOUGHT_LAST_WEEK,
  label: 'Comprou na última semana'
}, {
  value: BOUGHT_TODAY,
  label: 'Comprou hoje'
}];