export const CHIEF_COUNSELOR = 'chief_counselor';
export const COUNSELOR = 'counselor';
export const SURROGATE = 'surrogate';
export const FISCAL_MEMBER_ROLE = [{
  value: CHIEF_COUNSELOR,
  label: 'Conselheiro Chefe'
}, {
  value: COUNSELOR,
  label: 'Conselheiro'
}, {
  value: SURROGATE,
  label: 'Suplente'
}];