import { calculateItemsTotal } from '../../utils/InvoiceCalculations';
const attOrZero = att => {
  return att || 0.0;
};
export const toggleFieldValue = (form, fieldName, condition, indexName) => {
  if (condition) {
    return;
  }
  const ipi = form.getFieldValue(['items', indexName, 'ipi']);
  const icms = form.getFieldValue(['items', indexName, 'icms']);
  const pis = form.getFieldValue(['items', indexName, 'pis']);
  const cofins = form.getFieldValue(['items', indexName, 'cofins']);
  const totalTaxes = attOrZero(icms?.value) + attOrZero(icms?.valueSt) + attOrZero(icms?.fcpValue) + attOrZero(icms?.fcpValueSt) + attOrZero(icms?.unencumberedValue) + attOrZero(icms?.deferralValue) + attOrZero(icms?.withheldValue) + attOrZero(icms?.fcpWithheldValue) + attOrZero(icms?.effectiveValue) + attOrZero(pis?.value) + attOrZero(cofins?.value) + attOrZero(ipi?.value);
  form.setFieldValue(['items', indexName, 'totalTaxes'], totalTaxes);
  calculateItemsTotal(form);
  form.setFieldValue(fieldName, !form.getFieldValue(fieldName));
};