import { sanitizeBackend } from 'grifo-components';
export const groupParamsBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      paymentConditionId: values.paymentConditionId,
      paymentMethodId: values.paymentMethodId,
      currentEvent: values.currentEvent,
      currentDate: values.currentDate,
      requestIds: values.requestIds,
      requestProductUnits: values.requestProductUnits.map(object => ({
        quantity: object.quantity,
        productUnitId: object.productUnit[1]
      }))
    }
  };
};
export const groupRequestBuilder = records => {
  const object = (records || [])[0] || {};
  let requestIds;
  if (records) {
    requestIds = records.map(record => {
      return record.id;
    });
  }
  return {
    paymentCondition: object.paymentCondition || {},
    paymentMethod: object.paymentCondition || {},
    pickupCompany: object.pickupCompany || {},
    currentEvent: object.currentEvent,
    canEdit: object.canEdit,
    transactionId: object.transactionId || '',
    currentDate: sanitizeBackend(object.currentDate),
    requestProductUnits: groupRequestProducts(records),
    requestIds: requestIds
  };
};
export const groupRequestProducts = records => {
  let data = [];
  for (var index in records) {
    let requestProductUnits = (records[index] || {}).requestProductUnits;
    if (requestProductUnits) {
      for (var i in requestProductUnits) {
        let requestProductUnit = requestProductUnits[i];
        let found = data.findIndex(object => object.product.id === requestProductUnit.product.id && object.productUnit.id === requestProductUnit.productUnit.id);
        if (found < 0) {
          data.push({
            product: requestProductUnit.product,
            productUnit: requestProductUnit.productUnit,
            quantity: requestProductUnit.quantity
          });
        } else {
          data[found].quantity += requestProductUnit.quantity;
        }
      }
    }
  }
  return data;
};