import gql from "graphql-tag";
export const personBankingFragment = gql`
  fragment PersonBanking on PersonBanking {
    id,
    description,
    branch,
    kind,
    number,
    pixKey,
    bankId,
    implantationDate
  }
`;