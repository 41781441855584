import ProductBrandsAction from '../components/products/ProductBrandsAction';
import ProductSuppliersAction from '../components/products/ProductSuppliersAction';
import FuelPumpSealsAction from '../components/fuelPumps/FuelPumpSealsAction';
import NozzlesAction from '../components/nozzles/NozzlesAction';
export const fuelPumpActionsBuilder = fuelPump => {
  return [{
    action: ProductBrandsAction,
    actionProps: {
      record: fuelPump,
      fuelPump: true
    }
  }, {
    action: ProductSuppliersAction,
    actionProps: {
      record: fuelPump,
      fuelPump: true
    }
  }, {
    action: FuelPumpSealsAction,
    actionProps: {
      record: fuelPump
    }
  }, {
    action: NozzlesAction,
    actionProps: {
      record: fuelPump
    }
  }];
};