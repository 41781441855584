import { sanitizeBackend } from 'grifo-components';
import { fiscalPersonParamBuilder, fiscalPersonDefaultBuilder } from 'fiscal-front';
import { INDUSTRY_OTHERS, NON_INDUSTRY } from '../constants/PersonAccountingOptions';
export const accountingPersonParamBuilder = (record, values) => {
  return {
    attributes: accountingPersonAttributes(record, values)
  };
};
export const accountingPersonAttributes = (record, values) => {
  const personAttributes = fiscalPersonParamBuilder(record, values).attributes;
  return {
    person: personAttributes,
    scpIndicator: values.scpIndicator,
    csllBasis: values.csllBasis,
    apurationPeriod: values.apurationPeriod,
    stockAvaliationMethod: values.stockAvaliationMethod,
    presidentQualification: values.presidentQualification,
    spedFrameworkProfile: values.spedFrameworkProfile,
    icmsTaxableObligationCode: values.icmsTaxableObligationCode,
    accountingActivity: values.accountingActivity,
    regimeKind: values.regimeKind,
    creditApropriationKind: values.creditApropriationKind,
    contributionKind: values.contributionKind,
    cumulativeRegime: values.cumulativeRegime,
    specialSituation: values.specialSituation,
    specialSituationDate: values.specialSituationDate,
    bookNumber: (values.bookNumber || '').toString(),
    nireCode: values.nireCode,
    industryActivity: values.industryActivity,
    industryClassification: values.industryClassification
  };
};
export const accountingPersonDefaultBuilder = record => {
  const personAttributes = fiscalPersonDefaultBuilder(record);
  return {
    ...personAttributes,
    scpIndicator: record?.scpIndicator,
    csllBasis: record?.csllBasis,
    apurationPeriod: record?.apurationPeriod,
    stockAvaliationMethod: record?.stockAvaliationMethod,
    presidentQualification: record?.presidentQualification,
    spedFrameworkProfile: record?.spedFrameworkProfile,
    icmsTaxableObligationCode: record?.icmsTaxableObligationCode,
    accountingActivity: record?.accountingActivity,
    regimeKind: record?.regimeKind,
    creditApropriationKind: record?.creditApropriationKind,
    contributionKind: record?.contributionKind,
    cumulativeRegime: record?.cumulativeRegime,
    specialSituation: record?.specialSituation,
    specialSituationDate: sanitizeBackend(record?.specialSituationDate),
    bookNumber: record?.bookNumber,
    nireCode: record?.nireCode,
    industryActivity: record?.industryActivity || NON_INDUSTRY,
    industryClassification: record?.industryClassification || INDUSTRY_OTHERS
  };
};