import { BLUE, GREEN, RED } from 'grifo-components';
export const BID_CANCELLED = 'cancelled';
export const BID_DENIED = 'denied';
export const BID_ACCEPTED = 'accepted';
export const BID_BIDDED = 'bidded';
export const BID_CANCELLED_BY_COMPANY = 'cancelled_by_company';
export const BID_EVENTS = [{
  value: undefined,
  label: 'Em aberto'
}, {
  value: BID_CANCELLED,
  label: 'Cancelado',
  color: RED
}, {
  value: BID_DENIED,
  label: 'Rejeitado',
  color: RED
}, {
  value: BID_ACCEPTED,
  label: 'Aceito',
  color: GREEN
}, {
  value: BID_BIDDED,
  label: 'Proposta enviada',
  color: BLUE
}, {
  value: BID_CANCELLED_BY_COMPANY,
  label: 'Cancelado pela empresa',
  color: RED
}];