import gql from "graphql-tag";
import { parentAccountFragment } from "../fragments/Account";
export const GQL_ACCOUNT_RESCHEDULING = gql`
  mutation AccountRescheduling($attributes: AccountAttributes!, $accountId: ID) {
    accountRescheduling(input: {attributes: $attributes, accountId: $accountId}) { 
      newAccount { 
        ...ParentAccount
      },
      oldAccount { 
        ...ParentAccount
      },    
      errors 
    } 
  }
  ${parentAccountFragment}
`;