import { dateOrToday } from 'grifo-components';
import { CHECKING_ACCOUNT } from "../constants/bankAccounts/bankAccountsFieldsOptions";
export const bankAccountParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      description: values.description,
      kind: values.kind,
      number: values.number,
      canGenerateBankSlip: values.canGenerateBankSlip,
      ourNumber: values.ourNumber,
      branch: values.branch,
      bankId: values.bankId,
      pixKey: values.pixKey,
      implantationDate: values.implantationDate,
      operationId: values.operationId,
      agreements: values.agreements
    }
  };
};
export const bankAccountDefaultBuilder = record => {
  return {
    description: record?.description,
    kind: record?.kind || CHECKING_ACCOUNT,
    implantationDate: dateOrToday(record?.currentAsset?.implantationDate),
    number: record?.number,
    ourNumber: record?.ourNumber,
    branch: record?.branch,
    pixKey: record?.pixKey,
    bankId: record?.bankId,
    operationId: record?.currentAsset?.operationId,
    reconciliation: record?.reconciliation,
    agreements: record?.agreements,
    canGenerateBankSlip: record?.canGenerateBankSlip || false
  };
};