export const REQUEST_REQUESTED = 'requested';
export const REQUEST_CANCELLED = 'cancelled';
export const REQUEST_CANCELLED_BY_COMPANY = 'cancelled_by_company';
export const REQUEST_FINISHED = 'finished';
export const REQUEST_IN_ANALISYS = 'in_analisys';
export const REQUEST_BUYED = 'buyed';
export const REQUEST_WAITING_WITHDRAW = 'waiting_withdraw';
export const REQUEST_CHANGE_REQUESTED = 'change_requested';
export const REQUEST_CHANGE_CONFIRMED = 'change_confirmed';
export const REQUEST_DENIED = 'denied';
export const REQUEST_APPROVED = 'approved';
export const REQUEST_GROUPED = 'grouped';
export const REQUEST_PURCHASE_ORDER = 'purchase_order';
export const REQUEST_EVENTS = [{
  value: REQUEST_REQUESTED,
  label: 'Requisitado'
}, {
  value: REQUEST_CANCELLED,
  label: 'Cancelado'
}, {
  value: REQUEST_CANCELLED_BY_COMPANY,
  label: 'Cancelado pela Cooperativa'
}, {
  value: REQUEST_FINISHED,
  label: 'Finalizado'
}, {
  value: REQUEST_IN_ANALISYS,
  label: 'Sob Análise'
}, {
  value: REQUEST_BUYED,
  label: 'Comprado'
}, {
  value: REQUEST_WAITING_WITHDRAW,
  label: 'Aguardando Retirada'
}, {
  value: REQUEST_CHANGE_REQUESTED,
  label: 'Alteração Requisitada'
}, {
  value: REQUEST_CHANGE_CONFIRMED,
  label: 'Alteração Confirmada'
}, {
  value: REQUEST_DENIED,
  label: 'Negado'
}, {
  value: REQUEST_APPROVED,
  label: 'Aprovado'
}, {
  value: REQUEST_GROUPED,
  label: 'Agrupado'
}, {
  value: REQUEST_PURCHASE_ORDER,
  label: 'Pedido Realizado'
}];