import gql from "graphql-tag";
import { personFragment, personSimpleFragment } from "./Person";
import { addressFragment } from "./Address";
import { phoneFragment } from "./Phone";
export const companySimpleFragment = gql`
  fragment CompanySimple on Company {
    id,
    kind,
    name,
    tradeName,
    isHeadOffice,
    isCooperative,
    administratorId,
    person {
      ...PersonSimple
    },
    address {
      ...Address
    },
    phone {
      ...Phone
    }
  }
  ${personSimpleFragment}
  ${addressFragment}
  ${phoneFragment}
`;
export const companyFragment = gql`
  fragment Company on Company {
    ...CompanySimple,
    person {
      ...Person
    },
  }
  ${companySimpleFragment}
  ${personFragment}
`;