import { INTEGRATED, MONEY, OTHERS } from "../constants/paymentMethods/PaymentMethodsFieldsOptions";
export const paymentMethodParamBuilder = (record, values) => {
  let paymentConditions = (values.paymentConditions || []).map(object => ({
    paymentConditionId: object.paymentConditionId,
    fees: object.fees || 0.0,
    discount: object.discount || 0.0
  }));
  return {
    attributes: {
      id: record ? record.id : null,
      name: values.name,
      kind: values.kind,
      fees: values.fees,
      discount: values.discount,
      integrationMethod: values.integrationMethod,
      creditCardBrand: values.creditCardBrand,
      paymentInstitutionId: values.paymentInstitutionId,
      paymentConditions: paymentConditions,
      currentAssetId: values.currentAssetId
    }
  };
};
export const paymentMethodDefaultBuilder = record => {
  const object = record || {};
  return {
    name: object.name,
    kind: object.kind || MONEY,
    fees: object.fees || 0,
    discount: object.discount || 0,
    integrationMethod: object.integrationMethod || INTEGRATED,
    creditCardBrand: object.creditCardBrand || OTHERS,
    paymentInstitutionId: object.paymentInstitutionId,
    paymentConditions: (object.paymentMethodConditions || []).map(object => ({
      paymentConditionId: object.paymentConditionId,
      fees: object.fees || 0.0,
      discount: object.discount || 0.0
    })),
    currentAssetId: object.currentAssetId
  };
};