import { precisionType } from 'grifo-components';
import { calculatePaymentValue } from "financial-front";
export const calculateItemsTotal = form => {
  if (!form) return;
  const items = form.getFieldValue('items');
  const freightValue = items.reduce(function (previous, current) {
    return previous + (current.freightValue || 0);
  }, 0);
  const insuranceValue = items.reduce(function (previous, current) {
    return previous + (current.insuranceValue || 0);
  }, 0);
  const otherValue = items.reduce(function (previous, current) {
    return previous + (current.otherExpenses || 0);
  }, 0);
  const discountValue = items.reduce(function (previous, current) {
    return previous + (current.discountValue || 0);
  }, 0);
  const itemsTotal = items.reduce(function (previous, current) {
    return previous + (current.total || 0);
  }, 0);
  const totalTaxes = items.reduce(function (previous, current) {
    return previous + (current.totalTaxes || 0);
  }, 0);
  form.setFieldValue('freightValue', freightValue);
  form.setFieldValue('insuranceValue', insuranceValue);
  form.setFieldValue('otherValue', otherValue);
  form.setFieldValue('discountValue', discountValue);
  form.setFieldValue('itemsTotal', itemsTotal);
  form.setFieldValue('totalTaxes', totalTaxes);
  calculateTotalBeforePayment(undefined, undefined, form);
};
const getValueByKind = (value, precision, form) => {
  return precisionType(value, precision, form.getFieldValue(precisionType));
};
export const calculateTotalBeforePayment = (value, field, form) => {
  if (!form) return;
  const itemsTotal = form.getFieldValue('itemsTotal') || 0;
  const change = form.getFieldValue('change') || 0;
  let total = getValueByKind(change + itemsTotal, 2, form);
  form.setFieldValue('totalBeforePayment', total);
  const payments = form.getFieldValue('payments');
  if ((payments || []).length === 1) {
    form.setFieldValue(['payments', 0, 'valueToPay'], total);
    calculatePaymentValue(total, 0, form, form.getFieldValue('date'));
  }
  calculateTotal(form);
};
export const calculateTotal = form => {
  if (!form) return;
  form.setFieldValue('total', getValueByKind(form.getFieldValue('totalBeforePayment'), 2, form));
};
export const calculateInvoiceTaxes = form => {
  if (!form) return;
  const items = form.getFieldValue('items') || [];
  let invoiceTaxes = form.getFieldValue('invoiceTax') || {};
  invoiceTaxes.discountValue = form.getFieldValue('discountValue') || 0.00;
  invoiceTaxes.otherValue = form.getFieldValue('otherValue') || 0.00;
  invoiceTaxes.freightValue = form.getFieldValue('freightValue') || 0.00;
  invoiceTaxes.insuranceValue = form.getFieldValue('insuranceValue') || 0.00;
  invoiceTaxes.total = form.getFieldValue('total') || 0.00;
  invoiceTaxes.iiValue = 0.00;
  invoiceTaxes.ipiReturnedValue = 0.00;
  invoiceTaxes.totalTaxes = items.reduce((total, obj) => (obj?.totalTaxes || 0) + total, 0) || 0.00;
  invoiceTaxes.itemsTotal = items.reduce((total, obj) => (obj?.total || 0) + total, 0) || 0.00;
  invoiceTaxes.icmsBasis = items.reduce((total, obj) => (obj?.icms?.basis || 0) + total, 0) + items.reduce((total, obj) => (obj?.icms?.basisSt || 0) + total, 0) + items.reduce((total, obj) => (obj?.icms?.withheldBasis || 0) + total, 0) + items.reduce((total, obj) => (obj?.icms?.effectiveBasis || 0) + total, 0) + items.reduce((total, obj) => (obj?.icms?.unemcumberedBasis || 0) + total, 0) || 0.00;
  invoiceTaxes.icmsValue = items.reduce((total, obj) => (obj?.icms?.value || 0) + total, 0) + items.reduce((total, obj) => (obj?.icms?.valueSt || 0) + total, 0) + items.reduce((total, obj) => (obj?.icms?.withheldValue || 0) + total, 0) + items.reduce((total, obj) => (obj?.icms?.effectiveValue || 0) + total, 0) + items.reduce((total, obj) => (obj?.icms?.unemcumberedValue || 0) + total, 0) || 0.00;
  invoiceTaxes.icmsWithheldValue = items.reduce((total, obj) => (obj?.icms?.withheldValue || 0) + total, 0) || 0.00;
  invoiceTaxes.fcpValueStateDestination = items.reduce((total, obj) => (obj?.icms?.fcpValueStateDestination || 0) + total, 0) || 0.00;
  invoiceTaxes.icmsValueStateDestination = items.reduce((total, obj) => (obj?.icmsDestination?.value || 0) + total, 0) || 0.00;
  invoiceTaxes.icmsValueStateOrigin = items.reduce((total, obj) => (obj?.icms?.valueStateOrigin || 0) + total, 0) || 0.00;
  invoiceTaxes.fcpValue = items.reduce((total, obj) => (obj?.icms?.fcpValue || 0) + total, 0) || 0.00;
  invoiceTaxes.icmsBasisSt = items.reduce((total, obj) => (obj?.icms?.basisSt || 0) + total, 0) || 0.00;
  invoiceTaxes.icmsValueSt = items.reduce((total, obj) => obj?.icms?.valueSt || 0 + total, 0) || 0.00;
  invoiceTaxes.fcpWithheldValue = items.reduce((total, obj) => (obj?.icms?.fcpWithheldValue || 0) + total, 0) || 0.00;
  invoiceTaxes.ipiValue = items.reduce((total, obj) => (obj?.ipi?.value || 0) + total, 0) || 0.00;
  invoiceTaxes.pisValue = items.reduce((total, obj) => (obj?.pis?.value || 0) + total, 0) || 0.00;
  invoiceTaxes.cofinsValue = items.reduce((total, obj) => (obj?.cofins?.value || 0) + total, 0) || 0.00;
  form.setFieldValue('invoiceTax', invoiceTaxes);
  return invoiceTaxes;
};
export const setDfeSeriesNumber = (operationDfeSeries, companyFiscalStarts, dfeModel) => {
  if (operationDfeSeries) return operationDfeSeries;
  if (companyFiscalStarts.length === 0) return 1;
  let dfeSeries = companyFiscalStarts.filter(obj => obj.dfeModel == dfeModel);
  let seriesNumber = dfeSeries.map(obj => obj.dfeSeries);
  return Math.max(...seriesNumber);
};