import gql from "graphql-tag";
import { personSimpleFragment } from 'admin-front';
import { productModelFragment } from "./ProductModel";
export const brandSimpleFragment = gql`
  fragment BrandSimple on Brand  {
    id,
    name,
    discardedAt,
  }
`;
export const brandFragment = gql`
  fragment Brand on Brand  {
    ...BrandSimple
    person {
      ...PersonSimple
    },
    productModels {
      ...ProductModel
    },
  }
  ${brandSimpleFragment}
  ${personSimpleFragment}  
  ${productModelFragment}
`;