import gql from "graphql-tag";
import { personFragment, userStoreSimpleFragment } from 'admin-front';
import { accountSimpleFragment } from "./Account";
export const chargeFragment = gql`
  fragment Charge on Charge {
    id,
    userId,
    user {
      ...UserStoreSimple
    },
    contactId,
    contact {
      ...Person
    },
    accountId,
    account {
      ...AccountSimple
    },
    email,
    result,
    observation,
    chargeMethod,
    datetime,
    returnDate,
  }
  ${personFragment}
  ${userStoreSimpleFragment}
  ${accountSimpleFragment}
`;