import gql from "graphql-tag";
import { ContractFragment, ContractListingFragment } from "../fragments/Contract";
export const GQL_CONTRACT_SEARCH = gql`
  query Search($pageSize: Int, $cursor: String, $operationKind: String, $fiscalContractFilter: FiscalContractFilter) {    
    fiscalContractsPaginated(first: $pageSize, after: $cursor, operationKind: $operationKind, fiscalContractFilter: $fiscalContractFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...ContractListing
        }
      }
    }    
  }
  ${ContractListingFragment}
`;
export const GQL_CONTRACT_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: {id: $id, className: "Fiscal::Contract"}) {
      id,
      discardedAt 
    }
  }
`;
export const GQL_CONTRACT_BY_ID = gql`
  query GetById($id: ID) {    
    fiscalContracts(id: $id) {
      ...FiscalContract
    }    
  }
  ${ContractFragment}
`;
export const GQL_CONTRACT_STORE = gql`
  mutation StoreContract($attributes: FiscalContractAttributes!) {
    storeFiscalContract(input: {attributes: $attributes}) { 
      contract {
        ...FiscalContract,
      }, 
      errors 
    } 
  }
  ${ContractFragment}
`;
export const GQL_CONTRACT_STATUS_STORE = gql`
  mutation StoreContractEvent($attributes: FiscalContractEventAttributes!) {
    storeFiscalContractEvent(input: {attributes: $attributes}) { 
      id,
      errors
    }
  }
`;