import { FINANCIAL, REPORTS, CONFIG } from 'grifo-components';
import { TO_PAY, TO_RECEIVE } from './operations/OperationsFieldsOptions';
import { CASHIER, CREDIT_CARD_COMPANY, TREASURY, BANK } from "./currentAssets/CurrentAssetsFieldsOptions";
export const buildFeatures = () => {
  const financialPath = "/" + FINANCIAL;
  const financialConfigPath = financialPath + '/' + CONFIG;
  const financialReportPath = financialPath + '/' + REPORTS;
  const financialConfigRoutes = [{
    ordination: '02.10.01',
    name: 'Operações',
    key: financialConfigPath + "/operations",
    routePath: financialConfigPath + "/operations",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "OperationsPage",
      import_path: "containers/operations/OperationsPage"
    }
  }, {
    ordination: '02.10.02',
    name: 'Disponibilidades',
    key: financialConfigPath + "/current_assets",
    routePath: financialConfigPath + "/current_assets",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "CurrentAssetsPage",
      import_path: "containers/currentAssets/CurrentAssetsPage"
    },
    contentProp: {
      currentAssetKind: [CASHIER, BANK, CREDIT_CARD_COMPANY, TREASURY]
    }
  }, {
    ordination: '02.10.03',
    name: 'Condições de pagamento',
    key: financialConfigPath + "/payment_conditions",
    routePath: financialConfigPath + "/payment_conditions",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "PaymentConditionsPage",
      import_path: "containers/paymentConditions/PaymentConditionsPage"
    }
  }, {
    ordination: '02.10.04',
    name: 'Formas de pagamento',
    key: financialConfigPath + "/payment_methods",
    routePath: financialConfigPath + "/payment_methods",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "PaymentMethodsPage",
      import_path: "containers/paymentMethods/PaymentMethodsPage"
    }
  }, {
    ordination: '02.10.05',
    name: 'Centros de resultado',
    key: financialConfigPath + "/result_center",
    routePath: financialConfigPath + "/result_center",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "ResultCentersPage",
      import_path: "containers/resultCenters/ResultCentersPage"
    }
  }];
  const financialReportRoutes = [{
    ordination: '02.11.01',
    name: 'Contas a pagar',
    key: financialReportPath + "/bills_to_pay",
    routePath: financialReportPath + "/bills_to_pay",
    kind: 'report',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "AccountReportPage",
      import_path: "containers/accounts/AccountReportPage"
    },
    contentProp: {
      operationKind: 0
    }
  }, {
    ordination: '02.11.02',
    name: 'Contas a receber',
    key: financialReportPath + "/bills_to_receive",
    routePath: financialReportPath + "/bills_to_receive",
    kind: 'report',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "AccountReportPage",
      import_path: "containers/accounts/AccountReportPage"
    },
    contentProp: {
      operationKind: 1
    }
  }, {
    ordination: '02.11.03',
    name: 'Extrato de Movimento Diário de Bancos/Caixas',
    key: financialReportPath + "/daily_account_statement",
    routePath: financialReportPath + "/daily_account_statement",
    kind: 'report',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "DailyAccountStatementReportPage",
      import_path: "containers/accounts/DailyAccountStatementReportPage"
    }
  }, {
    ordination: '02.11.04',
    name: 'Acerto de Contas',
    key: financialReportPath + "/settlement_report",
    routePath: financialReportPath + "/settlement_report",
    kind: 'report',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "SettlementReportPage",
      import_path: "containers/accounts/SettlementReportPage"
    }
  }, {
    ordination: '02.11.05',
    name: 'Baixas Financeiras',
    key: financialReportPath + "/write_off",
    routePath: financialReportPath + "/write_off",
    kind: 'report',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "WriteOffReportPage",
      import_path: "containers/accounts/WriteOffReportPage"
    }
  }, {
    ordination: '02.11.06',
    name: 'Fluxo de Caixa',
    key: financialReportPath + "/cash_flow",
    routePath: financialReportPath + "/cash_flow",
    kind: 'report',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "CashFlowReportPage",
      import_path: "containers/accounts/CashFlowReportPage"
    }
  }, {
    ordination: '02.11.07',
    name: 'Análise Financeira por Pessoa',
    key: financialReportPath + "/accounts_by_person",
    routePath: financialReportPath + "/accounts_by_person",
    kind: 'report',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "FinancialAnalysisByPersonReportPage",
      import_path: "containers/financialAnalysisByPerson/FinancialAnalysisByPersonReportPage"
    }
  }];
  const routes = [{
    ordination: '02.01',
    name: 'Dashboard',
    key: financialPath + "/dashboard",
    routePath: financialPath + "/dashboard",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "DashboardFinancialPage",
      import_path: "containers/DashboardPage"
    }
  }, {
    ordination: '02.02',
    name: 'Análise financeira',
    key: financialPath + "/financial_analysis",
    routePath: financialPath + "/financial_analysis",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "FinancialAnalysisPage",
      import_path: "containers/financialAnalysis/FinancialAnalysisPage"
    }
  }, {
    ordination: '02.03',
    name: 'Contas a pagar',
    key: financialPath + "/bills_to_pay",
    routePath: financialPath + "/bills_to_pay",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "AccountsPage",
      import_path: "containers/accounts/AccountsPage"
    },
    contentProp: {
      operationKind: TO_PAY
    },
    dividerAbove: true
  }, {
    ordination: '02.04',
    name: 'Contas a receber',
    key: financialPath + "/bills_to_receive",
    routePath: financialPath + "/bills_to_receive",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "AccountsPage",
      import_path: "containers/accounts/AccountsPage"
    },
    contentProp: {
      operationKind: TO_RECEIVE
    }
  }, {
    ordination: '02.05',
    name: 'Negociações',
    key: financialPath + "/negotiation",
    routePath: financialPath + "/negotiation",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "NegotiationsPage",
      import_path: "containers/negotiations/NegotiationsPage"
    }
  }, {
    ordination: '02.06',
    name: 'Contas Bancárias',
    key: financialPath + "/bank_accounts",
    routePath: financialPath + "/bank_accounts",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "BankAccountsPage",
      import_path: "containers/bankAccounts/BankAccountsPage"
    }
  }, {
    ordination: '02.07',
    name: 'Caixas',
    key: financialPath + "/cashier",
    routePath: financialPath + "/cashier",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "CashiersPage",
      import_path: "containers/cashiers/CashiersPage"
    }
  }, {
    ordination: '02.08',
    name: 'Recorrências',
    key: financialPath + "/recurrence",
    routePath: financialPath + "/recurrence",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "RecurrencesPage",
      import_path: "containers/recurrences/RecurrencesPage"
    }
  }, {
    ordination: '02.10',
    name: 'Configurações',
    key: financialConfigPath,
    routePath: financialConfigPath,
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    dividerAbove: true,
    children: financialConfigRoutes
  }, {
    ordination: '02.11',
    name: 'Relatórios',
    key: financialReportPath,
    routePath: financialReportPath,
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    children: financialReportRoutes
  }];
  return {
    ordination: '02',
    name: 'Financeiro',
    key: financialPath,
    kind: 'module',
    status: 'active',
    routePath: financialPath,
    routePathParams: undefined,
    dividerAbove: false,
    icon: 'financial',
    content: undefined,
    contentProp: undefined,
    authorization: 'regular',
    userAuthorization: undefined,
    children: routes
  };
};