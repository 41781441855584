import gql from "graphql-tag";
import { fiscalPersonFragment, fiscalPersonToListFragment } from "../fragments/Person";
export const GQL_FISCAL_PEOPLE_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $search: String) {    
    fiscalPeoplePaginated(first: $pageSize, after: $cursor, search: $search) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...FiscalPerson
        }
      }
    }    
  }
  ${fiscalPersonFragment}
`;
export const GQL_PEOPLE_SEARCH_ID_SEARCH = gql`
  query GetById($id: ID) {    
    fiscalPeople(id: $id) {
      ...FiscalPerson
    }
  }
  ${fiscalPersonFragment}   
`;
export const GQL_FISCAL_PEOPLE_LIST_SIMPLE = gql`
  query GetAll($pageSize: Int, $cursor: String, $search: String) {    
    fiscalPeoplePaginated(first: $pageSize, after: $cursor, search: $search) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...FiscalPersonToList
        }
      }
    }    
  }
  ${fiscalPersonToListFragment}
`;
export const GQL_PEOPLE_ID_SEARCH = gql`
  query Get($id: ID) {    
    fiscalPerson(id: $id) {
      ...FiscalPerson
    }
  }
  ${fiscalPersonFragment}   
`;
export const GQL_PERSON_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: {personId: $id, className: "Person"}) {
      id,
      discardedAt
    }
  }
`;
export const GQL_PERSON_STORE = gql`
  mutation StorePerson($attributes: PersonAttributes!) {
    storePerson(input: {attributes: $attributes}) { 
      person {
        ...FiscalPerson      
      }, 
      errors 
    } 
  }
  ${fiscalPersonFragment}
`;