import gql from "graphql-tag";
export const fiscalOperationListFragment = gql`
  fragment FiscalOperationList on FiscalOperation {
    id,
    name,
    kind,
    discardedAt,
    stockOperationId,
    financialOperationId,
    financialOperationAdministrateTaxId
  }
`;