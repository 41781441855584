import gql from "graphql-tag";
import { productGenreFragment, accountingProductFragment } from "../fragments/AccountingProduct";
export const GQL_PRODUCT_GENRE_LIST = gql`
  {    
    productGenres{
      ...ProductGenre
    }
  }
  ${productGenreFragment}
`;
export const GQL_ACCOUNTING_PRODUCT_LOAD = gql`
  query Get($id: ID) { 
    accountingProducts(id: $id) {
      ...AccountingProduct
    }
  }
  ${accountingProductFragment}   
`;
export const GQL_ACCOUNTING_PRODUCTS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $search: String) {    
    accountingProductsPaginated(first: $pageSize, after: $cursor, search: $search) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...AccountingProduct
        }
      }
    }    
  }
  ${accountingProductFragment}
`;
export const GQL_ACCOUNTING_PRODUCT_STORE = gql`
  mutation StoreAccountingProduct($attributes: AccountingProductAttributes!) {
    storeAccountingProduct(input: {attributes: $attributes}) { 
      accountingProduct {
        ...AccountingProduct
      }, 
      errors 
    } 
  }
  ${accountingProductFragment}   
`;