import gql from "graphql-tag";
import { groupParentFragment } from "../fragments/Group";
export const GQL_SEARCH_GROUPS = gql`
  query GetByName($name: String) {    
    groups(name: $name) {
      ...GroupParent
    }
  }
  ${groupParentFragment}  
`;
export const GQL_ALL_AVAILABLE_GROUPS = gql`
  query GetAll($all: Boolean) {    
    groups(all: $all) {
      ...GroupParent
    }
  }
  ${groupParentFragment}
`;
export const GQL_GROUPS_SELECTION = gql`
  {
    groups {
      ...GroupParent
    }
  }
  ${groupParentFragment}
`;
export const GQL_GROUPS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $groupFilter: GroupFilter) {    
    groupsPaginated(first: $pageSize, after: $cursor, groupFilter: $groupFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...GroupParent
        }
      }
    }    
  }
  ${groupParentFragment}
`;
export const GQL_GROUP_STORE = gql`
  mutation StoreGroup($attributes: GroupAttributes!) {
    storeGroup(input: {attributes: $attributes}) { 
      group { 
        ...GroupParent
      }, 
      errors 
    } 
  }
  ${groupParentFragment}
`;
export const GQL_GROUP_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: {id: $id, className: "Stock::Group"}) {
      id,
      discardedAt
    }
  }
`;