import gql from "graphql-tag";
import { fleetPersonFragment, fleetPersonToListFragment } from "./FleetPerson";
export const driverFragment = gql`
  fragment Driver on Driver  { 
    id,
    personId,
    person {
      ...FleetPerson
    },
    discardedAt
  }
  ${fleetPersonFragment}
`;
export const driverToListFragment = gql`
  fragment DriverToList on Driver  { 
    id,
    personId,
    person {
      ...FleetPersonToList
    },
    discardedAt
  }
  ${fleetPersonToListFragment}
`;