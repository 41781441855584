import { companyDefaultBuilder, companyAtributesBuilder } from 'admin-front';
import { fiscalPersonDefaultBuilder, fiscalPersonParamBuilder } from './FiscalPersonBuilder';
export const fiscalCompanyParamBuilder = (record, values) => {
  const companyAttributes = companyAtributesBuilder(record, values);
  const fiscalCompanyAttributes = fiscalPersonParamBuilder(record?.person, values);
  return {
    attributes: {
      ...companyAttributes,
      person: fiscalCompanyAttributes
    }
  };
};
export const fiscalCompanyDefaultBuilder = record => {
  const companyAttributes = companyDefaultBuilder(record);
  const personAttributes = fiscalPersonDefaultBuilder(record?.person);
  return {
    ...personAttributes,
    ...companyAttributes
  };
};