export const ADDITIONAL_INFO_FISCO = 'fisco';
export const ADDITIONAL_INFO_TAXPAYER = 'tax_payer';
export const ADDITIONAL_INFO_COMPANY = 'company';
export const ADDITIONAL_INFO_PERSON = 'person';
export const ADDITIONAL_INFO_TAX_PROFILE = 'tax_profile';
export const ADDITIONAL_INFO_GENERAL = 'general';
export const ADDITIONAL_INFO_CONTRACT = 'contract';
export const ADDITIONAL_INFO_PROCESS = 'process';
export const TARGET_INTEREST = [{
  value: ADDITIONAL_INFO_FISCO,
  label: 'Fisco'
}, {
  value: ADDITIONAL_INFO_TAXPAYER,
  label: 'Contribuinte'
}];
export const INFO_ORIGIN = [{
  value: ADDITIONAL_INFO_COMPANY,
  label: 'Informação adicional da Empresa'
}, {
  value: ADDITIONAL_INFO_PERSON,
  label: 'Informação adicional da pessoa'
}, {
  value: ADDITIONAL_INFO_TAX_PROFILE,
  label: 'Informação adicional do perfil tributário'
}, {
  value: ADDITIONAL_INFO_GENERAL,
  label: 'Informação adicional sem origem específica'
}, {
  value: ADDITIONAL_INFO_CONTRACT,
  label: 'Informação adicional originada de contrato'
}, {
  value: ADDITIONAL_INFO_PROCESS,
  label: 'Informação adicional decorrente do processo executado'
}];