import gql from "graphql-tag";
import { parentAccountFragment, parentAccountSimpleFragment } from "../fragments/Account";
import { reportFragment } from 'admin-front';
import { accountFlowFragment } from "../fragments/AccountFlow";
export const GQL_PRINT_MOVEMENT_RECEIPT = gql`
  query PrintMovementReceipt($movementId: String, $transactionId: String, $accountId: String, $fromWriteOff: Boolean) {    
    printMovementReceipt(movementId: $movementId, transactionId: $transactionId, accountId: $accountId, fromWriteOff: $fromWriteOff) {
      ...Report
    }
  }
  ${reportFragment}
`;
export const GQL_ACCOUNT_STORE = gql`
  mutation StoreAccount($attributes: AccountAttributes!) {
    storeAccount(input: {attributes: $attributes}) { 
      account { 
        ...ParentAccount
      }, 
      errors 
    } 
  }
  ${parentAccountFragment}  
`;
export const GQL_ACCOUNT_LIST = gql`
  {
    accounts {
      ...ParentAccount
    }
  }
  ${parentAccountFragment}
`;
export const GQL_GENERATE_BANK_SLIP = gql`
  mutation BankSlipGenerate($attributes: BankSlipAttributes!) {    
    bankSlipGenerate(input: {attributes: $attributes}) {
      account { 
        ...ParentAccount
      }, 
      errors 
    }
  }
  ${parentAccountFragment}
`;
export const GQL_ACCOUNT_LIST_BY_KIND = gql`
  query GetByKindFilter($pageSize: Int, $cursor: String, $kindFilter: AccountKindFilter) {    
    accountsPaginated(first: $pageSize, after: $cursor, kindFilter: $kindFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...ParentAccount
        }
      }
    }    
  }
  ${parentAccountFragment}
`;
export const GQL_OVERDUE_ACCOUNTS = gql`
  query GetByKind($overdue: [String!]) {    
    accounts(overdue: $overdue) {
      ...ParentAccount
    }
  }
  ${parentAccountFragment}
`;
export const GQL_OVERDUE_ACCOUNTS_BY_PERSON = gql`
  query GetByPerson($personId: String) {    
    accounts(personId: $personId) {
      ...ParentAccount
    }
  }
  ${parentAccountFragment}
`;
export const GQL_ACCOUNT_RECONCILIATION_LIST_FILTER = gql`
  query GetAccountsByFilter($accountFilter: AccountFilter) {    
    accounts(accountFilter: $accountFilter) {
      ...ParentAccount
    }
  }
  ${parentAccountFragment}
`;
export const GQL_ACCOUNT_LIST_BY_KIND_AND_PERSON = gql`
  query SearchAccount($filter: AccountCustomFilter) {    
    accounts(filter: $filter) {
      ...ParentAccountSimple
    }
  }
  ${parentAccountSimpleFragment}
`;
export const GQL_ACCOUNT_SEARCH_BY_ID = gql`
  query GetById($id: ID) {    
    accounts(id: $id) {
      ...ParentAccount
    }
  }
  ${parentAccountFragment}
`;
export const GQL_ACCOUNT_FLOW = gql`
  query GetFlow($filter: MovementsCustomFilter) {    
    accountFlow(filter: $filter) { 
      ...AccountFlow
    }
  }
  ${accountFlowFragment}   
`;