import gql from "graphql-tag";
import { fiscalOperationSelectionFragment } from "./OperationSelection";
import { InvoiceItemListingFragment } from "./InvoiceItemListing";
import { fiscalPersonInvoiceListingFragment } from "./PersonInvoiceListing";
import { electronicDocumentFragment } from "./ElectronicDocument";
import { InvoiceSimpleFragment } from "./InvoiceSimple";
import { ContractSimpleFragment } from "./Contract";
export const invoiceListingFragment = gql`
  fragment InvoiceListing on Invoice  { 
    ...InvoiceSimple
    contract {
      ...FiscalContractSimple
    }
    electronicDocument {
      ...ElectronicDocument
    },
    operation {
      ...FiscalOperationSelection
    }
    person {
      ...FiscalPersonInvoiceListing
    }
    items {
      ...InvoiceItemListing
    }
  }
  ${InvoiceSimpleFragment}
  ${ContractSimpleFragment}
  ${electronicDocumentFragment}
  ${fiscalPersonInvoiceListingFragment}
  ${fiscalOperationSelectionFragment}  
  ${InvoiceItemListingFragment}
`;