import gql from "graphql-tag";
import { personStatisticFragment } from "../fragments/PersonStatistic";
import { financialPersonFragment } from "../fragments/FinancialPerson";
export const GQL_PERSON_STATISTIC = gql`
  query personStatistic($filter: FinancialPersonStatisticCustomFilter) {    
    personStatistic(filter: $filter) {
      ...PersonStatistic
    }
  }
  ${personStatisticFragment}
`;
export const GQL_FINANCIAL_PEOPLE_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $search: String) {    
    financialPeoplePaginated(first: $pageSize, after: $cursor, search: $search) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...FinancialPerson
        }
      }
    }    
  }
  ${financialPersonFragment}
`;
export const GQL_PEOPLE_ID_SEARCH = gql`
  query Get($id: ID) {    
    financialPerson(id: $id) {
      ...FinancialPerson
    }
  }
  ${financialPersonFragment}   
`;