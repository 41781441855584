import gql from "graphql-tag";
import { resultCenterFragment } from "financial-front";
export const contractResultCenterFragment = gql`
  fragment ContractResultCenter on ContractResultCenter { 
    id,
    apportionment,
    resultCenterId,
    resultCenter{
      ...ResultCenter
    }
  }
  ${resultCenterFragment}
`;