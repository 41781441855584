import { BILLED, FINISHED, GROUPED, NFCE, NFE, ONLY_PREVIEW, OPENED, RESERVATION, XML } from '../constants/invoices/InvoiceFieldsOptions';
import { DOES_NOT_GENERATE_TAX_DOCUMENT, EXIT, OWN_TAX_DOCUMENT, OWN_TAX_DOCUMENT_ON_BEHALF_OF_THRID_PARTIES, THIRD_PARTY_TAX_DOCUMENT } from '../constants/operations/OperationsFieldsOptions';
import { DFE_AUTHORIZED, DFE_PENDING_SUBMISSION, DFE_PROCESSING, DFE_REJECTED, DFE_SENT } from '../constants/dfes/DfeFieldsOptions';
export const isBilled = invoice => {
  return [BILLED].includes(invoice?.situation);
};
export const isOpened = invoice => {
  return [OPENED].includes(invoice?.situation);
};
export const isReserved = invoice => {
  return [RESERVATION].includes(invoice?.situation);
};
export const isFinished = invoice => {
  return [FINISHED].includes(invoice?.situation);
};
export const isGrouped = invoice => {
  return [GROUPED].includes(invoice?.situation);
};
export const isExit = invoice => {
  return invoice?.operationType === EXIT;
};
export const isOwnDfe = invoice => {
  return [OWN_TAX_DOCUMENT, OWN_TAX_DOCUMENT_ON_BEHALF_OF_THRID_PARTIES].includes(invoice?.taxDocumentIssuer);
};
export const isThirdPartyDfe = invoice => {
  return [THIRD_PARTY_TAX_DOCUMENT].includes(invoice?.taxDocumentIssuer);
};
export const isTaxDocument = invoice => {
  return ![DOES_NOT_GENERATE_TAX_DOCUMENT].includes(invoice?.taxDocumentIssuer);
};
export const isNfe = invoice => {
  return invoice?.dfeModel === NFE;
};
export const isNfce = invoice => {
  return invoice?.dfeModel === NFCE;
};
export const isImported = invoice => {
  return invoice?.generatedBy === XML;
};
export const isNotImported = invoice => {
  return !isImported(invoice);
};
export const isGrouperOrder = invoice => {
  return invoice?.hasChildren;
};
export const isGroupedOrder = invoice => {
  return !!invoice?.parentId;
};