import gql from "graphql-tag";
import { fiscalProductFragment } from "../fragments/Product";
import { productSearchFragment } from "../fragments/ProductSearch";
import { fiscalProductWithoutTaxInfoFragment } from "../fragments/ProductWithoutTaxInfo";
export const GQL_FISCAL_PRODUCTS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $search: String) {    
    fiscalProductsPaginated(first: $pageSize, after: $cursor, search: $search) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...FiscalProduct
        }
      }
    }    
  }
  ${fiscalProductFragment}
`;
export const GQL_FISCAL_PRODUCT_LOAD = gql`
  query Get($id: ID) { 
    fiscalProduct(id: $id) {
      ...FiscalProduct
    }
  }
  ${fiscalProductFragment}   
`;
export const GQL_FISCAL_PRODUCT_BY_ID_WITHOUT_TAX_INFO = gql`
  query Get($id: ID) { 
    fiscalProduct(id: $id) {
      ...FiscalProductWithoutTaxInfo
    }
  }
  ${fiscalProductWithoutTaxInfoFragment}   
`;
export const GQL_FISCAL_PRODUCT_STORE = gql`
  mutation StoreFiscalProduct($attributes: FiscalProductAttributes!) {
    storeFiscalProduct(input: {attributes: $attributes}) { 
      product {
        ...FiscalProduct
      }, 
      errors 
    } 
  }
  ${fiscalProductFragment}   
`;
export const GQL_FISCAL_PRODUCT_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: {id: $id, className: "Stock::Product"}) {
      id,
      discardedAt
    }
  }
`;
export const GQL_FISCAL_PRODUCTS_SEARCH = gql`
  query SearchFiscalProducts($filter: FilterFiscalProductSearch) {
    searchFiscalProducts(filter: $filter) {
      ...FiscalProductSearch
    }
  }
  ${productSearchFragment}
`;