import gql from "graphql-tag";
import { companySimpleFragment } from "./Company";
export const companyUserFragment = gql`
  fragment CompanyUser on CompanyUser {
    id,
    tenantUserId,
    companyId,
    company {
      ...CompanySimple
    }
  }
  ${companySimpleFragment}
`;