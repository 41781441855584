import gql from "graphql-tag";
export const locationSimpleFragment = gql`
  fragment LocationSimple on Location {
    id,
    name,
    area,
    identifier,
    description,
    ancestry,
    fullPathName,
    discardedAt,
  }
`;
export const locationFragment = gql`
  fragment Location on Location  {
    ...LocationSimple
    parent {
      ...LocationSimple
    },
  }
  ${locationSimpleFragment}
`;
export const locationParentFragment = gql`
  fragment LocationParent on Location {
    ...Location
    children {
      ...Location,
      children {
        ...Location,
        children {
          ...Location,
          children {
            ...Location,
            children {
              ...Location,
            }
          }
        }
      }
    }
  }
  ${locationFragment} 
`;