import { personAtributesBuilder, personDefaultBuilder } from 'admin-front';
export const shippingCompanyParamBuilder = (record, values) => {
  const personAttributes = personAtributesBuilder({
    id: values.personId
  }, values);
  return {
    attributes: {
      id: record?.id,
      personId: record?.personId,
      person: personAttributes
    }
  };
};
export const shippingCompanyDefaultBuilder = record => {
  const personAttributes = personDefaultBuilder(record?.person);
  return {
    id: record?.id,
    personId: record?.personId,
    ...personAttributes
  };
};