import { sanitizeBackend, sanitize } from 'grifo-components';
import { financialPersonParamBuilder, financialPersonDefaultBuilder } from 'financial-front';
import { ICMS_TAXPAYER_EXEMPT, NORMAL_PRODUCER } from "../constants/personFiscal/PersonFiscalOptions";
export const fiscalPersonParamBuilder = (record, values) => {
  const personAttributes = financialPersonParamBuilder(record, values).attributes;
  const certificate64 = values.documents64 ? sanitize(values.documents64, ['name', 'url']) : null;
  return {
    attributes: {
      person: personAttributes,
      infos: (values.infos || []).map(object => ({
        ...object,
        mandatory: object.mandatory || false
      })),
      cnaes: values.cnaes,
      sts: values.sts,
      stateRegistration: values.stateRegistration,
      stateRegistrations: values.stateRegistrations || [],
      municipalRegistration: values.municipalRegistration,
      suframaRegistration: values.suframaRegistration,
      taxRegime: values.taxRegime,
      contributeIcms: values.contributeIcms,
      producer: values.producer || NORMAL_PRODUCER,
      nfceToken: values.nfceToken,
      nfceId: values.nfceId,
      environment: values.environment,
      issueMode: values.issueMode,
      powerTensionGroup: values.powerTensionGroup,
      ipiTaxpayerClassification: values.ipiTaxpayerClassification,
      personFiscalStart: values.personFiscalStart,
      certificate: {
        documents64: certificate64,
        password: values.certificatePassword
      },
      fiscalStarts: (values.fiscalStarts || []).map(object => ({
        ...object,
        dfeSeries: parseInt(object.dfeSeries),
        dfeNumber: parseInt(object.dfeNumber)
      }))
    }
  };
};
export const fiscalPersonDefaultBuilder = record => {
  const personAttributes = financialPersonDefaultBuilder(record);
  return {
    ...personAttributes,
    cnaes: record?.cnaes || [],
    sts: record?.sts || [],
    infos: (record?.infos || []).map(object => ({
      ...object,
      start: sanitizeBackend(object.start),
      finish: sanitizeBackend(object.finish)
    })),
    contributeIcms: record?.contributeIcms || ICMS_TAXPAYER_EXEMPT,
    stateRegistration: record?.stateRegistration,
    stateRegistrations: record?.stateRegistrations,
    municipalRegistration: record?.municipalRegistration,
    suframaRegistration: record?.suframaRegistration,
    taxRegime: record?.taxRegime,
    producer: record?.producer || NORMAL_PRODUCER,
    nfceToken: record?.nfceToken,
    nfceId: record?.nfceId,
    environment: record?.environment,
    issueMode: record?.issueMode,
    powerTensionGroup: record?.powerTensionGroup,
    ipiTaxpayerClassification: record?.ipiTaxpayerClassification,
    fiscalStarts: record?.fiscalStarts || [],
    certificate: record?.certificate,
    documents64: certificateBuilder(record?.certificate?.documents64)
  };
};
const certificateBuilder = content => {
  let documents64 = null;
  if (content) {
    documents64 = [{
      name: content?.filename,
      status: 'done',
      uid: content?.id,
      key: content?.key,
      url: content?.key,
      filename: content?.filename,
      contentType: content?.contentType,
      data: content?.data
    }];
  }
  return documents64;
};