import { INDUSTRY, CONFIG } from 'grifo-components';
export const buildFeatures = () => {
  const industryPath = "/" + INDUSTRY;
  const industryConfigPath = industryPath + '/' + CONFIG;
  const industryConfigRoutes = [{
    ordination: '12.04.01',
    name: 'Operações',
    key: industryConfigPath + "/operation",
    routePath: industryConfigPath + "/operation",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "OperationsPage",
      import_path: "containers/operations/OperationsPage"
    }
  }, {
    ordination: '12.04.03',
    name: 'Ficha técnica',
    key: industryConfigPath + "/data_sheet",
    routePath: industryConfigPath + "/data_sheet",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "DataSheetsPage",
      import_path: "containers/data_sheet/DataSheetsPage"
    }
  }, {
    ordination: '12.04.04',
    name: 'Embalagens',
    key: industryConfigPath + "/packaging",
    routePath: industryConfigPath + "/packaging",
    kind: 'page',
    status: 'disabled',
    authorization: 'regular'
  }, {
    ordination: '12.04.05',
    name: 'Processo',
    key: industryConfigPath + "/process",
    routePath: industryConfigPath + "/process",
    kind: 'page',
    status: 'disabled',
    authorization: 'regular'
  }];
  const routes = [{
    ordination: '12.01',
    name: 'Dashboard',
    key: industryPath + "/dashboard",
    routePath: industryPath + "/dashboard",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "DashboardIndustryPage",
      import_path: "containers/Dashboard"
    }
  }, {
    ordination: '12.02',
    name: 'Produções',
    key: industryPath + "/productions",
    routePath: industryPath + "/productions",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "ProductionsPage",
      import_path: "containers/production/ProductionsPage"
    },
    dividerAbove: true
  }, {
    ordination: '12.03',
    name: 'Lotes',
    key: industryPath + "/batches",
    routePath: industryPath + "/batches",
    kind: 'page',
    status: 'disabled',
    authorization: 'regular'
  }, {
    ordination: '12.04',
    name: 'Configurações',
    key: industryConfigPath,
    routePath: industryConfigPath,
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    dividerAbove: true,
    children: industryConfigRoutes
  }];
  return {
    ordination: '12',
    name: 'Indústria',
    key: industryPath,
    kind: 'module',
    status: 'active',
    routePath: industryPath,
    routePathParams: undefined,
    dividerAbove: false,
    icon: 'industry',
    content: undefined,
    contentProp: undefined,
    authorization: 'regular',
    userAuthorization: undefined,
    children: routes
  };
};