import { deepGet } from "./ListUtil";
export const buildOptions = (datasource, attributeLabel, excludedIds = []) => {
  if (!datasource) return;
  let options = [];
  const objects = datasource.filter(object => !excludedIds.includes(object.id));
  for (var index in objects) options.push({
    id: objects[index].id,
    value: objects[index].id,
    label: buildLabel(objects[index], attributeLabel),
    disabled: objects[index].discardedAt || false,
    record: objects[index]
  });
  return options;
};
export const buildCustomList = list => {
  return ((list || [])[0] || {} || {}).options || [];
};
export const buildLabel = (object, attributeLabel) => {
  if (Array.isArray(attributeLabel)) {
    let label = '';
    let hasValue = false;
    for (let i = 0; i < attributeLabel.length; i++) {
      const value = deepGet(object, attributeLabel[i]);
      if (value) {
        label += (hasValue ? ' - ' : '') + value;
        hasValue = true;
      }
    }
    return label.trim();
  } else {
    return object[attributeLabel];
  }
};