import { GREEN, GREEN_BAR, RED_BAR, RED } from 'grifo-components';
export const CLASSIFICATION = 'classification';
export const ORIGIN = 'origin';
export const OPERATION = 'operation';
export const POSITIVE = 'positive';
export const NEGATIVE = 'negative';
export const ZERO = 'zero';
export const RECEITA_ESPERADA = 'Receita esperada';
export const RECEITA_EFETIVA = 'Receita efetiva';
export const BALANCO_ESPERADO = 'Balanço esperado';
export const BALANCO_EFETIVO = 'Balanço efetivo';
export const DESPESA_ESPERADA = 'Despesa esperada';
export const DESPESA_EFETIVA = 'Despesa efetiva';
export const KEYS = [RECEITA_ESPERADA, RECEITA_EFETIVA, BALANCO_ESPERADO, BALANCO_EFETIVO, DESPESA_ESPERADA, DESPESA_EFETIVA];
export const DATA_KEYS = {
  "Receita esperada": GREEN_BAR,
  "Receita efetiva": GREEN,
  "Despesa esperada": RED,
  "Despesa efetiva": RED_BAR
};
export const GRID_STYLE = {
  width: '100%',
  textAlign: 'center'
};