import { FISCAL, REPORTS, CONFIG, ADVANCED_CONFIG } from 'grifo-components';
export const buildFiscalFeatures = () => {
  const fiscalPath = "/" + FISCAL;
  const fiscalConfigPath = fiscalPath + '/' + CONFIG;
  const fiscalAdvancedConfigPath = fiscalConfigPath + '/' + ADVANCED_CONFIG;
  const fiscalReportPath = fiscalPath + '/' + REPORTS;
  const fiscalAdvancedConfigRoutes = [{
    ordination: '06.03.07.01',
    name: 'CFOP',
    key: fiscalAdvancedConfigPath + "/cfop",
    routePath: fiscalAdvancedConfigPath + "/cfop",
    kind: 'page',
    status: 'active',
    authorization: 'super_admin',
    content: {
      page: "CfopsPage",
      import_path: "containers/cfop/CfopsPage"
    }
  }, {
    ordination: '06.03.07.02',
    name: 'CNAE',
    key: fiscalAdvancedConfigPath + "/cnae",
    routePath: fiscalAdvancedConfigPath + "/cnae",
    kind: 'page',
    status: 'active',
    authorization: 'super_admin',
    content: {
      page: "CnaesPage",
      import_path: "containers/cnaes/CnaesPage"
    }
  }, {
    ordination: '06.03.07.03',
    name: 'NCM',
    key: fiscalAdvancedConfigPath + "/ncm",
    routePath: fiscalAdvancedConfigPath + "/ncm",
    kind: 'page',
    status: 'active',
    authorization: 'super_admin',
    content: {
      page: "NcmsPage",
      import_path: "containers/ncm/NcmsPage"
    }
  }, {
    ordination: '06.03.07.04',
    name: 'CEST',
    key: fiscalAdvancedConfigPath + "/cests",
    routePath: fiscalAdvancedConfigPath + "/cests",
    kind: 'page',
    status: 'active',
    authorization: 'super_admin',
    content: {
      page: "CestsPage",
      import_path: "containers/cest/CestsPage"
    }
  }, {
    ordination: '06.03.07.05',
    name: 'Códigos de Benefício Fiscal',
    key: fiscalAdvancedConfigPath + "/tax_benefit_codes",
    routePath: fiscalAdvancedConfigPath + "/tax_benefit_codes",
    kind: 'page',
    status: 'active',
    authorization: 'super_admin',
    content: {
      page: "TaxBenefitCodesPage",
      import_path: "containers/taxBenefitCode/TaxBenefitCodesPage"
    }
  }, {
    ordination: '06.03.07.06',
    name: 'Lista de serviços (Lei 116/2003)',
    key: fiscalAdvancedConfigPath + "/service",
    routePath: fiscalAdvancedConfigPath + "/service",
    kind: 'page',
    status: 'active',
    authorization: 'super_admin',
    content: {
      page: "FiscalServicesPage",
      import_path: "containers/fiscal_services/FiscalServicesPage"
    }
  }, {
    ordination: '06.03.07.07',
    name: 'Enquadramento Legal do IPI',
    key: fiscalAdvancedConfigPath + "/ipi_legal_framework",
    routePath: fiscalAdvancedConfigPath + "/ipi_legal_framework",
    kind: 'page',
    status: 'active',
    authorization: 'super_admin',
    content: {
      page: "IpiLegalFrameworksPage",
      import_path: "containers/ipiLegalFramework/IpiLegalFrameworksPage"
    }
  }, {
    ordination: '06.03.07.08',
    name: 'Incidência de IPI',
    key: fiscalAdvancedConfigPath + "/ipi_incidence",
    routePath: fiscalAdvancedConfigPath + "/ipi_incidence",
    kind: 'page',
    status: 'active',
    authorization: 'super_admin',
    content: {
      page: "IpiIncidencesPage",
      import_path: "containers/ipi_incidence/IpiIncidencesPage"
    }
  }, {
    ordination: '06.03.07.09',
    name: 'Unidades tributáveis',
    key: fiscalAdvancedConfigPath + "/taxable_units",
    routePath: fiscalAdvancedConfigPath + "/taxable_units",
    kind: 'page',
    status: 'active',
    authorization: 'super_admin',
    content: {
      page: "TaxableUnitsPage",
      import_path: "containers/taxableUnits/TaxableUnitsPage"
    }
  }];
  const fiscalConfigRoutes = [{
    ordination: '06.03.01',
    name: 'Perfis Tributários',
    key: fiscalConfigPath + "/taxprofile",
    routePath: fiscalConfigPath + "/taxprofile",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "TaxProfilesPage",
      import_path: "containers/taxProfile/TaxProfilesPage"
    }
  }, {
    ordination: '06.03.02',
    name: 'Retenções',
    key: fiscalConfigPath + "/retention",
    routePath: fiscalConfigPath + "/retention",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "RetentionsPage",
      import_path: "containers/retention/RetentionsPage"
    }
  }, {
    ordination: '06.03.03',
    name: 'Informações Fiscais',
    key: fiscalConfigPath + "/additional_information",
    routePath: fiscalConfigPath + "/additional_information",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "AdditionalInformationsPage",
      import_path: "containers/additionalInformation/AdditionalInformationsPage"
    }
  }, {
    ordination: '06.03.06',
    name: 'Equivalência de CFOP',
    key: fiscalConfigPath + "/cfop_equivalence",
    routePath: fiscalConfigPath + "/cfop_equivalence",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "CfopEquivalencesPage",
      import_path: "containers/cfopEquivalence/CfopEquivalencesPage"
    }
  }, {
    ordination: '06.03.07',
    name: 'Avançado',
    key: fiscalAdvancedConfigPath,
    routePath: fiscalAdvancedConfigPath,
    kind: 'page',
    status: 'active',
    authorization: 'super_admin',
    children: fiscalAdvancedConfigRoutes,
    dividerAbove: true
  }];
  const fiscalReportRoutes = [{
    ordination: '06.04.01',
    name: 'Livro Registro de Entradas',
    key: fiscalReportPath + "/entry_book",
    routePath: fiscalReportPath + "/entry_book",
    kind: 'report',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "FiscalBookReportPage",
      import_path: "containers/fiscalBook/FiscalBookReportPage"
    },
    contentProp: {
      kind: 1
    }
  }, {
    ordination: '06.04.02',
    name: 'Livro Registro de Saídas',
    key: fiscalReportPath + "/exit_book",
    routePath: fiscalReportPath + "/exit_book",
    kind: 'report',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "FiscalBookReportPage",
      import_path: "containers/fiscalBook/FiscalBookReportPage"
    },
    contentProp: {
      kind: 2
    }
  }, {
    ordination: '06.04.03',
    name: 'Relatório de Notas Fiscais',
    key: fiscalReportPath + "/invoices_report",
    routePath: fiscalReportPath + "/invoices_report",
    kind: 'report',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "InvoicesReportPage",
      import_path: "containers/invoicesReport/InvoicesReportPage"
    }
  }, {
    ordination: '06.04.04',
    name: 'Resumo por CFOP',
    key: fiscalReportPath + "/cfops_book",
    routePath: fiscalReportPath + "/cfops_book",
    kind: 'report',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "CfopsBookReportPage",
      import_path: "containers/cfopsBook/CfopsBookReportPage"
    }
  }, {
    ordination: '06.04.05',
    name: 'Retenções',
    key: fiscalReportPath + "/retention",
    routePath: fiscalReportPath + "/retention",
    kind: 'report',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "RetentionReportPage",
      import_path: "containers/retention/RetentionReportPage"
    }
  }];
  const routes = [{
    ordination: '06.01',
    name: 'Dashboard',
    key: fiscalPath + "/dashboard",
    routePath: fiscalPath + "/dashboard",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "DashboardPage",
      import_path: "containers/dashboards/DashboardPage"
    }
  }, {
    ordination: '06.02',
    name: 'Inutilização de documentos',
    key: fiscalPath + "/unused_electronic_documents",
    routePath: fiscalPath + "/unused_electronic_documents",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "UnusedElectronicDocumentsPage",
      import_path: "containers/unusedElectronicDocuments/UnusedElectronicDocumentsPage"
    }
  }, {
    ordination: '06.03',
    name: 'Buscador de Notas',
    key: fiscalPath + "/dfe_distributions",
    routePath: fiscalPath + "/dfe_distributions",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "DfeDistributionsPage",
      import_path: "containers/dfeDistributions/DfeDistributionsPage"
    }
  }, {
    ordination: '06.04',
    name: 'Configurações',
    key: fiscalConfigPath,
    routePath: fiscalConfigPath,
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    dividerAbove: true,
    children: fiscalConfigRoutes
  }, {
    ordination: '06.05',
    name: 'Relatórios',
    key: fiscalReportPath,
    routePath: fiscalReportPath,
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    children: fiscalReportRoutes
  }];
  return {
    ordination: '06',
    name: 'Fiscal',
    key: fiscalPath,
    kind: 'module',
    status: 'active',
    routePath: fiscalPath,
    routePathParams: undefined,
    dividerAbove: false,
    icon: 'fiscal',
    content: undefined,
    contentProp: undefined,
    authorization: 'regular',
    userAuthorization: undefined,
    children: routes
  };
};