export const PHONE_AREA_MASK = "99";
export const PHONE_EXTENSION_MASK = "99999";
export const PHONE_MAIN = 'main';
export const PHONE_HOME = 'home';
export const PHONE_WORK = 'work';
export const PHONE_OTHER = 'other';
export const PHONES_NAMING_OPTIONS = [{
  value: PHONE_MAIN,
  label: 'Principal'
}, {
  value: PHONE_HOME,
  label: 'Casa'
}, {
  value: PHONE_WORK,
  label: 'Trabalho'
}, {
  value: PHONE_OTHER,
  label: 'Outro'
}];