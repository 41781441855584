export const IN_NEGOTIATION = 'in_negotiation';
export const AGREED = 'agreed';
export const CANCELLED = 'cancelled';
export const STATUS_KIND = [{
  value: IN_NEGOTIATION,
  label: 'Em negociação'
}, {
  value: AGREED,
  label: 'Negociado'
}, {
  value: CANCELLED,
  label: 'Cancelado'
}];