import gql from "graphql-tag";
import { financialDailyBalanceFragment } from "./FinancialDailyBalance";
import { accountFlowMovementFragment } from "./AccountFlowMovement";
export const accountFlowFragment = gql`
  fragment AccountFlow on AccountFlow  {
    id,
    date,
    movements {
      ...AccountFlowMovement
    }
    balance {
      ...FinancialDailyBalance
    }
  }
  ${accountFlowMovementFragment}
  ${financialDailyBalanceFragment}
`;