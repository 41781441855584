import gql from "graphql-tag";
import { personFragment } from "../fragments/Person";
import { personSearchFragment } from "../fragments/Person";
export const GQL_PEOPLE_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $search: String) {    
    peoplePaginated(first: $pageSize, after: $cursor, search: $search) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Person
        }
      }
    }    
  }
  ${personFragment}
`;
export const GQL_JURIDICAL_PEOPLE_SEARCH = gql`
  query Get($text: String) {    
    peopleSearch(juridical: $text) {
      ...PersonSearch
    }
  }
  ${personSearchFragment}   
`;
export const GQL_ASSOCIATED_PEOPLE_SEARCH = gql`
  query GetAssociated($text: String) {
    peopleSearch(associated: $text) {
      ...PersonSearch
    }
  }
  ${personSearchFragment}
`;
export const GQL_NATURAL_PEOPLE_SEARCH = gql`
  query Get($text: String) {    
    peopleSearch(natural: $text) {
      ...PersonSearch
    }
  }
  ${personSearchFragment}    
`;
export const GQL_DRIVER_PEOPLE_SEARCH = gql`
  query Get($text: String) {    
    peopleSearch(driver: $text) {
      ...PersonSearch
    }
  }
  ${personSearchFragment}    
`;
export const GQL_PEOPLE_ID_SEARCH = gql`
  query Get($id: ID) {    
    peopleSearch(id: $id) {
      ...PersonSearch
    }
  }
  ${personSearchFragment}   
`;
export const GQL_FULL_PEOPLE_ID = gql`
  query Get($id: ID) {    
    peopleSearch(id: $id) {
      ...Person
    }
  }
  ${personFragment}   
`;
export const GQL_ALL_PEOPLE_SEARCH = gql`
  query Get($text: String) {    
    peopleSearch(all: $text) {
      ...PersonSearch
    }
  }
  ${personSearchFragment}    
`;
export const GQL_PERSON_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: {id: $id, className: "Person"}) {
      id,
      discardedAt
    }
  }
`;
export const GQL_PERSON_STORE = gql`
  mutation StorePerson($attributes: PersonAttributes!) {
    storePerson(input: {attributes: $attributes}) { 
      person {
        ...Person      
      }, 
      errors 
    } 
  }
  ${personFragment}
`;