import { PURCHASE, REPORTS, CONFIG } from 'grifo-components';
import { NFE } from './invoices/InvoiceFieldsOptions';
import { ENTRY } from './operations/OperationsFieldsOptions';
export const buildPurchaseFeatures = () => {
  const purchasePath = "/" + PURCHASE;
  const purchaseConfigPath = purchasePath + '/' + CONFIG;
  const purchaseReportPath = purchasePath + '/' + REPORTS;
  const purchaseConfigRoutes = [{
    ordination: '04.06.01',
    name: 'Operações',
    key: purchaseConfigPath + "/operations",
    routePath: purchaseConfigPath + "/operations",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "FiscalOperationsPage",
      import_path: "containers/operations/OperationsPage"
    },
    contentProp: {
      operationKind: ENTRY
    }
  }];
  const purchaseReportRoutes = [{
    ordination: '04.07.01',
    name: 'Produtos por Pessoa',
    key: purchaseReportPath + "/products_by_person",
    routePath: purchaseReportPath + "/products_by_person",
    kind: 'report',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "ProductsByPersonReportPage",
      import_path: "containers/productByPerson/ProductsByPersonReportPage"
    },
    contentProp: {
      operationType: 2
    }
  }, {
    ordination: '04.07.02',
    name: 'Relatório de Compra(CAF)',
    key: purchaseReportPath + "/purchase_by_caf",
    routePath: purchaseReportPath + "/purchase_by_caf",
    kind: 'report',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "PurchaseByCafReportPage",
      import_path: "containers/purchaseByCaf/PurchaseByCafReportPage"
    },
    contentProp: {
      operationType: 2
    }
  }];
  const routes = [{
    ordination: '04.01',
    name: 'Dashboard',
    key: purchasePath + "/dashboard",
    routePath: purchasePath + "/dashboard",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "DashboardPurchasePage",
      import_path: "containers/dashboards/DashboardPurchasePage"
    }
  }, {
    ordination: '04.02',
    name: 'Coletas',
    key: purchasePath + "/gathers",
    routePath: purchasePath + "/gathers",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "GathersPage",
      import_path: "containers/gathers/GathersPage"
    },
    dividerAbove: true
  }, {
    ordination: '04.03',
    name: 'Contratos de Compras',
    key: purchasePath + "/contracts",
    routePath: purchasePath + "/contracts",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "ContractsPage",
      import_path: "containers/contracts/ContractsPage"
    },
    contentProp: {
      operationKind: ENTRY
    }
  }, {
    ordination: '04.04',
    name: 'Pedidos de Compras',
    key: purchasePath + "/invoices",
    routePath: purchasePath + "/invoices",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "InvoicesPage",
      import_path: "containers/invoices/InvoicesPage"
    },
    contentProp: {
      operationKind: ENTRY
    }
  }, {
    ordination: '04.05',
    name: 'NFe - Notas fiscais',
    key: purchasePath + "/tax_invoices",
    routePath: purchasePath + "/tax_invoices",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "TaxInvoicesPage",
      import_path: "containers/taxInvoice/TaxInvoicesPage"
    },
    contentProp: {
      operationKind: ENTRY,
      dfeModel: NFE
    },
    dividerAbove: true
  }, {
    ordination: '04.06',
    name: 'Configurações',
    key: purchaseConfigPath,
    routePath: purchaseConfigPath,
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    dividerAbove: true,
    children: purchaseConfigRoutes
  }, {
    ordination: '04.07',
    name: 'Relatórios',
    key: purchaseReportPath,
    routePath: purchaseReportPath,
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    children: purchaseReportRoutes
  }];
  return {
    ordination: '04',
    name: 'Compras',
    key: purchasePath,
    kind: 'module',
    status: 'active',
    routePath: purchasePath,
    routePathParams: undefined,
    dividerAbove: false,
    icon: 'purchase',
    content: undefined,
    contentProp: undefined,
    authorization: 'regular',
    userAuthorization: undefined,
    children: routes
  };
};