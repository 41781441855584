import gql from "graphql-tag";
export const stockFlowMovementFragment = gql`
  fragment StockFlowMovement on StockFlowMovement  {
    id,
    time,
    transactionId,
    operation,
    entries,
    exits,
    kind,
    subkind
  }
`;