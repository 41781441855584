import { ONLY_PREVIEW } from '../constants/invoices/InvoiceFieldsOptions';
import { DFE_AUTHORIZED, DFE_PENDING_SUBMISSION, DFE_PROCESSING, DFE_PROCESSING_CANCELLATION, DFE_REJECTED, DFE_SENT } from '../constants/dfes/DfeFieldsOptions';
export const isDfeAuthorized = electronicDocument => {
  return [DFE_AUTHORIZED].includes(electronicDocument?.situation);
};
export const isDfePending = electronicDocument => {
  return [DFE_PENDING_SUBMISSION].includes(electronicDocument?.situation);
};
export const isDfeInProcessing = electronicDocument => {
  return [DFE_PROCESSING, DFE_SENT, DFE_PROCESSING_CANCELLATION].includes(electronicDocument?.situation) || isDfePending(electronicDocument);
};
export const isDfeOnlyPreview = electronicDocument => {
  return [ONLY_PREVIEW].includes(electronicDocument?.situation);
};
export const isDfeRejected = electronicDocument => {
  return [DFE_REJECTED].includes(electronicDocument?.situation);
};
export const shouldConsult = electronicDocument => {
  return !isDfeRejected(electronicDocument) && !isDfeOnlyPreview(electronicDocument) && (isDfePending(electronicDocument) || isDfeInProcessing(electronicDocument));
};
export const isNotDfeYet = electronicDocument => {
  return isDfeOnlyPreview(electronicDocument) || isDfePending(electronicDocument);
};
export const isNotDfeAuthorizedYet = electronicDocument => {
  return isNotDfeYet(electronicDocument) || [DFE_PROCESSING, DFE_SENT, DFE_REJECTED].includes(electronicDocument?.situation);
};
export const isNotDfeSentYet = electronicDocument => {
  return isNotDfeYet(electronicDocument) || isDfeRejected(electronicDocument);
};
export const isDfeSefazAbsent = electronicDocument => {
  return electronicDocument?.rejectionCode === 217;
};
export const hasXML = electronicDocument => {
  return electronicDocument?.xmlDownloadUrl;
};