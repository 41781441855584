import gql from "graphql-tag";
import { groupFragment } from "./Group";
import { productBrandFragment } from "./ProductBrand";
import { productSupplierFragment } from "./ProductSupplier";
import { productUnitFragment } from "./ProductUnit";
export const productSimpleFragment = gql`
  fragment ProductSimple on Product  {
    id,
    name,
    description,
    searchCode,
    discardedAt,
  }
`;
export const productFragment = gql`
  fragment Product on Product  {
    ...ProductSimple,
    groups {
      ...Group
    }
    productBrands {
      ...ProductBrand
    }
    productSuppliers {
      ...ProductSupplier
    }
    productUnits {
      ...ProductUnit
    }  
  }
  ${productSimpleFragment}
  ${groupFragment}
  ${productBrandFragment}
  ${productSupplierFragment}  
  ${productUnitFragment}
`;