import { dateOrToday, cloneObject, sanitizeBackend, today } from 'grifo-components';
import { accountPaymentBuilder, resultCentersBuilder } from "financial-front";
import { COMPANY_LOCATION, GOODS_RETURN_DFE, NFE, NORMAL_DFE, OPENED, PERSON_LOCATION, WITHOUT_FREIGHT, ROUNDER, FINISHED } from "../constants/invoices/InvoiceFieldsOptions";
import { ICMS_TAXPAYER_EXEMPT } from "../constants/personFiscal/PersonFiscalOptions";
import { COSTUMER_FINAL, INSIDE_STATE, IN_PERSON, MAKE_UP_TOTAL, NORMAL, WITHOUT_BROKER } from "../constants/taxProfile/TaxFieldsOptions";
import { invoiceItemParamBuilder } from "./InvoiceItemBuilder";
import { DOES_NOT_GENERATE_TAX_DOCUMENT, FINAL_COSTUMER } from '../constants/operations/OperationsFieldsOptions';
import { isGrouperOrder } from '../utils/InvoiceDecoratorUtils';
const finalCostumer = values => {
  if (values.finalCostumerMethod != undefined) return values.finalCostumerMethod;
  return values.operationSubkind == FINAL_COSTUMER ? COSTUMER_FINAL : NORMAL;
};
export const invoiceParamBuilder = (record, values) => {
  let situation = values.action || values.situation || record?.situation;
  if (situation === OPENED && values.autoFinishInvoice) situation = FINISHED;
  let totalTaxes = values?.totalTaxes;
  if (values?.invoiceTax?.totalTaxes > totalTaxes) totalTaxes = values.invoiceTax?.totalTaxes;
  return {
    attributes: {
      id: record?.id,
      contractId: values.contractId,
      referencedDfe: values.referencedDfe,
      referencedDfeNumber: values.referencedDfeNumber,
      referencedDfeSeries: values.referencedDfeSeries,
      referencedDfeDate: values.referencedDfeDate,
      referencedDocumentId: values.referencedDocumentId,
      resultCenters: resultCentersBuilder(values.resultCenters),
      invoiceNumber: values.invoiceNumber,
      dfeNumber: values.dfeNumber,
      dfeSeries: values.dfeSeries,
      dfeModel: values.dfeModel,
      accessKey: values.accessKey,
      precisionType: values.precisionType,
      currentAssetId: values.currentAssetId,
      document: values.document,
      change: values.change,
      personId: values.personId,
      addressId: values.addressId,
      pickupMethod: values.pickupMethod || COMPANY_LOCATION,
      receiverId: values.receiverId,
      pickupLocationId: values.pickupLocationId,
      deliveryMethod: values.deliveryMethod || PERSON_LOCATION,
      freightMethod: values.freightMethod || WITHOUT_FREIGHT,
      dispatcherId: values.dispatcherId,
      deliveryPlaceId: values.deliveryPlaceId,
      shipperId: values.shipperId,
      vehicleId: values.vehicleId,
      operationId: values.operationId,
      transactionId: values.transactionId,
      situation: situation,
      autoSendInvoice: values.autoSendInvoice,
      date: values.date ? values.date.format("YYYY-MM-DD") : undefined,
      issuanceDate: values.issuanceDate ? values.issuanceDate.format("YYYY-MM-DDTHH:mm:ss") : undefined,
      departureDate: values.departureDate ? values.departureDate.format("YYYY-MM-DDTHH:mm:ss") : undefined,
      deliveryDate: values.deliveryDate ? values.deliveryDate.format("YYYY-MM-DDTHH:mm:ss") : undefined,
      expirationDate: values.expirationDate ? values.expirationDate.format("YYYY-MM-DDTHH:mm:ss") : undefined,
      operationDestination: values.operationDestination || INSIDE_STATE,
      contributeIcms: values.contributeIcms || ICMS_TAXPAYER_EXEMPT,
      finalCostumerMethod: finalCostumer(values),
      buyerPresenceIndicator: values.buyerPresenceIndicator || IN_PERSON,
      brokerIndicator: values.brokerIndicator || WITHOUT_BROKER,
      taxDocumentIssuer: values.taxDocumentIssuer,
      issuancePurpose: values.issuancePurpose,
      itemsTotal: values.itemsTotal,
      amount: values.amount || values.itemsTotal,
      administrateTax: values.administrateTax,
      totalTaxes: totalTaxes,
      freightValue: 0,
      insuranceValue: 0,
      discountValue: 0,
      invoiceTax: values.invoiceTax || {
        itemsTotal: values.itemsTotal,
        icmsBasis: values.icmsBasis || 0,
        icmsValue: values.icmsValue || 0,
        icmsWithheldValue: values.icmsWithheldValue || 0,
        fcpValueStateDestination: values.fcpValueStateDestination || 0,
        icmsValueStateDestination: values.icmsValueStateDestination || 0,
        icmsValueStateOrigin: values.icmsValueStateOrigin || 0,
        fcpValue: values.fcpValue || 0,
        icmsBasisSt: values.icmsBasisSt || 0,
        icmsValueSt: values.icmsValueSt || 0,
        fcpWithheldValue: values.fcpWithheldValue || 0,
        pisValue: values.pisValue || 0,
        cofinsValue: values.cofinsValue || 0,
        ipiValue: values.ipiValue || 0,
        ipiReturnedValue: values.ipiReturnedValue || 0,
        iiValue: values.iiValue || 0,
        retentions: values.retentions || [],
        otherValue: 0,
        freightValue: 0,
        insuranceValue: 0,
        discountValue: 0,
        total: values.total || 0,
        totalTaxes: totalTaxes || 0,
        itemsTotal: values.itemsTotal || 0
      },
      invoiceTaxWithheld: {
        pisValue: values.pisValue,
        cofinsValue: values.cofinsValue,
        csllValue: values.csllValue,
        irrfBasis: values.irrfBasis,
        irrfValue: values.irrfValue,
        socialSecurityBasis: values.socialSecurityBasis,
        socialSecurityValue: values.socialSecurityValue
      },
      otherValue: 0,
      total: values.total || 0,
      infos: values.infos || [],
      volumes: values.volumes || [],
      items: (values.items || []).filter(obj => obj.quantity > 0 || !values.contractId).map((object, index) => {
        return invoiceItemParamBuilder(object, index, situation);
      }),
      payments: values.payments,
      origin: values.origin
    }
  };
};
export const invoiceDefaultBuilder = (record, props = {}) => {
  const object = record || {};
  let contract = object.contract || props.contract;
  if (props.isCopy) {
    delete object.id;
    delete object.date;
    delete object.situation;
    delete object.transactionId;
    delete object.totalTaxes;
    delete object.invoiceTax;
    delete object.contractId;
    delete object.retentions;
    contract = undefined;
  }
  if (contract) {
    object.contractId = contract.id;
    if (object.id) {
      object.items = object.items.map(item => {
        const contractProduct = (contract.products || []).find(contractItem => contractItem.productId === item.productId && contractItem.productUnitId === item.productUnitId && contractItem.locationId === item.locationId && contractItem.unitaryValue === item.unitaryValue);
        return {
          balance: contractProduct?.balance || 0,
          ...item
        };
      });
    } else {
      object.operationId = contract.operationId;
      object.personId = contract.personId;
      object.payments = accountPaymentBuilder(object);
      object.address = contract.address;
      object.addressId = contract.addressId;
      object.administrateTax = contract.administrateTax;
      object.infos = (contract.infos || []).map(object => {
        return {
          ...object,
          id: undefined
        };
      });
      object.items = (contract.products || []).filter(item => item.balance > 0).map(item => {
        return {
          productId: item.productId,
          productUnitId: item.productUnitId,
          locationId: item.locationId,
          batchId: undefined,
          selectedProduct: [item.productId, item.productUnitId, item.locationId, undefined],
          invoiceIndex: item.contractIndex,
          quantity: 0,
          balance: item.balance,
          unitaryValue: item.unitaryValue,
          grossTotal: 0,
          discountValue: 0,
          freightValue: 0,
          insuranceValue: 0,
          otherExpenses: 0,
          totalInvoiceMethod: MAKE_UP_TOTAL,
          icms: {},
          productLabel: item.productName,
          total: 0,
          administrateTax: item.administrateTax
        };
      });
      object.categoryItems = (contract.categories || []).flatMap(category => (category.categoryItems || []).map(item => ({
        productId: item.productId,
        productUnitId: item.productUnitId,
        locationId: item.locationId,
        batchId: undefined,
        selectedProduct: [item.productId, item.productUnitId, item.locationId, undefined],
        categoryName: category.name,
        categoryBalance: category.quantityBalance > 0 ? "Saldo Qtde: " + category.quantityBalance : "Saldo Valor: " + category.valueBalance,
        invoiceIndex: item.contractIndex || 0,
        quantity: 0,
        balance: item.balance,
        unitaryValue: item.unitaryValue || 0,
        grossTotal: 0,
        discountValue: 0,
        freightValue: 0,
        insuranceValue: 0,
        otherExpenses: 0,
        totalInvoiceMethod: MAKE_UP_TOTAL,
        icms: {},
        productLabel: item.productName,
        total: 0,
        administrateTax: item.administrateTax
      })));
      let categoryItems = object.categoryItems;
      object.items = object.items.concat(categoryItems);
      object.itemsTotal = object.items.reduce((accumulator, item) => {
        return accumulator + item.total;
      }, 0);
      object.total = object.itemsTotal;
    }
  }
  const data = {
    referencedDfe: object?.electronicDocument?.accessKey || object.referencedDfe,
    contractId: object.contractId,
    invoiceNumber: object.invoiceNumber,
    dfeNumber: object.dfeNumber,
    changeInvoiceTax: false,
    autoSendInvoice: true,
    autoFinishInvoice: false,
    dfeSeries: object.dfeSeries,
    precisionType: object.precisionType || ROUNDER,
    dfeModel: object.dfeModel || props.dfeModel || NFE,
    accessKey: object.accessKey,
    personId: object.personId,
    addressId: object.addressId || object?.address?.id,
    address: object?.address,
    receiverId: (object.receiver || {}).id,
    pickupLocationId: (object.pickupLocation || {}).id,
    dispatcherId: (object.dispatcher || {}).id,
    deliveryPlaceId: (object.deliveryPlace || {}).id,
    pickupMethod: object.pickupMethod || COMPANY_LOCATION,
    deliveryMethod: object.deliveryMethod || PERSON_LOCATION,
    freightMethod: object.freightMethod || WITHOUT_FREIGHT,
    shipperId: (object.shipper || {}).id,
    vehicleId: (object.vehicle || {}).id,
    operationId: object.operationId,
    transactionId: object.transactionId,
    situation: object.situation || OPENED,
    action: props.action,
    operationType: object.operationType,
    operationDestination: object.operationDestination || INSIDE_STATE,
    contributeIcms: object.contributeIcms || ICMS_TAXPAYER_EXEMPT,
    finalCostumerMethod: object.finalCostumerMethod,
    buyerPresenceIndicator: object.buyerPresenceIndicator || IN_PERSON,
    brokerIndicator: object.brokerIndicator || WITHOUT_BROKER,
    date: dateOrToday(object.date),
    issuanceDate: dateOrToday(object.issuanceDate),
    departureDate: dateOrToday(object.departureDate),
    deliveryDate: sanitizeBackend(object.deliveryDate),
    expirationDate: sanitizeBackend(object.expirationDate),
    issuancePurpose: object.issuancePurpose || NORMAL_DFE,
    taxDocumentIssuer: object.taxDocumentIssuer || DOES_NOT_GENERATE_TAX_DOCUMENT,
    itemsTotal: object.itemsTotal || 0,
    administrateTax: object.administrateTax,
    totalTaxes: object.totalTaxes || 0,
    freightValue: object.freightValue || 0,
    insuranceValue: object.insuranceValue || 0,
    discountValue: object.discountValue || 0,
    otherValue: object.otherValue || 0,
    change: object.change || 0,
    totalBeforePayment: (object.freightValue || 0) + (object.insuranceValue || 0) + (object.change || 0) + (object.otherValue || 0) + (object.itemsTotal || 0) - (object.discountValue || 0),
    total: object.total || 0,
    infos: props.isCopy ? cloneObject(object.infos) : object.infos || [],
    invoiceTaxWithheld: object.invoiceTaxWithheld || {
      pisValue: 0,
      cofinsValue: 0,
      csllValue: 0,
      irrfBasis: 0,
      irrfValue: 0,
      socialSecurityBasis: 0,
      socialSecurityValue: 0
    },
    generatedBy: object.generatedBy,
    volumes: object.volumes || [],
    items: buildItemsList(object, props.isCopy),
    payments: buildPaymentsList(object, props.isCopy),
    invoiceIds: object.invoiceIds,
    resultCenters: object.resultCenters
  };
  return data;
};
export const referencedInvoiceBuilder = referencedInvoice => {
  const object = referencedInvoice;
  return {
    id: undefined,
    referencedDfe: object?.electronicDocument?.accessKey || object.referencedDfe,
    referencedDocumentId: object.id,
    referencedDfeNumber: object.dfeNumber,
    referencedDfeSeries: object.dfeSeries,
    referencedDfeDate: object.issuanceDate,
    dfeModel: object.dfeModel,
    personId: object.personId,
    precisionType: object.precisionType,
    addressId: object.addressId,
    receiverId: object.receiverId,
    pickupLocationId: object.pickupLocationId,
    dispatcherId: object.dispatcherId,
    deliveryPlaceId: object.deliveryPlaceId,
    pickupMethod: object.pickupMethod || COMPANY_LOCATION,
    deliveryMethod: object.deliveryMethod || PERSON_LOCATION,
    freightMethod: object.freightMethod || WITHOUT_FREIGHT,
    shipperId: object.shipperId,
    vehicleId: object.vehicleId,
    operationType: object.operationType,
    operationDestination: object.operationDestination || INSIDE_STATE,
    contributeIcms: object.contributeIcms || ICMS_TAXPAYER_EXEMPT,
    finalCostumerMethod: object.finalCostumerMethod || NORMAL,
    buyerPresenceIndicator: object.buyerPresenceIndicator || IN_PERSON,
    brokerIndicator: object.brokerIndicator || WITHOUT_BROKER,
    date: today(),
    issuanceDate: today(),
    departureDate: today(),
    deliveryDate: today(),
    expirationDate: today(),
    issuancePurpose: GOODS_RETURN_DFE,
    itemsTotal: object.itemsTotal || 0,
    totalTaxes: object.totalTaxes || 0,
    freightValue: object.freightValue || 0,
    insuranceValue: object.insuranceValue || 0,
    discountValue: object.discountValue || 0,
    otherValue: object.otherValue || 0,
    change: object.change || 0,
    totalBeforePayment: (object.freightValue || 0) + (object.insuranceValue || 0) + (object.change || 0) + (object.otherValue || 0) + (object.itemsTotal || 0) - (object.discountValue || 0),
    total: object.total || 0,
    volumes: object.volumes || [],
    items: object.items.map(item => {
      item.originalQuantity = item.quantity;
      delete item.id;
      delete item.fiscalInvoiceItemId;
      delete item.icms;
      item.selectedProduct = [item.productId, item.productUnitId, item.locationId, item.batchId];
      return item;
    }),
    payments: accountPaymentBuilder(object)
  };
};
export const invoiceConsultationParamsBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null
    }
  };
};
export const invoiceConsultationdefaultValuesBuilder = record => {
  return {};
};
const buildItemsList = (record, copying = false) => {
  let items = record.items || [];
  if (copying) items = copyItemsList(record);
  if (items.length === 0) items = [{
    quantity: 1,
    productUnitId: undefined,
    invoiceIndex: (record.items || []).length || 0 + 1 || 1,
    discountValue: 0,
    freightValue: 0,
    insuranceValue: 0,
    otherExpenses: 0,
    totalInvoiceMethod: MAKE_UP_TOTAL,
    icms: {}
  }];
  items.map(item => {
    item.selectedProduct = [item.productId, item.productUnitId, item.locationId, item.batchId];
    return item;
  });
  return items;
};
const copyItemsList = record => {
  let items = [];
  const clonedItems = cloneObject(record.items);
  if (clonedItems) {
    items = clonedItems.map(item => {
      delete item.totalTaxes;
      delete item.icms;
      delete item.cofins;
      delete item.pis;
      delete item.ipi;
      delete item.retentions;
      return item;
    });
  }
  return items;
};
const buildPaymentsList = (record, copying = false) => {
  let payments = [];
  const grouping = isGrouperOrder(record) && !record.id;
  if (copying) payments = copyPaymentsList(record);else if (grouping) payments = agroupPaymentsList(record);else payments = accountPaymentBuilder(record);
  if (payments.length === 0) payments = [{
    time: 0,
    timeKind: '',
    valueToPay: 0,
    installment: 0,
    gracePeriod: 0,
    fees: 0,
    discount: 0,
    total: 0,
    methodFees: 0,
    conditionFees: 0,
    methodDiscount: 0,
    conditionDiscount: 0,
    methodFeesRate: 0,
    conditionFeesRate: 0,
    methodDiscountRate: 0,
    conditionDiscountRate: 0,
    paymentMethodId: undefined,
    paymentConditionId: undefined
  }];
  return payments;
};
const copyPaymentsList = record => {
  let payments = [];
  const clonedPayment = cloneObject(record.payments);
  if (clonedPayment) {
    payments = clonedPayment.map(payment => {
      delete payment.paymentMethod;
      delete payment.paymentCondition;
      return payment;
    });
  }
  return payments;
};
const agroupPaymentsList = record => {
  const payments = record.children.map(groupedInvoice => {
    const deleteAttibutes = groupedInvoice.payments.map(payment => {
      delete payment.paymentMethod;
      delete payment.paymentCondition;
      return payment;
    });
    return deleteAttibutes;
  });
  return payments;
};