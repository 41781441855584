import gql from "graphql-tag";
import { currentAssetFragment } from "../fragments/CurrentAsset";
import { currentAssetSelectionFragment } from "../fragments/CurrentAsset";
export const GQL_SEARCH_CURRENT_ASSETS = gql`
  query GetByName($name: String) {    
    currentAssets(name: $name) { 
      ...CurrentAsset
    }
  }
  ${currentAssetFragment}   
`;
export const GQL_SEARCH_BY_TYPE_CURRENT_ASSETS = gql`
  query GetByType($type: String) {    
    currentAssets(type: $type) { 
      ...CurrentAsset
    }
  }
  ${currentAssetFragment}   
`;
export const GQL_SEARCH_BY_USER_ID = gql`
  query GetByUser($user: String) {    
    currentAssets(user: $user) { 
      ...CurrentAsset
    }
  }
  ${currentAssetFragment}   
`;
export const GQL_CURRENT_ASSETS_LIST = gql`
  {
    currentAssets { 
      ...CurrentAsset
    }
  }
  ${currentAssetFragment}   
`;
export const GQL_CURRENT_ASSET_LIST_BY_KIND_SELECTION = gql`
  query GetByKind($kind: [String!]) {    
    currentAssets(kind: $kind) {
      ...CurrentAssetSelection
    }
  }
${currentAssetSelectionFragment}
`;
export const GQL_CURRENT_ASSET_LIST_BY_KIND = gql`
  query GetByKind($pageSize: Int, $cursor: String, $kind: [String!]) {    
    currentAssetsPaginated(first: $pageSize, after: $cursor, kind: $kind) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...CurrentAsset
        }
      }
    }    
  }
  ${currentAssetFragment}
`;
export const GQL_CURRENT_ASSET_STORE = gql`
  mutation StoreCurrentAsset($attributes: CurrentAssetAttributes!) {
    storeCurrentAsset(input: {attributes: $attributes}) { 
      currentAsset {
        ...CurrentAsset
      }, 
      errors 
    } 
  }
  ${currentAssetFragment}   
`;
export const GQL_CURRENT_ASSET_ID_SEARCH = gql`
  query Get($id: ID) {    
    currentAssets(id: $id) {
      ...CurrentAsset
    }
  }
  ${currentAssetFragment}
`;