import moment from "moment";
export const pollListParamBuilder = (record, values) => {
  return {
    attributes: {
      attributes: values.polls,
      phaseId: record.id,
      start: values.start,
      finish: values.finish,
      question: values.question,
      description: values.description,
      majority_type: values.majority_type,
      minutes: values.minutes
    }
  };
};
export const pollListDefaultBuilder = record => {
  return {
    polls: (record || []).map(object => ({
      ...object,
      start: moment(object.start),
      finish: moment(object.finish)
    }))
  };
};