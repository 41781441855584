import { precisionType } from 'grifo-components';
import { calculateItemsTotal } from "./InvoiceCalculations";
import { COMPLEMENTARY_DFE } from '../constants/invoices/InvoiceFieldsOptions';
const getValueByKind = (value, precision, form) => {
  return precisionType(value, precision, form.getFieldValue('precisionType'));
};
export const calculateItemGrossTotal = (value, field, form) => {
  const quantity = form.getFieldValue(['items', field[0], 'quantity']);
  const unitaryValue = form.getFieldValue(['items', field[0], 'unitaryValue']);
  const total = getValueByKind(quantity * unitaryValue, 2, form);
  form.setFieldValue(['items', field[0], 'grossTotal'], total);
  return calculateItemTotal(value, field, form);
};
export const calculateItemUnitaryValue = (value, field, form, operationIssuancePurpose) => {
  if (operationIssuancePurpose !== COMPLEMENTARY_DFE) {
    const quantity = form.getFieldValue(['items', field[0], 'quantity']);
    const grossTotal = form.getFieldValue(['items', field[0], 'grossTotal']);
    const unitaryValue = getValueByKind(grossTotal / quantity, 4, form);
    form.setFieldValue(['items', field[0], 'unitaryValue'], unitaryValue);
  }
  return calculateItemTotal(value, field, form);
};
export const calculateItemTotal = (value, field, form) => {
  const grossTotal = form.getFieldValue(['items', field[0], 'grossTotal']);
  const freightValue = form.getFieldValue(['items', field[0], 'freightValue']);
  const insuranceValue = form.getFieldValue(['items', field[0], 'insuranceValue']);
  const otherExpenses = form.getFieldValue(['items', field[0], 'otherExpenses']);
  const discountValue = form.getFieldValue(['items', field[0], 'discountValue']);
  const total = getValueByKind(grossTotal + freightValue + insuranceValue + otherExpenses - discountValue, 2, form);
  form.setFieldValue(['items', field[0], 'total'], total);
  calculateItemsTotal(form);
  return total;
};