import gql from "graphql-tag";
import { paymentConditionFragment } from "financial-front";
export const fiscalPdvOperationFragment = gql`
  fragment FiscalPdvOperation on FiscalOperation  { 
      id,
      stockLocationId,
      taxDocumentIssuer,
      convenienceStoreMode,
      dfeSeries,
      paymentMethods{
        id,
        kind,
        paymentConditions {
          ...PaymentCondition
        }
      },
      stockLocation{
        name
      }
  }
  ${paymentConditionFragment}
`;