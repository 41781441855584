export const GENERATED = 'generated';
export const MANIFEST = 'manifest';
export const BILLED = 'billed';
export const OPENED = 'opened';
export const XML = 'xml';

// 
export const TRANSPORT_PROVIDER = 'transport_provider';
export const OWN_CARGO_CARRIER = 'own_cargo_carrier';
export const TRANSPORT_PROVIDER_CTE_EMITTER = 'transport_provider_cte_emitter';
export const ETC = 'ETC';
export const TAC = 'TAC';
export const CTC = 'CTC';
export const TON = 'ton';
export const KG = 'kg';
export const MDFE = 'mdf_e';
export const EXIT = 'exit';
export const ROAD = 'road';
export const AERIAL = 'aerial';
export const WATERWAY = 'waterway';
export const RAIL = 'rail';
export const PIPELINE = 'pipeline';
export const MULTIMODAL = 'multimodal';
export const LOAD = 'to_load';
export const UNLOAD = 'to_download';
export const BOTH = 'both';
export const ACTION_KIND_OPTIONS = [{
  value: LOAD,
  label: 'Carregamento',
  default: true
}, {
  value: UNLOAD,
  label: 'Descarregamento'
}, {
  value: BOTH,
  label: 'Ambos'
}];
export const EMITTER_OPTIONS = [{
  value: TRANSPORT_PROVIDER,
  label: 'Prestador de serviço de transporte',
  disabled: true
}, {
  value: OWN_CARGO_CARRIER,
  label: 'Transportador de Carga Própria',
  default: true
}, {
  value: TRANSPORT_PROVIDER_CTE_EMITTER,
  label: 'Prestador de serviço de transporte que emitirá CT-e Globalizado',
  disabled: true
}];
export const CARRIER_TYPE_OPTIONS = [{
  value: ETC,
  label: 'ETC'
}, {
  value: TAC,
  label: 'TAC'
}, {
  value: CTC,
  label: 'CTC'
}];
export const MEASURE_TYPE = [{
  value: TON,
  label: 'TON'
}, {
  value: KG,
  label: 'KG',
  default: true
}];
export const DFE_MODEL = [{
  value: MDFE,
  label: 'MDF-e - Manifesto Eletrônico de Documentos Fiscais'
}];
export const MODAL_OPTIONS = [{
  value: ROAD,
  label: 'Rodoviário',
  default: true
}, {
  value: AERIAL,
  label: 'Aéreo'
}, {
  value: WATERWAY,
  label: 'Aquaviário'
}, {
  value: RAIL,
  label: 'Ferroviário'
}, {
  value: PIPELINE,
  label: 'Dutoviário'
}, {
  value: MULTIMODAL,
  label: 'Multimodal'
}];