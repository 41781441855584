import gql from "graphql-tag";
import { batchFragment } from "./Batch";
import { locationSimpleFragment } from "./Location";
import { productSimpleFragment } from "./Product";
import { productUnitSimpleForSearchFragment } from "./ProductUnit";
export const productSearchFragment = gql`
  fragment ProductSearch on ProductSearch  {
    id,
    balance,
    product {
      ...ProductSimple
    }
    productUnit {
      ...ProductUnitSimpleForSearch
    }
    location {
      ...LocationSimple
    }
    batch {
      ...Batch
    }
  }
  ${productSimpleFragment}
  ${productUnitSimpleForSearchFragment}
  ${locationSimpleFragment}  
  ${batchFragment}  
`;