import { packObjectList, pluck, getSessionInfo, sanitizeBackend } from 'grifo-components';
import { NORMAL_USER, REGULAR } from "../constants/users/UsersFieldsOptions";
import { personAtributesBuilder, personDefaultBuilder } from "./PersonBuilder";
export const userParamBuilder = (record, values) => {
  const personParams = personAtributesBuilder(record, values);
  return {
    attributes: {
      id: record ? record.id : null,
      email: values.email,
      profile: values.profile,
      tenantId: values.tenantId,
      companyId: values.companyId,
      person: personParams,
      tenantUsers: values.tenantUsers.map(tenantUser => {
        tenantUser.roleId = tenantUser.roleId;
        tenantUser.authorizations = tenantUser.authorizations;
        tenantUser.companyUsers = packObjectList(tenantUser.companyUsers, 'companyId');
        tenantUser.companyId = ((tenantUser.companyUsers || [])[0] || {}).companyId;
        if (tenantUser.tenantId === values.tenantId) tenantUser.companyId = values.companyId;
        return tenantUser;
      })
    }
  };
};
export const userDefaultBuilder = record => {
  const personAttributes = personDefaultBuilder(record?.person);
  const session = getSessionInfo();
  return {
    ...personAttributes,
    id: record?.id,
    email: record?.email,
    profile: record?.profile || REGULAR,
    tenantId: record?.tenantId || session.tenantId,
    companyId: record?.company?.id || session.company?.id,
    name: record?.name || personAttributes.name,
    confirmedAt: sanitizeBackend(record?.confirmedAt),
    lockedAt: sanitizeBackend(record?.lockedAt),
    currentSignInAt: sanitizeBackend(record?.currentSignInAt),
    signInCount: record?.signInCount,
    document: record?.document || personAttributes.document,
    tenantUsers: (record?.tenantUsers || [{
      tenantId: session.tenantId,
      profile: NORMAL_USER,
      companyUsers: [{
        companyId: session.company?.id
      }]
    }]).map(tenantUser => {
      return {
        ...tenantUser,
        ...{
          companyUsers: pluck(tenantUser.companyUsers, "companyId")
        }
      };
    })
  };
};