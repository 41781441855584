export const POLL_AGREE = 'agree';
export const POLL_DISAGREE = 'disagree';
export const POLL_ABSTAIN = 'abstain';
export const POLL_OPENED = 'opened';
export const POLL_CLOSED = 'closed';
export const POLL_AWAITING = 'awaiting';
export const POLL_SKIPPED = 'skipped';
export const POLL_EVENTS = [{
  value: POLL_AGREE,
  label: 'Concordar'
}, {
  value: POLL_DISAGREE,
  label: 'Discordar'
}, {
  value: POLL_ABSTAIN,
  label: 'Abster-se'
}, {
  value: POLL_OPENED,
  label: 'Iniciar'
}, {
  value: POLL_CLOSED,
  label: 'Finalizar'
}, {
  value: POLL_AWAITING,
  label: 'Pausar'
}, {
  value: POLL_SKIPPED,
  label: 'Pular'
}];