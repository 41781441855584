import gql from "graphql-tag";
import { batchFragment } from "./Batch";
import { stockOperationSimpleFragment } from "./Operation";
import { productSimpleFragment } from "./Product";
import { locationSimpleFragment } from "./Location";
import { productUnitSimpleFragment } from "./ProductUnit";
export const movementFragment = gql`
  fragment StockMovement on StockMovement {
    id,
    quantity, 
    date,
    time,
    transactionId,
    kind,
    subkind,
    setup,
    origin,
    operation {
      ...StockOperationSimple
    }    
    product {
      ...ProductSimple
    }
    productUnit {
      ...ProductUnitSimple
    }
    location {
      ...LocationSimple
    }
    batch {
      ...Batch
    }
  }
  ${stockOperationSimpleFragment}
  ${productSimpleFragment}
  ${productUnitSimpleFragment}
  ${locationSimpleFragment}
  ${batchFragment}
`;