import { hasXML, isDfeAuthorized, isNotDfeAuthorizedYet, isNotDfeSentYet, isNotDfeYet } from './ElectronicDocumentDecoratorUtils';
import { isBilled, isExit, isFinished, isGrouped, isGroupedOrder, isGrouperOrder, isNfe, isNotImported, isOpened, isOwnDfe, isReserved, isTaxDocument } from './InvoiceDecoratorUtils';
export const canEdit = invoice => {
  return isOpened(invoice) && !isGroupedOrder(invoice);
};
export const canGenerateCorrectionLetter = invoice => {
  return isBilled(invoice) && isOwnDfe(invoice) && isDfeAuthorized(invoice?.electronicDocument) && isNfe(invoice);
};
export const canGenerateDevolution = invoice => {
  return false && isBilled(invoice) && isOwnDfe(invoice) && isDfeAuthorized(invoice?.electronicDocument) && isNfe(invoice); //disabled
};
export const canSendDfe = invoice => {
  return isBilled(invoice) && isOwnDfe(invoice) && isNotImported(invoice) && (isNotDfeYet(invoice?.electronicDocument) || isNotDfeAuthorizedYet(invoice?.electronicDocument));
};
export const canCancelDfe = invoice => {
  return isBilled(invoice) && isOwnDfe(invoice) && isDfeAuthorized(invoice?.electronicDocument);
};
export const canGenerateDanfe = invoice => {
  return isBilled(invoice) && isOwnDfe(invoice);
};
export const canGenerateXML = invoice => {
  return isBilled(invoice) && isOwnDfe(invoice) && hasXML(invoice?.electronicDocument);
};
export const canUnbill = invoice => {
  return isBilled(invoice) && (!invoice?.electronicDocument || isNotDfeSentYet(invoice.electronicDocument));
};
export const canFinish = invoice => {
  return isOpened(invoice) || isReserved(invoice);
};
export const canBill = invoice => {
  return isTaxDocument(invoice) && isNfe(invoice) && (isOpened(invoice) || isFinished(invoice));
};
export const canReopen = invoice => {
  return isFinished(invoice);
};
export const canReserve = invoice => {
  return isExit(invoice) && isOpened(invoice);
};
export const canUnreserve = invoice => {
  return isExit(invoice) && isReserved(invoice);
};
export const canGroup = invoice => {
  return (isOpened(invoice) || isFinished(invoice)) && !(isGrouperOrder(invoice) || isGroupedOrder(invoice));
};
export const canUngroup = invoice => {
  return (isGrouped(invoice) || isGrouperOrder(invoice)) && !(invoice.differentSituation || invoice.differentParentSituation);
};
export const canCancel = invoice => {
  return isOpened(invoice);
};