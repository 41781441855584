import gql from "graphql-tag";
import { retentionApportionmentFragment } from "./RetentionApportionment";
export const retentionFragment = gql`
  fragment Retention on Retention {
    id, 
    name,
    retentionApportionments {
      ...RetentionApportionment
    }
  }
  ${retentionApportionmentFragment}
`;