import { companyAtributesBuilder, companyDefaultBuilder } from 'admin-front';
import { SINGULAR } from "../constants/organizations/OrganizationFieldsOptions";
export const organizationParamBuilder = (record, values) => {
  const companyAtributes = companyAtributesBuilder(record, values);
  return {
    attributes: {
      id: record?.id,
      level: values.level,
      statutoryCapitalBase: values.statutoryCapitalBase,
      statutoryPersonAcceptance: values.statutoryPersonAcceptance,
      parentId: values.parentId,
      company: companyAtributes
    }
  };
};
export const organizationDefaultBuilder = record => {
  const companyAttributes = companyDefaultBuilder(record);
  const organization = record?.organization;
  return {
    isOrganization: !!organization,
    ...companyAttributes,
    level: organization?.level || SINGULAR,
    statutoryCapitalBase: organization?.statutoryCapitalBase || 0,
    statutoryPersonAcceptance: organization?.statutoryPersonAcceptance,
    parentId: record?.parentId,
    dataId: record?.id
  };
};