import { EXIT } from 'grifo-components';
import { NORMAL, APPLICATION_PRODUCT_AND_SERVICE } from "../constants/taxProfile/TaxFieldsOptions";
import { icmsParamBuilder } from "./TaxProfileIcmsBuilder";
export const taxProfileParamBuilder = (record, values) => {
  delete (values.ipi || {})["ipiLegalFramework"];
  let cests = values.cests?.map(cest => {
    delete cest?.ncmIds;
    delete cest?.ncmCests;
    return {
      ...cest
    };
  });
  let icms = icmsParamBuilder(values.icms);
  if (icms) icms['cests'] = cests;
  return {
    attributes: {
      id: record ? record.id : null,
      name: values.name,
      movementKind: values.movementKind,
      applicationKind: values.applicationKind,
      finalCostumerMethod: values.finalCostumerMethod,
      retentions: values.retentions,
      csll: values.csll,
      irpj: values.irpj,
      inss: values.inss,
      pis: values.pis,
      cofins: values.cofins,
      ipi: values.ipi,
      iss: values.iss,
      icms: icms,
      icmsFreight: values.icmsFreight,
      cfops: values.cfops,
      ncms: (values.ncms || []).map(object => {
        delete object.ncm;
        object.tecRate = object.tecRate || 0;
        return object;
      }),
      additionalInformations: (values.additionalInformations || []).map(object => {
        delete object.information;
        return object;
      })
    }
  };
};
export const taxProfileDefaultBuilder = record => {
  return {
    name: (record || {}).name,
    movementKind: (record || {}).movementKind || EXIT,
    applicationKind: (record || {}).applicationKind || APPLICATION_PRODUCT_AND_SERVICE,
    finalCostumerMethod: (record || {}).finalCostumerMethod || NORMAL,
    retentions: (record || {}).retentions,
    csll: (record || {}).csll,
    irpj: (record || {}).irpj,
    inss: (record || {}).inss,
    pis: (record || {}).pis,
    cofins: (record || {}).cofins,
    ipi: (record || {}).ipi,
    iss: (record || {}).iss,
    icms: (record || {}).icms,
    icmsFreight: (record || {}).icmsFreight,
    cfops: (record || {}).cfops,
    ncms: (record || {}).ncms,
    additionalInformations: (record || {}).additionalInformations,
    cests: (record || {}).cests
  };
};