import gql from "graphql-tag";
import { contractCategoryItemFragment } from "./ContractCategoryItem";
export const contractCategoryFragment = gql`
  fragment ContractCategory on FiscalContractCategory  { 
    id,
    contractId,
    name,
    quantity,
    quantityBalance,
    quantityCompletionPercentage,
    value,
    valueBalance,
    valueCompletionPercentage,
    categoryItems {
      ...ContractCategoryItem
    }
  }
  ${contractCategoryItemFragment}
`;