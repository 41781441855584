import { fiscalCompanyParamBuilder, fiscalCompanyDefaultBuilder } from 'fiscal-front';
import { sanitizeBackend } from 'grifo-components';
import { accountingPersonAttributes, accountingPersonDefaultBuilder } from './AccountingPersonBuilder';
export const accountingCompanyParamBuilder = (record, values) => {
  const fiscalCompanyAttributes = fiscalCompanyParamBuilder(record, values).attributes;
  const personAttributes = accountingPersonAttributes(record?.person, values);
  return {
    attributes: {
      ...fiscalCompanyAttributes,
      person: personAttributes,
      companyAccountants: values.companyAccountants.map(accountant => {
        return {
          ...accountant,
          person: undefined
        };
      }),
      stateIcmsApurationCode: values.stateIcmsApurationCode,
      stateIcmsExpirationDay: values.stateIcmsExpirationDay,
      accountingEntryDeadlineDay: values.accountingEntryDeadlineDay
    }
  };
};
export const accountingCompanyDefaultBuilder = record => {
  const companyAttributes = fiscalCompanyDefaultBuilder(record);
  const personAttributes = accountingPersonDefaultBuilder(record?.person);
  return {
    ...personAttributes,
    ...companyAttributes,
    companyAccountants: (record?.companyAccountants || []).map(companyAccountant => {
      return {
        ...companyAccountant,
        start: sanitizeBackend(companyAccountant.start),
        finish: sanitizeBackend(companyAccountant.finish),
        expirationDate: sanitizeBackend(companyAccountant.expirationDate)
      };
    }),
    stateIcmsApurationCode: record?.stateIcmsApurationCode,
    stateIcmsExpirationDay: record?.stateIcmsExpirationDay,
    accountingEntryDeadlineDay: record?.accountingEntryDeadlineDay
  };
};