import { packObjectList } from 'grifo-components';
export const productParamBuilder = (record, values) => {
  return {
    attributes: productAtributesBuilder(record, values)
  };
};
export const productAtributesBuilder = (record, values) => {
  return {
    id: record ? record.id : null,
    name: values.name,
    description: values.description,
    searchCode: values.searchCode,
    groups: packObjectList(values.groups, "groupId"),
    productBrands: (values.productBrands || []).map(object => ({
      ...object,
      productId: record?.id,
      productModels: packObjectList(object.productModels, "productModelId")
    })),
    productSuppliers: (values.productSuppliers || []).map(object => ({
      ...object,
      productId: record?.id
    }))
  };
};
export const productDefaultBuilder = record => {
  return {
    name: record?.name,
    description: record?.description,
    searchCode: record?.searchCode,
    groups: record?.groups,
    productBrands: record?.productBrands,
    productSuppliers: record?.productSuppliers
  };
};