import { packObjectList, pluck } from 'grifo-components';
import { fuelPumpSealDefaultBuilder } from './FuelPumpSealBuilder';
export const fuelPumpParamBuilder = (record, values) => {
  return {
    attributes: fuelPumpAtributesBuilder(record, values)
  };
};
export const fuelPumpAtributesBuilder = (record, values) => {
  return {
    id: record ? record.id : null,
    name: values.name,
    description: values.description,
    searchCode: values.searchCode,
    measurement: values.measurement,
    groups: packObjectList(values.groups, "groupId"),
    fuelPumpBrands: (values.fuelPumpBrands || []).map(object => ({
      ...object,
      fuelPumpId: record?.id,
      fuelPumpModels: packObjectList(object.fuelPumpModels, "fuelPumpModelId")
    })),
    fuelPumpSuppliers: (values.fuelPumpSuppliers || []).map(object => ({
      ...object,
      fuelPumpId: record?.id
    })),
    nozzles: (values.nozzles || []).map(object => ({
      ...object,
      fuelPumpId: record?.id
    })),
    fuelPumpSeals: (values.fuelPumpSeals || []).map(object => ({
      ...object,
      fuelPumpId: record?.id
    }))
  };
};
export const fuelPumpDefaultBuilder = record => {
  return {
    name: record?.name,
    description: record?.description,
    searchCode: record?.searchCode,
    measurement: record?.measurement,
    groups: record?.groups,
    fuelPumpBrands: record?.fuelPumpBrands,
    fuelPumpSuppliers: record?.fuelPumpSuppliers,
    nozzles: record?.nozzles,
    fuelPumpSeals: fuelPumpSealDefaultBuilder(record).fuelPumpSeals
  };
};