import { getSessionInfo } from 'grifo-components';
export const locationParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      name: values.name,
      area: values.area || 0.0,
      description: values.description,
      identifier: values.identifier,
      parentId: values.parentId,
      companyId: getSessionInfo().company.id
    }
  };
};
export const locationDefaultBuilder = record => {
  return {
    name: record?.name,
    area: record?.area,
    description: record?.description,
    identifier: record?.identifier,
    parentId: record?.parentId
  };
};