import gql from "graphql-tag";
import { accountSimpleFragment } from "./Account";
import { movementResultCenterFragment } from "./MovementResultCenter";
export const movementSimpleFragment = gql`
  fragment MovementSimple on Movement {
    id,
    transactionId,      
    operationId,      
    accountId,      
    shortComplement,
    longComplement,
    referencedDocument,
    kind,        
    reversingEntry,
    createdAt,          
    usedAsCompensation,
    hasSourceCompensation,
    currentAssetName,
    reversed,
    value,
    date, 
    setup,
  }
`;
export const movementFragment = gql`
  fragment Movement on Movement {
    ...MovementSimple,
    documents64 {
      id,
      data,          
      filename,
      contentType,
      key
    },
    reversingEntryMovement{
      ...MovementSimple
    },    
    reversingMovement{
      ...MovementSimple
    }    
  }
  ${movementSimpleFragment}
`;
export const movementEntryFragment = gql`
  fragment MovementEntrySimple on Movement {
    ...MovementSimple
    resultCenters {
      ...MovementResultCenter
    }
  }
  ${movementSimpleFragment}
  ${movementResultCenterFragment}
`;
export const movementWithWriteOffFragment = gql`
  fragment MovementWithWriteOff on Movement {
    ...MovementSimple,
    documents64 {
      id,
      data,          
      filename,
      contentType,
      key
    },
    reversingEntryMovement{
      ...MovementSimple
    },    
    reversingMovement{
      ...MovementSimple
    }
    writeOffMovement{
      ...MovementSimple
    }
  }
  ${movementSimpleFragment}
`;