import gql from "graphql-tag";
import { documentFolderKindFragment } from "./DocumentFolderKind";
export const personDocumentFragment = gql`
  fragment PersonDocument on PersonDocument {
    id,
    personId,
    issueDate,
    documentTypeId,
    size,
    code,
    observation,
    documentFolderKinds {
      ...DocumentFolderKind
    },
    documents64 {
      id,
      data,
      filename,
      contentType,
      key
    }
  }
  ${documentFolderKindFragment}
`;