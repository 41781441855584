export const REAL_PROFIT = 'real_profit';
export const PRESUMED_PROFIT = 'presumed_profit';
export const IMMUNE = 'immune';
export const NATIONAL_SIMPLE = 'national_simple';
export const ICMS_TAXPAYER = 'icms_taxpayer';
export const ICMS_TAXPAYER_EXEMPT = 'icms_taxpayer_exempt';
export const NON_ICMS_TAXPAYER = 'non_icms_taxpayer';
export const NORMAL_PRODUCER = 'normal_producer';
export const RURAL_PRODUCER = 'rural_producer';
export const TAX_REGIME_OPTIONS = [{
  value: REAL_PROFIT,
  label: 'Lucro Real'
}, {
  value: PRESUMED_PROFIT,
  label: 'Lucro Presumido'
}, {
  value: IMMUNE,
  label: 'Imune'
}, {
  value: NATIONAL_SIMPLE,
  label: 'Simples Nacional'
}];
export const CONTRIBUTE_ICMS_OPTIONS = [{
  value: ICMS_TAXPAYER,
  label: 'Contribuinte'
}, {
  value: ICMS_TAXPAYER_EXEMPT,
  label: 'ISENTO'
}, {
  value: NON_ICMS_TAXPAYER,
  label: 'Não Contribuinte'
}];
export const NATURAL_CONTRIBUTE_ICMS_OPTIONS = [{
  value: ICMS_TAXPAYER_EXEMPT,
  label: 'ISENTO'
}, {
  value: NON_ICMS_TAXPAYER,
  label: 'Não Contribuinte'
}];
export const PRODUCER_OPTIONS = [{
  value: NORMAL_PRODUCER,
  label: 'Normal'
}, {
  value: RURAL_PRODUCER,
  label: 'Rural'
}];