export const PHASE_OPENED = 'opened';
export const PHASE_REOPENED = 'reopened';
export const PHASE_SKIPPED = 'skipped';
export const PHASE_CLOSED = 'closed';
export const PHASE_AWAITING = 'awaiting';
export const PHASE_INFORMATION = 'information';
export const PHASE_VOTATION = 'votation';
export const PHASE_EVENTS = [{
  value: PHASE_OPENED,
  label: 'Aberta'
}, {
  value: PHASE_REOPENED,
  label: 'Reaberta'
}, {
  value: PHASE_CLOSED,
  label: 'Pulada'
}, {
  value: PHASE_CLOSED,
  label: 'Fechada'
}, {
  value: PHASE_AWAITING,
  label: 'Em recesso'
}];