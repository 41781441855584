import gql from "graphql-tag";
import { personSimpleFragment } from 'admin-front';
import { paymentConditionFragment } from "./PaymentCondition";
import { paymentMethodConditionFragment } from "./PaymentMethodCondition";
export const paymentMethodSimpleFragment = gql`
  fragment PaymentMethodSimple on PaymentMethod  {
    id,
    name,
    kind,
    fees,
    discount,
    integrationMethod,
    creditCardBrand,
    currentAssetId,
    paymentInstitutionId,
    discardedAt
  }
`;
export const paymentMethodFragment = gql`
  fragment PaymentMethod on PaymentMethod  {
    ...PaymentMethodSimple,
    paymentInstitution {
      ...PersonSimple
    },
    paymentConditions {
      ...PaymentCondition
    },
    paymentMethodConditions {
      ...PaymentMethodCondition
    }, 
  }
  ${paymentMethodSimpleFragment}
  ${personSimpleFragment}
  ${paymentConditionFragment}
  ${paymentMethodConditionFragment}
`;