import gql from "graphql-tag";
import { preferredDriverFragment } from "./PreferredDriver";
export const customerFragment = gql`
  fragment Customer on FleetPerson  { 
    id,
    name,
    tradeName,
    document,
    naturalRegistration,
    naturalRegistrationStateId,
    naturalRegistrationAgencyId,
    photo64,
    email,
    gender,
    birth,
    closure,
    rntrcCode,
    cadunicoCode,
    nisCode,
    jobRoleId,
    discardedAt,
    governmentInstitution, 
    nickname, 
    pisCode, 
    ctps, 
    voterRegistrationNumber, 
    voterRegistrationZone, 
    voterRegistrationSection, 
    nationalityId, 
    citizenshipId, 
    inss, 
    incomeId,
    lowIncome, 
    dependents, 
    occupationCode,
    cafCode,
    isDriver,
    isAssociated, 
    preferredDrivers {
      ...PreferredDriver
    },
  }
  ${preferredDriverFragment}
`;