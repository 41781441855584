import { companyAtributesBuilder } from './CompanyBuilder';
export const registrationParamBuilder = values => {
  const companyAttributes = companyAtributesBuilder(undefined, values, true);
  return {
    userDocument: values.userDocument,
    userName: values.userName,
    email: values.userEmail,
    password: values.password,
    passwordConfirmation: values.passwordConfirmation,
    company: companyAttributes
  };
};