import gql from "graphql-tag";
export const contractCategoryItemFragment = gql`
  fragment ContractCategoryItem on FiscalContractCategoryItem  {
    id,
    contractCategoryId,
    productId,
    productName,
    productUnitId,
    unitaryValue,
    administrateTax
  }
`;